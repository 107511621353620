import { theme } from "antd";

import { requestPasswordResetLink } from "api/resources/users";
import { zIndexOffset } from "components/Window";
import {
  Link,
  Space,
  SpaceTypes,
  Typography,
  TypographySizes,
} from "components/antd";
import { useNavigateToPath, useSelector } from "hooks";
import { ProtectedPaths } from "routes";
import type { ProfileProps } from "store";
import { selectAuthUserType } from "store/selectors";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { fontWeightMedium } from "styles/variables";

const StyledSpace = styled(Space)(
  ({ styledProps: { paddingBottom, zIndex } }: StyledProps) => ({
    paddingBottom,
    "&:after": {
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      bottom: 0,
      content: "''",
      display: "block",
      height: "100%",
      pointerEvents: "none",
      position: "absolute",
      width: "100%",
      zIndex,
    },
  })
);

type HeaderProps = {
  isViewerTargetUser: boolean;
  onInstructionsSent: () => void;
  user: ProfileProps;
};

const Header = ({
  isViewerTargetUser,
  onInstructionsSent,
  user,
}: HeaderProps) => {
  const navigateToEventProfileEdit = useNavigateToPath(
    ProtectedPaths.EventProfileEdit
  );
  const { isGuestUser } = useSelector(selectAuthUserType);
  const { token } = theme.useToken();

  const handleSendInstructionsEmailClick = async () => {
    await requestPasswordResetLink({ email: user.email }, true);

    onInstructionsSent();
  };

  const getText = () => {
    switch (true) {
      case isViewerTargetUser:
        return (
          <>
            <span style={{ fontWeight: fontWeightMedium }}>
              Not happy with your profile?
            </span>{" "}
            <Link style={{ textDecoration: "underline" }}>Change it now</Link>
          </>
        );

      default:
        return (
          <>
            <span style={{ fontWeight: fontWeightMedium }}>Is it you?</span>{" "}
            <Link style={{ textDecoration: "underline" }}>Email me</Link>{" "}
            Matchmaking profile instructions
          </>
        );
    }
  };

  return (
    (isGuestUser || isViewerTargetUser) && (
      <StyledSpace
        direction="vertical"
        styledProps={{
          paddingBottom: token.paddingXXS,
          zIndex: token.zIndexBase + zIndexOffset + 1,
        }}
        type={SpaceTypes.Centered}
      >
        <Typography.Text
          onClick={
            isViewerTargetUser
              ? navigateToEventProfileEdit
              : handleSendInstructionsEmailClick
          }
          size={TypographySizes.SM}
          style={{
            display: "block",
            marginTop: -token.marginSM,
          }}
        >
          {getText()}
        </Typography.Text>
      </StyledSpace>
    )
  );
};

export default Header;
