import { PlusOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { Button, Col, Row, Skeleton, Space, Tooltip, theme } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { postUserToEvent } from "api/resources/events";
import ConfirmationModal from "components/ConfirmationModal";
import Placeholder from "components/Placeholder";
import Window from "components/Window";
import { Link, Typography, TypographySizes } from "components/antd";
import {
  formatCountdownInDays,
  formatDatesRange,
  formatUserFullName,
  isLoaded,
} from "helpers";
import {
  useBreakpoint,
  useDispatch,
  useEffectOnValueChange,
  useNavigateToPath,
  useSelector,
} from "hooks";
import SinglePageLayout from "layouts/SinglePage";
import { ProtectedPaths } from "routes";
import type { EventProps } from "store";
import { fetchAllEvents, fetchJoinedEvents, unsetEvents } from "store/appSlice";
import {
  selectAccount,
  selectAccountId,
  selectAllEvents,
  selectJoinedEvents,
} from "store/selectors";
import { styled } from "styles";
import type { StyledProps } from "styles";

import EnterCode from "./EnterCode";
import Filters, { FilterValues } from "./Filters";
import Item from "./Item";

const StyledButtonWrapper = styled.div(
  ({ styledProps: { bottom, left, position, zIndex } }: StyledProps) =>
    position && {
      bottom,
      left,
      position,
      zIndex,
    }
);

const StyledPlaceholder = styled(Placeholder)(
  ({ styledProps: { marginTop } }: StyledProps) => ({
    marginTop,
  })
);

const Events = () => {
  const { isDesktop } = useBreakpoint();
  const navigate = useNavigate();
  const navigateToEventsDiscover = useNavigateToPath([
    ProtectedPaths.Events,
    FilterValues.Discover,
  ]);
  const { filter = FilterValues.Joined } = useParams();
  const buttonWrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const allEvents = useSelector(selectAllEvents);
  const joinedEvents = useSelector(selectJoinedEvents);
  const account = useSelector(selectAccount);
  const accountId = useSelector(selectAccountId);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isEnterCodeWindowOpen, setIsEnterCodeWindowOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const { token } = theme.useToken();

  useEffect(
    function openPromptBasedOnEventStatus() {
      if (selectedEvent) {
        if (
          selectedEvent.isLocked &&
          !joinedEvents.some((event) => event.path === selectedEvent.path)
        ) {
          setIsEnterCodeWindowOpen(true);
        } else if (
          joinedEvents.some((event) => event.path === selectedEvent.path)
        ) {
          navigate(`/${selectedEvent.path}/${ProtectedPaths.EventHome}`);
        } else {
          setIsConfirmationModalOpen(true);
        }
      }
    },
    [joinedEvents, navigate, selectedEvent]
  );

  const navigateToDiscoverTabIfNoJoinedEvents = useCallback(() => {
    joinedEvents?.length === 0 && navigateToEventsDiscover();
  }, [joinedEvents?.length, navigateToEventsDiscover]);

  useEffectOnValueChange(
    navigateToDiscoverTabIfNoJoinedEvents,
    joinedEvents?.length
  );

  const handleEventClick = (event: EventProps) => {
    event.isOnboardingStarted
      ? navigate(`/${event.path}`)
      : setSelectedEvent(event);
  };

  const onSubmit = async () => {
    const data = {
      eventCode: selectedEvent.eventCode,
    };

    postUserToEvent(accountId, selectedEvent.path, data).then(() =>
      navigate(`/${selectedEvent.path}/${ProtectedPaths.EventHome}`)
    );
  };

  const filteredEvents =
    filter === FilterValues.Joined
      ? joinedEvents
      : allEvents?.filter(
          (event1) =>
            !joinedEvents?.some((event2) => event2.path === event1.path)
        );
  const isEventsLoaded = isLoaded(allEvents) && isLoaded(joinedEvents);
  const isFixed = !isDesktop;

  return (
    <>
      <SinglePageLayout isCompact>
        <Row justify="center">
          <Col span={24} xl={18}>
            <Space direction="vertical" size="large">
              {isLoaded(account) ? (
                <Typography.Title level={2}>
                  Welcome, {formatUserFullName(account)}!
                </Typography.Title>
              ) : (
                <Space style={{ alignItems: "flex-start" }}>
                  <Typography.Title level={2}>Welcome,</Typography.Title>
                  <Skeleton.Input size="large" />
                </Space>
              )}

              {isEventsLoaded ? (
                <>
                  <Space style={{ justifyContent: "space-between" }}>
                    <Filters activeFilter={filter as FilterValues} />

                    <StyledButtonWrapper
                      ref={buttonWrapperRef}
                      styledProps={{
                        bottom: token.sizeLG,
                        left: token.sizeXL,
                        position: isFixed && "fixed",
                        zIndex: token.zIndexBase + 1,
                      }}
                    >
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => setIsEnterCodeWindowOpen(true)}
                        shape="round"
                        size="large"
                        type="primary"
                      >
                        Join with code
                      </Button>
                      <Tooltip
                        placement="top"
                        title="Is your event not listed? Secret events can be accessed by inserting the event code."
                      >
                        <QuestionCircleFilled
                          style={{
                            fontSize: TypographySizes.LG,
                            position: "relative",
                            top: -token.sizeLG,
                          }}
                        />
                      </Tooltip>
                    </StyledButtonWrapper>
                  </Space>

                  <Row gutter={[40, 40]}>
                    {filteredEvents.length ? (
                      filteredEvents.map((event: EventProps, index) => (
                        <Col key={index} md={12} span={24}>
                          <Item
                            coverImageSrc={event.coverImageSrc}
                            isOnboardingUnfinished={
                              event.isOnboardingStarted &&
                              !event.isOnboardingFinished
                            }
                            dates={formatDatesRange(
                              event.startDate,
                              event.endDate
                            )}
                            countdownInDays={formatCountdownInDays(
                              event.startDate,
                              event.endDate
                            )}
                            locked={event.isLocked}
                            onClick={() => handleEventClick(event)}
                            title={event.title}
                          />
                        </Col>
                      ))
                    ) : (
                      <Space
                        direction="vertical"
                        size={token.sizeXXL}
                        style={{
                          margin: "auto",
                          marginTop: token.marginXXL,
                          textAlign: "center",
                        }}
                      >
                        {filter === "joined" ? (
                          <>
                            <Typography.Title level={3}>
                              You haven't joined any events yet
                            </Typography.Title>
                            <Space direction="vertical">
                              <Typography.Text size={TypographySizes.MD}>
                                Choose an event to join from the Discover tab or
                                join by code.
                              </Typography.Text>
                            </Space>
                          </>
                        ) : (
                          <>
                            <Typography.Title level={3}>
                              There are currently no new events
                            </Typography.Title>
                            <Space direction="vertical">
                              <Typography.Text size={TypographySizes.SM}>
                                Wish to use Exponaut Matchmaking at your event?
                              </Typography.Text>
                              <Typography.Text size={TypographySizes.SM}>
                                Write to{" "}
                                <Link to="mailto:info@exponaut.me">
                                  info@exponaut.me
                                </Link>{" "}
                                and your personal client manager will write you
                                back.
                              </Typography.Text>
                            </Space>
                          </>
                        )}
                      </Space>
                    )}
                  </Row>
                </>
              ) : (
                <Skeleton />
              )}
            </Space>
          </Col>
        </Row>

        {isEventsLoaded && isFixed && (
          <StyledPlaceholder
            originRef={buttonWrapperRef}
            styledProps={{ marginTop: token.marginXL }}
          />
        )}
      </SinglePageLayout>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => {
          setIsConfirmationModalOpen(false);
          setSelectedEvent(undefined);
        }}
        title={`Are you sure you wish to join ${selectedEvent?.title}?`}
        onOk={() => onSubmit()}
      />
      <Window
        onAfterOpen={() => inputRef.current?.focus()}
        isOpen={isEnterCodeWindowOpen}
        onClose={() => {
          setIsEnterCodeWindowOpen(false);
          setSelectedEvent(undefined);
        }}
        width={700}
      >
        <EnterCode
          inputRef={inputRef}
          setSelectedEvent={setSelectedEvent}
          setIsEnterCodeWindowOpen={setIsEnterCodeWindowOpen}
          setIsConfirmationModalOpen={setIsConfirmationModalOpen}
          ticketUrl={selectedEvent?.ticketUrl}
        />
      </Window>
    </>
  );
};

type EventsProviderProps = {
  children: React.ReactNode;
};

export const EventsProvider = ({ children }: EventsProviderProps) => {
  const dispatch = useDispatch();
  const accountId = useSelector(selectAccountId);

  useEffect(
    function fetchOnMountAndUnsetOnUnmount() {
      dispatch(fetchAllEvents());
      dispatch(fetchJoinedEvents(accountId));

      return () => {
        dispatch(unsetEvents());
      };
    },
    [accountId, dispatch]
  );

  return <>{children}</>;
};

export default () => (
  <EventsProvider>
    <Events />
  </EventsProvider>
);
