import { useParams } from "react-router-dom";

import { isLoaded } from "helpers";
import { useDatabaseListener, useSelector } from "hooks";
import { FirestoreReducerKeys } from "store";
import { selectAccountId } from "store/selectors";

type MeetingsProviderProps = {
  children: React.ReactNode;
};

const MeetingsProvider = ({ children }: MeetingsProviderProps) => {
  const accountId = useSelector(selectAccountId);
  const { eventId } = useParams();

  useDatabaseListener([
    {
      collection: "meetings",
      storeAs: FirestoreReducerKeys.ReceivedMeetings,
      ...(isLoaded(accountId) && {
        where: [
          ["invitee_id", "==", accountId],
          ["eventId", "==", eventId],
        ],
      }),
    },
    {
      collection: "meetings",
      storeAs: FirestoreReducerKeys.SentMeetings,
      ...(isLoaded(accountId) && {
        where: [
          ["inviter_id", "==", accountId],
          ["eventId", "==", eventId],
        ],
      }),
    },
  ]);

  return <>{children}</>;
};

export default MeetingsProvider;
