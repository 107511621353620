import { Button, Form, Space, Typography } from "antd";
import dayjs from "dayjs";
import { serverTimestamp } from "firebase/firestore";
import { useCallback, useMemo, useState } from "react";

import Window from "components/Window";
import { Input, InputAppearances } from "components/antd";
import sendEmail from "database/handles/sendEmail";
import { useDatabase } from "hooks";
import { ProfileProps } from "store";
import { styled } from "styles";
import { FirestoreStatuses } from "views/Meetings";

import styles from "./Meetings.module.scss";

const StyledSpace = styled(Space)({
  margin: "auto",
  width: 300,
});

enum FieldValues {
  Message = "message",
}

type DeclineMeetingWindowProps = {
  viewer: ProfileProps;
  user: ProfileProps;
  declineMeeting: any;
  onClearDeclineMeeting: () => void;
};

const DeclineMeetingWindow = ({
  viewer,
  user,
  declineMeeting,
  onClearDeclineMeeting,
}: DeclineMeetingWindowProps) => {
  const database = useDatabase();
  const [declineMessage, setDeclineMessage] = useState(null);

  const declineMessages = useMemo(() => {
    return [
      "I unfortunately can not accept this meeting offer",
      "This time does not suit me, please propose a new meeting",
    ];
  }, []);

  const handleDeclineMessageClick = useCallback(
    (i) => {
      if (declineMessage === i) {
        setDeclineMessage(null);
      } else {
        setDeclineMessage(i);
      }
    },
    [declineMessage]
  );

  const onClose = useCallback(() => {
    onClearDeclineMeeting();
  }, [onClearDeclineMeeting]);

  const onSubmit = useCallback(async () => {
    let message = null;

    if (typeof declineMessage === "number") {
      message = declineMessages[declineMessage];
    } else {
      message = declineMessage;
    }

    const data = {
      status: FirestoreStatuses.Declined,
      decline_message: message || "",
      declined_at: serverTimestamp(),
    };

    await database
      .collection("meetings")
      .doc(declineMeeting.id)
      .update(data)
      .then(() => {
        // Send an email to the inviter letting them know I have declined the meeting offer
        const emailData = {
          inviteeFullName: viewer.firstName + " " + viewer.lastName,
          inviterName: user.firstName,
          date: dayjs(declineMeeting.proposed_time.toDate()).format(
            "DD/MM/YYYY"
          ),
          time: dayjs(declineMeeting.proposed_time.toDate()).format("HH:mm"),
          decliningReason: data.decline_message || "No reason added.",
        };
        sendEmail(user.email, emailData, "d-cc571bc19608417385daedf482e28710");
        onClose();
      });
  }, [
    onClose,
    database,
    declineMeeting,
    declineMessage,
    declineMessages,
    viewer,
    user,
  ]);

  return (
    <Window isOpen={!!declineMeeting} onClose={onClose}>
      <Space
        direction="vertical"
        style={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          margin: "4rem 0",
        }}
      >
        <>
          <Typography.Title level={1}>Decline meeting</Typography.Title>
          <Form onFinish={onSubmit}>
            <StyledSpace
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
              direction="vertical"
            >
              {declineMessages.map((m, i) => (
                <Button
                  shape="round"
                  type={declineMessage === i ? "primary" : "dashed"}
                  onClick={() => handleDeclineMessageClick(i)}
                  className={styles.cancelMessage}
                  key={"DeclineMessage-" + i}
                >
                  {m}
                </Button>
              ))}
              <Form.Item
                style={{ margin: "2rem 0", width: "330px" }}
                name={FieldValues.Message}
              >
                <Input
                  appearance={InputAppearances.Underline}
                  onChange={(e: any) => setDeclineMessage(e.target.value)}
                  placeholder="Custom reply, click to type…"
                />
              </Form.Item>
              <StyledSpace style={{ justifyContent: "center" }}>
                <Button shape="round" htmlType="submit" type="primary">
                  Decline meeting
                </Button>
              </StyledSpace>
            </StyledSpace>
          </Form>
        </>
      </Space>
    </Window>
  );
};

export default DeclineMeetingWindow;
