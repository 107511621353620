import { Typography as AntdTypography, ConfigProvider } from "antd";
import { cloneElement, forwardRef } from "react";

import { getPreviousEnumValue } from "helpers/enums";
import { useBreakpoint } from "hooks";
import { styled } from "styles";
import type { StyledProps } from "styles";
import {
  fontSizeHeading1Responsive,
  fontSizeHeading2Responsive,
  fontSizeHeading3Responsive,
  fontSizeHeading4Responsive,
  fontSizeHeading5Responsive,
  fontSizeLG,
  fontSizeMD,
  fontSizeSM,
  fontSizeXL,
  fontSizeXS,
  fontSizeXXL,
  fontSizeXXS,
  fontWeightBold,
  fontWeightMedium,
  fontWeightRegular,
} from "styles/variables";

import type {
  GenericProps,
  TypographyLevelProps,
  TypographyProps,
} from "./types";

export enum TypographySizes {
  XXS = fontSizeXXS,
  XS = fontSizeXS,
  SM = fontSizeSM,
  MD = fontSizeMD,
  LG = fontSizeLG,
  XL = fontSizeXL,
  XXL = fontSizeXXL,
}

const StyledEllipsisWrapper = styled.div(
  ({ styledProps: { height, maxHeight } }: StyledProps) => ({
    height,
    lineHeight: 1,
    maxHeight,
  })
);

const getTitleLevelFontWeight = (titleLevel: TypographyLevelProps) => {
  switch (titleLevel) {
    case 1:
      return fontWeightBold;
    case 2:
      return fontWeightMedium;
    case 3:
      return fontWeightRegular;
    case 4:
      return fontWeightBold;
    case 5:
      return fontWeightMedium;
  }
};

type ResponsiveTypographyProps = {
  element: React.ReactElement;
  titleLevel?: TypographyLevelProps;
};

type TypographyParagraphProps = {
  isEllipsisRowsVisible?: boolean;
};

type TypographySharedProps = {
  color?: string;
  size?: TypographySizes;
  uppercase?: boolean;
};

const ResponsiveTypography = ({
  color: colorText,
  element,
  isEllipsisRowsVisible,
  size,
  titleLevel,
  uppercase,
}: ResponsiveTypographyProps &
  TypographyParagraphProps &
  TypographySharedProps) => {
  const { isDesktop } = useBreakpoint();

  const nonResponsiveSize = size;
  const responsiveSize = isDesktop
    ? size
    : getPreviousEnumValue(TypographySizes, size); // TODO: Analyse how to properly implement responsive size, then replace with nonResponsiveSize
  const fontSize = nonResponsiveSize || TypographySizes.MD;
  const lineHeight = (nonResponsiveSize || TypographySizes.MD) / 10;

  const clonedElement = cloneElement(element, {
    ...element.props,
    style: {
      ...(titleLevel && {
        fontWeight: getTitleLevelFontWeight(titleLevel),
      }),
      ...(uppercase && {
        letterSpacing: 1,
        textTransform: "uppercase",
      }),
      ...element.props.style,
    },
  });

  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: !isDesktop && {
            fontSizeHeading1: fontSizeHeading1Responsive,
            fontSizeHeading2: fontSizeHeading2Responsive,
            fontSizeHeading3: fontSizeHeading3Responsive,
            fontSizeHeading4: fontSizeHeading4Responsive,
            fontSizeHeading5: fontSizeHeading5Responsive,
          },
        },
        token: {
          ...(colorText && { colorText }),
          fontSize,
          lineHeight,
        },
      }}
    >
      {isEllipsisRowsVisible ? (
        <StyledEllipsisWrapper
          styledProps={{
            [isEllipsisRowsVisible ? "height" : "maxHeight"]:
              element.props.ellipsis.rows * (fontSize * lineHeight),
          }}
        >
          {clonedElement}
        </StyledEllipsisWrapper>
      ) : (
        clonedElement
      )}
    </ConfigProvider>
  );
};

type CompoundedProps = GenericProps & TypographyProps & TypographySharedProps;

type CompoundedComponent = React.ForwardRefExoticComponent<
  CompoundedProps & React.RefAttributes<HTMLElement>
> & {
  Paragraph: (
    props: CompoundedProps &
      TypographyParagraphProps &
      React.RefAttributes<HTMLElement>
  ) => JSX.Element;
  Text: (
    props: CompoundedProps & React.RefAttributes<HTMLElement>
  ) => JSX.Element;
  Title: (
    props: CompoundedProps & React.RefAttributes<HTMLElement>
  ) => JSX.Element;
};

const Typography = forwardRef(
  ({ color, size, uppercase, ...restProps }: CompoundedProps, ref) => (
    <ResponsiveTypography
      element={<AntdTypography {...restProps} ref={ref} />}
      color={color}
      size={size}
      uppercase={uppercase}
    />
  )
) as CompoundedComponent;

Typography.Paragraph = forwardRef(
  (
    {
      color,
      isEllipsisRowsVisible,
      size,
      uppercase,
      ...restProps
    }: CompoundedProps & TypographyParagraphProps,
    ref
  ) => (
    <ResponsiveTypography
      element={<AntdTypography.Paragraph {...restProps} ref={ref} />}
      color={color}
      isEllipsisRowsVisible={isEllipsisRowsVisible}
      size={size}
      uppercase={uppercase}
    />
  )
);

Typography.Text = forwardRef(
  ({ color, size, uppercase, ...restProps }: CompoundedProps, ref) => (
    <ResponsiveTypography
      element={<AntdTypography.Text {...restProps} ref={ref} />}
      color={color}
      size={size}
      uppercase={uppercase}
    />
  )
);

Typography.Title = forwardRef(
  ({ color, size, uppercase, ...restProps }: CompoundedProps, ref) => (
    <ResponsiveTypography
      element={<AntdTypography.Title {...restProps} ref={ref} />}
      color={color}
      size={size}
      uppercase={uppercase}
      titleLevel={restProps.level}
    />
  )
);

export default Typography;
