import { RocketOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd";

import { Layout, Typography } from "components/antd";
import { useNavigateToPath } from "hooks";
import { UnprotectedPaths } from "routes";

const SessionExpiredPage = () => {
  const navigateToEntry = useNavigateToPath(UnprotectedPaths.Entry);

  return (
    <Layout
      isFullHeight
      style={{
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Row justify="center">
        <Col span={20}>
          <Space direction="vertical" size="large">
            <Space direction="vertical">
              <Typography.Title>
                401. Unauthorized Cosmic Exploration Detected!
              </Typography.Title>
              <Typography.Title level={2}>
                You need to provide your credentials before seeing this page.
              </Typography.Title>
              <Typography.Title level={3}>
                To continue, please log back in.
              </Typography.Title>
            </Space>
            <Button
              icon={<RocketOutlined />}
              onClick={navigateToEntry}
              shape="round"
              size="large"
              type="primary"
            >
              Log in
            </Button>
          </Space>
        </Col>
      </Row>
    </Layout>
  );
};

export default SessionExpiredPage;
