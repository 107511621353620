import { Typography, TypographySizes } from "components/antd";
import { styled } from "styles";

const StyledErrorMessageWrapper = styled.div({
  textAlign: "left",
});

type ErrorFieldProps = {
  children: React.ReactNode;
};

const ErrorField = ({ children }: ErrorFieldProps) => (
  <StyledErrorMessageWrapper className="ant-form-item-explain">
    <Typography.Text size={TypographySizes.SM} type="danger">
      {children}
    </Typography.Text>
  </StyledErrorMessageWrapper>
);

export default ErrorField;
