import { Button, ConfigProvider, Space, theme } from "antd";

import { useNavigateToPath } from "hooks";
import { ProtectedPaths } from "routes";

export enum FilterValues {
  Joined = "joined",
  Discover = "discover",
}

type FiltersProps = {
  activeFilter: FilterValues;
};

const Filters = ({ activeFilter }: FiltersProps) => {
  const navigateToEvents = useNavigateToPath(ProtectedPaths.Events);
  const navigateToEventsDiscover = useNavigateToPath([
    ProtectedPaths.Events,
    FilterValues.Discover,
  ]);
  const { token } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: token.colorTextBase,
            colorTextLightSolid: token.colorBgLayout,
          },
        },
      }}
    >
      <Space>
        <Button
          ghost={activeFilter !== FilterValues.Joined}
          onClick={navigateToEvents}
          shape="round"
          size="large"
          type="primary"
        >
          My events
        </Button>
        <Button
          ghost={activeFilter !== FilterValues.Discover}
          onClick={navigateToEventsDiscover}
          shape="round"
          size="large"
          type="primary"
        >
          Discover new events
        </Button>
      </Space>
    </ConfigProvider>
  );
};

export default Filters;
