import emptySrc from "assets/images/empty.png";
import { Space, SpaceTypes, Typography } from "components/antd";

const Error = () => (
  <Space direction="vertical" size="large" type={SpaceTypes.Centered}>
    <Typography.Title level={4} style={{ margin: 0 }}>
      This attendee has not yet joined Matchmaking
    </Typography.Title>

    <img height={85} width={138} src={emptySrc} />
  </Space>
);

export default Error;
