import { Button, Col, Row, Space } from "antd";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import onboardingIntroductionImageSrc from "assets/images/onboarding-introduction.png";
import { Typography, TypographySizes } from "components/antd";
import {
  useDispatch,
  useEffectOnValueChange,
  useInterestTags,
  useNavigateToPath,
  useOnboarding,
  useSelector,
} from "hooks";
import { useScroll } from "hooks/useScroll";
import { ProtectedPaths } from "routes";
import { fetchUserEventUpdateProfile } from "store/appSlice";
import {
  selectAccountId,
  selectEventProfile,
  selectEventProfileIsOnboardingFinished,
} from "store/selectors";
import { styled } from "styles";
import { sizeXXL } from "styles/variables";
import EditInterests, { FirestoreKeys } from "views/Edit/Interests";
import EditProfile from "views/Edit/Profile";
import EditLayoutFooter from "views/Edit/layout/Footer";
import PreviewProfile from "views/PreviewProfile";

import OnboardingLayout from "./layout";

export enum StepIndexes {
  "expertise",
  "info",
  "preview",
}

export type ParamsProps = {
  eventId: string;
  step: keyof typeof StepIndexes;
};

const StyledHead = styled.div({ textAlign: "center" });

const StyledImg = styled.img({
  display: "block",
  margin: "auto",
  maxWidth: 523,
  width: "100%",
});

const Onboarding = () => {
  const dispatch = useDispatch();
  useInterestTags();
  const navigateToEventHome = useNavigateToPath(ProtectedPaths.EventHome);
  const { onStepChange } = useOnboarding();
  const { eventId, step } = useParams<ParamsProps>();
  const accountId = useSelector(selectAccountId);
  const joinedEventProfile = useSelector(selectEventProfile);
  const isOnboardingFinished = useSelector(
    selectEventProfileIsOnboardingFinished
  );
  const scrollOnStepChange = useScroll();
  useEffectOnValueChange(scrollOnStepChange, step);

  useEffect(
    () => joinedEventProfile && isOnboardingFinished && navigateToEventHome(),
    [isOnboardingFinished, joinedEventProfile, navigateToEventHome]
  );

  const finishOnboarding = useCallback(async () => {
    dispatch(
      fetchUserEventUpdateProfile(accountId, eventId, {
        isOnboardingFinished: true,
      })
    );
  }, [accountId, dispatch, eventId]);

  const introductionElement = (
    <>
      <StyledHead>
        <Typography.Title>Set up your profile</Typography.Title>
        <Typography.Text size={TypographySizes.LG}>
          In the next 3 steps we will help to set up your event profile and how
          you will be displayed for others to see.
        </Typography.Text>
      </StyledHead>
      <StyledImg src={onboardingIntroductionImageSrc} />
      <EditLayoutFooter>
        <Button shape="round" onClick={() => onStepChange(0)} type="primary">
          Start
        </Button>
      </EditLayoutFooter>
    </>
  );

  const stepElements = useMemo(
    () => ({
      [StepIndexes[0]]: (
        <Space direction="vertical" size={sizeXXL}>
          <StyledHead>
            <Typography.Title>Your expertise</Typography.Title>
            <Typography.Text size={TypographySizes.LG}>
              Choose tags on the topics you feel comfortable sharing knowledge
              about. If you do not have any tags to choose you may skip this
              step.
            </Typography.Text>
          </StyledHead>
          <EditInterests
            firestoreKeys={[FirestoreKeys.business, FirestoreKeys.network]}
            onDone={() => onStepChange(1)}
          />
        </Space>
      ),
      [StepIndexes[1]]: (
        <Space direction="vertical" size={sizeXXL}>
          <StyledHead>
            <Typography.Title>Your info</Typography.Title>
            <Typography.Text size={TypographySizes.LG}>
              Fill out your matchmaking profile
            </Typography.Text>
          </StyledHead>
          <EditProfile onSaveFinished={() => onStepChange(2)} />
        </Space>
      ),
      [StepIndexes[2]]: (
        <>
          <StyledHead>
            <Typography.Title>Preview your profile</Typography.Title>
            <Typography.Paragraph size={TypographySizes.LG}>
              This is how your profile will look to others
            </Typography.Paragraph>
          </StyledHead>
          <PreviewProfile />
          <EditLayoutFooter>
            <Button onClick={finishOnboarding} shape="round" type="primary">
              Enter event
            </Button>
          </EditLayoutFooter>
        </>
      ),
    }),
    [finishOnboarding, onStepChange]
  );

  return (
    <OnboardingLayout>
      <Row justify="center">
        <Col span={24} xl={16}>
          <Space direction="vertical" size="large">
            {step ? stepElements[step] : introductionElement}
          </Space>
        </Col>
      </Row>
    </OnboardingLayout>
  );
};

export default Onboarding;
