import { Segmented as AntdSegmented, ConfigProvider, theme } from "antd";

import { styled } from "styles";
import type { StyledProps } from "styles";
import {
  colorGrayButtonBackground,
  segmentedBorderRadiusSM,
  segmentedColorText,
  segmentedColorTextLabelMidTone,
  segmentedTransitionDurationIn,
  segmentedTransitionDurationOut,
} from "styles/variables";

const StyledSegmented = styled(AntdSegmented)(
  ({ styledProps: { textShadowColor } }: StyledProps) => ({
    "&.ant-segmented": {
      ".ant-segmented-item": {
        textShadow: `0 0 ${textShadowColor}`,
        transition: `color ${segmentedTransitionDurationIn}s`,
        "&.ant-segmented-item-selected": {
          transition: `color ${segmentedTransitionDurationOut}s`,
        },
        "&:not(.ant-segmented-item-selected):hover": {
          color: "inherit",
        },
      },
    },
  })
);

type SegmentedOptionsProps = {
  label: React.ReactNode;
  value: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  className?: string;
};

type SegmentedProps = {
  onChange: (value: string | number) => void;
  options: SegmentedOptionsProps[];
  value: string | number;
};

const Segmented = ({ onChange, options, value }: SegmentedProps) => {
  const { token } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        components: {
          Segmented: {
            borderRadius: segmentedBorderRadiusSM,
            borderRadiusSM: segmentedBorderRadiusSM,
            colorBgElevated: token.colorTextBase,
            colorBgLayout: colorGrayButtonBackground,
            colorFillSecondary: "transparent",
            colorText: segmentedColorText,
            colorTextLabel: segmentedColorTextLabelMidTone,
          },
        },
      }}
    >
      <StyledSegmented
        block
        onChange={onChange}
        options={options}
        styledProps={{ textShadowColor: segmentedColorText }}
        value={value}
      />
    </ConfigProvider>
  );
};

export default Segmented;
