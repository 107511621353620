import classNames from "classnames";

import { colorIconFill } from "styles/variables";

import type { FilledIcon } from ".";

export const VideoCallFilled = ({
  className,
  fill = colorIconFill,
  fontSize = 14, // TODO: Apply to all icons
  height = 20,
  style,
  width = 25,
}: FilledIcon) => {
  const ratio = width / height;

  return (
    <svg
      className={classNames("anticon", className)}
      fill="none"
      height={fontSize}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
      width={fontSize * ratio}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9487 7.32122V12.6784C17.9487 16.0031 15.3622 18.7051 12.1795 18.7051H5.76923C2.58654 18.7051 0 16.0031 0 12.6784V7.32122C0 3.99644 2.58654 1.29443 5.76923 1.29443H12.1795C15.3622 1.29443 17.9487 3.99644 17.9487 7.32122ZM23.8462 4.29443C23.1378 3.89934 22.282 3.94287 21.6186 4.40827L20.157 5.42613C19.9776 5.55001 19.8718 5.76095 19.8718 5.98193V14.0176C19.8718 14.242 19.9776 14.4496 20.157 14.5735L21.6154 15.5913C21.9776 15.8458 22.3974 15.973 22.8205 15.973C23.1699 15.973 23.5224 15.8826 23.8429 15.7051C24.5545 15.3101 24.9968 14.54 24.9968 13.6962V6.30336C24.9968 5.45961 24.5545 4.68952 23.8429 4.29443H23.8462Z"
        fill={fill}
      />
    </svg>
  );
};
