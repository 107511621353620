import { LeftOutlined } from "@ant-design/icons";
import { Col, ConfigProvider, Row, theme } from "antd";

import logoImageSrc from "assets/images/logo.png";
import AccountDropdown from "components/AccountDropdown";
import { Button, Link, Typography } from "components/antd";
import { isEmpty, isFunction } from "helpers";
import { useBreakpoint, useNavigateToReturnPath, useSelector } from "hooks";
import { UnprotectedPaths } from "routes";
import { selectAccount } from "store/selectors";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { colorWhite } from "styles/variables";

import { StyledLayoutHeader } from "..";
import GeneralButton from "./GeneralButton";

const StyledCol = styled(Col)(
  ({ styledProps: { justifyContent } }: StyledProps) => ({
    alignItems: "center",
    display: "flex",
    justifyContent,
  })
);

const StyeldHeader = styled(Row)({
  width: "100%",
});

const StyledLink = styled(Link)(({ styledProps: { width } }: StyledProps) => ({
  display: "flex",
  width,
}));

const StyledLogo = styled.img({
  width: "100%",
});

type HeaderProps = { isLarge: boolean };

const Header = ({ isLarge }: HeaderProps) => {
  const { isDesktop } = useBreakpoint();
  const navigateToReturnPath = useNavigateToReturnPath();
  const account = useSelector(selectAccount);
  const { token } = theme.useToken();

  const isAccountVisible = !isEmpty(account);
  const isAccountVisibleInMobile = isAccountVisible && !isDesktop;
  const isBackVisible = isFunction(navigateToReturnPath);
  const logoWidth = isLarge ? 222 : 150;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorLink: colorWhite,
        },
      }}
    >
      <StyledLayoutHeader
        styledProps={{
          backgroundColor: "transparent",
          zIndex: token.zIndexPopupBase,
        }}
      >
        <StyeldHeader justify="center">
          <StyledCol span={6} styledProps={{ justifyContent: "flex-start" }}>
            {isBackVisible && (
              <Button
                icon={<LeftOutlined />}
                onClick={navigateToReturnPath}
                type="link"
              >
                <Typography.Text strong>Back</Typography.Text>
              </Button>
            )}
          </StyledCol>

          <StyledCol
            span={8}
            styledProps={{
              justifyContent: "center",
            }}
          >
            <StyledLink
              styledProps={{
                width: isAccountVisibleInMobile ? 82 : logoWidth,
              }}
              to={UnprotectedPaths.Entry}
            >
              <StyledLogo src={logoImageSrc} />
            </StyledLink>
          </StyledCol>

          <StyledCol
            span={6}
            styledProps={{
              justifyContent: "flex-end",
            }}
          >
            {isAccountVisible && (
              <>{isDesktop ? <AccountDropdown /> : <GeneralButton />}</>
            )}
          </StyledCol>
        </StyeldHeader>
      </StyledLayoutHeader>
    </ConfigProvider>
  );
};

export default Header;
