import { RocketOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, theme } from "antd";

import { Layout, Typography, TypographySizes } from "components/antd";
import { useNavigateToPath } from "hooks";
import { UnprotectedPaths } from "routes";

const NotFoundPage = () => {
  const navigateToEntry = useNavigateToPath(UnprotectedPaths.Entry);
  const { token } = theme.useToken();

  return (
    <Layout
      isFullHeight
      style={{
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Row justify="center">
        <Col span={20}>
          <Space direction="vertical" size="middle">
            <Typography.Title>The link has expired</Typography.Title>
            <Typography.Title level={3}>
              It seems that the one-time login link you've used is no longer
              valid.
              <br />
              For your account security these links are set to be used within 15
              minutes.
            </Typography.Title>
            <Typography.Text size={TypographySizes.LG}>
              How to proceed?
            </Typography.Text>
            <Typography.Text size={TypographySizes.LG}>
              1 - Go back to home page
              <br />2 - Enter your account email
              <br />3 - You will receive a new one time log in link
            </Typography.Text>
            <Button
              ghost
              icon={<RocketOutlined />}
              onClick={navigateToEntry}
              shape="round"
              size="large"
              style={{ marginTop: token.margin }}
              type="primary"
            >
              Back to home
            </Button>
          </Space>
        </Col>
      </Row>
    </Layout>
  );
};

export default NotFoundPage;
