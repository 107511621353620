import { QuestionCircleFilled } from "@ant-design/icons";
import { Col, Row, Space, Tabs, Tooltip, theme } from "antd";
import { useCallback, useEffect, useState } from "react";

import { Typography, TypographySizes } from "components/antd";
import { isEqual, isNumber } from "helpers";
import { useBreakpoint, useSelector } from "hooks";
import type { ProfileInterestsProps } from "store";
import {
  selectEventInterestsAllTags,
  selectEventProfileInterests,
} from "store/selectors";
import { styled } from "styles";
import EditInterests, { FirestoreKeys } from "views/Edit/Interests";

enum Values {
  All,
  Custom,
  Expertise,
}

type NetworkingPreferencesProps = {
  onDone: () => void;
};

const StyledTabs = styled(Tabs)({
  "&.ant-tabs": {
    "&.ant-tabs-centered": {
      ".ant-tabs-tab": {
        justifyContent: "center",
      },
    },
    ".ant-tabs-nav": {
      ".ant-tabs-nav-list": {
        width: "100%",
        ".ant-tabs-tab": {
          width: "100%",
        },
      },
    },
  },
});

const NetworkingPreferences = ({ onDone }: NetworkingPreferencesProps) => {
  const { isDesktop, xs } = useBreakpoint();
  const eventInterestsAllTags = useSelector(selectEventInterestsAllTags);
  const eventProfileInterests = useSelector(selectEventProfileInterests);
  const [userInterestsCustomTags, setUserInterestsCustomTags] = useState(null);
  const [value, setValue] = useState(undefined);
  const { token } = theme.useToken();

  const compareSelectedTags = useCallback(
    (userInterests: ProfileInterestsProps) => {
      const isSelectedTagsEqualToAllTags = isEqual(
        userInterests.network,
        eventInterestsAllTags
      );
      const isSelectedTagsEqualToExpertiseTags = isEqual(
        userInterests.network,
        userInterests.business
      );
      const isSelectedTagsCustomTags =
        !isSelectedTagsEqualToAllTags && !isSelectedTagsEqualToExpertiseTags;

      if (isSelectedTagsCustomTags) {
        setUserInterestsCustomTags(userInterests.network);

        setValue(Values.Custom);
      } else {
        setValue(
          isSelectedTagsEqualToExpertiseTags ? Values.Expertise : Values.All
        );
      }
    },
    [eventInterestsAllTags]
  );

  useEffect(() => {
    compareSelectedTags(eventProfileInterests);
  }, [compareSelectedTags, eventProfileInterests]);

  const handleChange = (activeKey: Values) => {
    setValue(activeKey);

    activeKey === Values.Custom &&
      !userInterestsCustomTags &&
      setUserInterestsCustomTags([]);
  };

  const handleTagChange = (userInterests: ProfileInterestsProps) => {
    compareSelectedTags(userInterests);
  };

  const userInterestTagsOverride: any = {
    [Values.All]: eventInterestsAllTags,
    [Values.Custom]: userInterestsCustomTags,
    [Values.Expertise]:
      eventProfileInterests[FirestoreKeys[FirestoreKeys.business]],
  };

  const allLabel = !xs ? "All interests" : "All";
  const customLabel = !xs ? "Custom selection" : "Custom";
  const expertiseLabel = !xs ? "Expertise based" : "Expertise";

  const tooltipIconWidth = token.fontSize;

  return (
    <Row justify="center">
      <Col span={24} lg={16}>
        <Space direction="vertical" size="large">
          <div style={{ textAlign: "center" }}>
            <Typography.Title>Network about</Typography.Title>
            <Typography.Text size={TypographySizes.LG}>
              Select interests that best represent topics you would like to
              network about. You can change these as many times as you would
              like and new matches will be displayed.
            </Typography.Text>{" "}
            {!isDesktop && value === Values.Expertise && (
              <Typography.Text size={TypographySizes.LG} strong>
                "{expertiseLabel}" matches are displayed based on your
                expertise. Change it by going to Profile → My expertise.
              </Typography.Text>
            )}
          </div>

          {isNumber(value) && (
            <StyledTabs
              activeKey={value}
              centered
              items={[
                {
                  key: Values.All,
                  label: allLabel,
                },
                {
                  key: Values.Custom,
                  label: customLabel,
                },
                {
                  key: Values.Expertise,
                  label: isDesktop ? (
                    <Space>
                      <div style={{ width: tooltipIconWidth }} />
                      {expertiseLabel}
                      <Tooltip title="Matches are displayed based on your expertise. Change it by going to Profile → My expertise">
                        <QuestionCircleFilled
                          style={{ fontSize: tooltipIconWidth }}
                        />
                      </Tooltip>
                    </Space>
                  ) : (
                    expertiseLabel
                  ),
                },
              ]}
              onChange={handleChange}
            />
          )}

          <EditInterests
            firestoreKeys={[FirestoreKeys.network]}
            onChange={handleTagChange}
            onDone={onDone}
            userInterestTagsOverride={userInterestTagsOverride[value]}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default NetworkingPreferences;
