import { GlobalOutlined } from "@ant-design/icons";
import { Col, Row, theme } from "antd";
import { useRef } from "react";

import {
  Space,
  SpaceTypes,
  Typography,
  TypographySizes,
} from "components/antd";
import { Button } from "components/antd";
import { formatTime, isEqual } from "helpers";
import { useOnMount, useRefElementProperties } from "hooks";
import { useScroll } from "hooks/useScroll";
import type { TimeProps, TimeWithAvailabilityProps } from "store";
import { boxShadow, styled } from "styles";
import type { StyledProps } from "styles";

const StyledIcon = styled(GlobalOutlined)(
  ({ styledProps: { marginRight } }: StyledProps) => ({
    marginRight,
  })
);

export const TimeSelectorFooter = () => {
  const { token } = theme.useToken();

  return (
    <Space direction="vertical" size={0} type={SpaceTypes.Centered}>
      <Typography.Text strong>Time zone</Typography.Text>
      <Typography.Text size={TypographySizes.SM}>
        <StyledIcon styledProps={{ marginRight: token.marginXS }} />
        Eastern European Time
      </Typography.Text>
    </Space>
  );
};

export const TimeSelectorHeader = () => {
  const { token } = theme.useToken();

  return (
    <Space
      direction="vertical"
      size={0}
      style={{ marginBottom: token.marginXS }}
      type={SpaceTypes.Centered}
    >
      <Typography.Text size={TypographySizes.LG} strong>
        Select a Time
      </Typography.Text>
      <Typography.Text>Duration 25 min</Typography.Text>
    </Space>
  );
};

const StyledButton = styled(Button)({
  ...boxShadow,
  border: 0,
});

type TimeSelectorProps = {
  onSelect: (time: TimeProps) => void;
  options: TimeWithAvailabilityProps[];
  value: TimeProps;
};

const TimeSelector = ({ onSelect, options, value }: TimeSelectorProps) => {
  const firstAvailableOptionRef = useRef<HTMLDivElement>(null);
  const {
    relativeToParent: { top },
  } = useRefElementProperties(firstAvailableOptionRef);
  const scroll = useScroll({ top });

  useOnMount(scroll);

  return (
    <Row justify="center">
      <Col span={24} md={8}>
        <Space direction="vertical">
          <TimeSelectorHeader />

          {options.map((option: TimeWithAvailabilityProps, index) => {
            const previousOption = options[index - 1];
            const isPreviousOptionNotAvailable =
              previousOption && !previousOption.isAvailable;

            const time: TimeProps = {
              hour: option.hour,
              minute: option.minute,
            };

            return (
              <StyledButton
                block
                disabled={!option.isAvailable}
                key={formatTime(time, "-")}
                onClick={() => onSelect(time)}
                ref={
                  isPreviousOptionNotAvailable && option.isAvailable
                    ? firstAvailableOptionRef
                    : null
                }
                type={isEqual(time, value) ? "primary" : "default"}
              >
                {formatTime(time)}
              </StyledButton>
            );
          })}
        </Space>
      </Col>
    </Row>
  );
};

export default TimeSelector;
