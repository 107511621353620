import { FirestoreReducerKeys } from "store";

import { useDatabaseListener } from "./database";

export const useInterestTags = () => {
  useDatabaseListener([
    {
      collection: "interest_tags",
      storeAs: FirestoreReducerKeys.InterestTags,
    },
  ]);
};
