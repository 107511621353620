import { Col, Row } from "antd";
import { Button, Typography } from "antd";
import { useState } from "react";

import { Space, SpaceTypes } from "components/antd";
import { useNavigateToPath } from "hooks";
import SinglePageLayout from "layouts/SinglePage";
import { UnprotectedPaths } from "routes";

import ResetPasswordForm from "./Form";

export default () => {
  const navigateToLogin = useNavigateToPath(UnprotectedPaths.Login);
  const [isEmailSent, setIsEmailSent] = useState(false);

  return (
    <SinglePageLayout
      isCentered
      title={
        isEmailSent ? "Your reset password link was sent" : "Reset password"
      }
    >
      <Row justify="center">
        <Col span={24} sm={16} md={12} lg={10} xl={8}>
          {isEmailSent ? (
            <Space type={SpaceTypes.FormContentAndButton}>
              <Typography.Text>
                If the account with the email you entered exists, an email has
                been sent. Use the link in the email to create a new password.
              </Typography.Text>

              <Button
                block
                data-qa="done-button"
                onClick={navigateToLogin}
                shape="round"
                size="middle"
                type="primary"
              >
                Done
              </Button>
            </Space>
          ) : (
            <ResetPasswordForm setIsEmailSent={setIsEmailSent} />
          )}
        </Col>
      </Row>
    </SinglePageLayout>
  );
};
