import AnchorLink from "antd/es/anchor/AnchorLink";

import {
  Space,
  SpaceTypes,
  Typography,
  TypographySizes,
} from "components/antd";
import { isEmpty } from "helpers";
import { useSelector } from "hooks";
import type { ProfileProps } from "store";
import { selectEventCompany } from "store/selectors";

interface HeaderProps {
  user: ProfileProps;
}

export const Header = ({ user }: HeaderProps) => {
  const eventCompany = useSelector(selectEventCompany);

  return (
    !isEmpty(eventCompany) && (
      <Space
        direction="vertical"
        type={SpaceTypes.Centered}
        style={{ rowGap: "0" }}
      >
        <Typography.Text size={TypographySizes.XL}>
          Project - {user?.lastName}
        </Typography.Text>
        <AnchorLink
          href={user?.externalUrl}
          title={user?.externalUrl}
          target="_blank"
        />
      </Space>
    )
  );
};

export default Header;
