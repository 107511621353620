import { useRefElementProperties } from "hooks";
import { styled } from "styles";
import type { StyledProps } from "styles";

const StyledPlaceholder = styled.div(
  ({ styledProps: { height } }: StyledProps) => ({
    height,
  })
);

type PlaceholderProps = {
  className?: string;
  originRef: any;
};

const Placeholder = ({ className, originRef }: PlaceholderProps) => {
  const {
    relativeToViewport: { height },
  } = useRefElementProperties(originRef);

  return <StyledPlaceholder className={className} styledProps={{ height }} />;
};

export default Placeholder;
