import { Button, Form } from "antd";
import { useCallback } from "react";

import { Input, InputAppearances, Space, SpaceTypes } from "components/antd";
import { validatePassword } from "helpers/validators";
import { useFirebase } from "hooks";

enum FieldValues {
  Password = "password",
  PasswordConfirm = "password-confirm",
}

type PropTypes = {
  actionCode: string;
  setIsPasswordChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

const SetNewPasswordForm: React.FC<PropTypes> = ({
  actionCode,
  setIsPasswordChanged,
}) => {
  const firebase = useFirebase();

  const onSubmit = useCallback(
    async (values) => {
      const newPassword = values[FieldValues.Password];

      firebase
        .confirmPasswordReset(actionCode, newPassword)
        .then(() => {
          setIsPasswordChanged(true);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    [actionCode, firebase, setIsPasswordChanged]
  );

  return (
    <Form onFinish={onSubmit} size="large">
      <Space type={SpaceTypes.FormContentAndButton}>
        <Space direction="vertical">
          <Form.Item
            name={FieldValues.Password}
            rules={[
              {
                message: "Enter password to continue",
                required: true,
              },
              { validator: validatePassword },
            ]}
          >
            <Input.Password
              appearance={InputAppearances.Underline}
              autoFocus
              data-qa="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name={FieldValues.PasswordConfirm}
            dependencies={["password"]}
            rules={[
              {
                message: "Confirm password to continue",
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords must match"));
                },
              }),
            ]}
          >
            <Input.Password
              appearance={InputAppearances.Underline}
              data-qa="confirm-password"
              placeholder="Confirm password"
            />
          </Form.Item>
        </Space>

        <Button
          block
          data-qa="submit"
          htmlType="submit"
          shape="round"
          size="middle"
          type="primary"
        >
          Save
        </Button>
      </Space>
    </Form>
  );
};

export default SetNewPasswordForm;
