import { useEffect, useState } from "react";

export type UnmountOnAfterCloseProps = {
  onAfterClose: () => void;
};

export const useUnmountOnAfterClose = (
  Component: any,
  { isOpen, ...restProps }: any
) => {
  const [isMount, setIsMount] = useState(false);
  const [isUnmount, setIsUnmount] = useState(true);

  useEffect(() => {
    isOpen && setIsUnmount(false);
  }, [isOpen]);

  useEffect(() => {
    setIsMount(!isUnmount);
  }, [isUnmount]);

  return (
    !isUnmount && (
      <Component
        isOpen={isMount && isOpen}
        onAfterClose={() => setIsUnmount(true)}
        {...restProps}
      />
    )
  );
};
