import { PushpinOutlined } from "@ant-design/icons";
import { Steps, theme } from "antd";
import type { MenuProps } from "antd";
import { Badge } from "antd";

import { Typography } from "components/antd";
import { useIsPathActive, useNavigateToPath, useOnboarding } from "hooks";
import { ProtectedPaths } from "routes";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { menuSubmenuBackgroundColor } from "styles/variables";
import {
  paddingContentHorizontalLG,
  paddingContentVerticalLG,
} from "styles/variables";

import { StyledMenu } from "./Menu";

const onboardingKey = "onboarding";

const StyledSteps = styled(Steps)(
  ({
    styledProps: { backgroundColor, paddingLeft, paddingTop },
  }: StyledProps) => ({
    "&.ant-steps": {
      backgroundColor,
      paddingLeft,
      paddingTop,
    },
  })
);

const OnboardingMenu = () => {
  const isEventOnboardingPathActive = useIsPathActive(
    ProtectedPaths.EventOnboarding
  );
  const navigateToEventOnboarding = useNavigateToPath([
    ProtectedPaths.EventOnboarding,
  ]);
  const { activeStepIndex, onStepChange } = useOnboarding();
  const { token } = theme.useToken();

  const handleClick: MenuProps["onClick"] = navigateToEventOnboarding;

  const onboardingMenuItems: MenuProps["items"] = [
    {
      icon: <PushpinOutlined />,
      key: onboardingKey,
      label: (
        <Badge dot>
          <Typography.Text style={{ marginRight: token.marginXS }}>
            Finish your profile
          </Typography.Text>
        </Badge>
      ),
    },
  ];

  const selectedKeys = activeStepIndex ?? [onboardingKey];

  return (
    <>
      <StyledMenu
        items={onboardingMenuItems}
        mode="inline"
        onClick={handleClick}
        selectedKeys={isEventOnboardingPathActive ? selectedKeys : []}
        styledProps={{ borderRadius: token.borderRadius }}
      />

      <StyledSteps
        current={activeStepIndex}
        direction="vertical"
        items={[
          { title: "Expertise" },
          { title: "Info" },
          { title: "Profile preview" },
        ]}
        onChange={onStepChange}
        size="small"
        styledProps={{
          backgroundColor:
            activeStepIndex !== null && menuSubmenuBackgroundColor,
          paddingLeft: paddingContentHorizontalLG * 1.2,
          paddingTop: paddingContentVerticalLG * 1.2,
        }}
      />
    </>
  );
};

export default OnboardingMenu;
