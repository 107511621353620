import { useContext, useEffect, useRef, useState } from "react";

import { RouteTransitionContext } from "components/RouteTransition";

import { useEffectOnValueChange } from ".";

export const useFocus = () => {
  const focusRef = useRef(null);

  const setFocus = () => {
    focusRef.current && focusRef.current.focus();
  };

  return [focusRef, setFocus];
};

export const useFocusOnTransitionEnd = () => {
  const { isTransitionEntered } = useContext(RouteTransitionContext);
  const [focusRef, setFocus] = useFocus();
  const [isTransitionEnd, setIsTransitionEnd] = useState(false);

  useEffect(() => {
    isTransitionEntered && setIsTransitionEnd(true);
  }, [isTransitionEntered]);

  useEffectOnValueChange(setFocus, isTransitionEnd);

  return focusRef;
};
