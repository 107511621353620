import classNames from "classnames";

import type { OutlinedIcon } from ".";

export const ExportOutlined = ({
  className,
  fill = "currentColor",
  height = 16,
  style,
  width = 16,
}: OutlinedIcon) => (
  <svg
    className={classNames("anticon", className)}
    fill="none"
    height={height}
    style={style}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.94 2.71317C7.94 2.71317 7.91333 2.6865 7.89333 2.67984C7.87333 2.67317 7.86 2.6665 7.84 2.6665C7.82 2.6665 7.8 2.6665 7.78667 2.67984C7.76667 2.6865 7.75333 2.69984 7.74 2.71317L5.99333 4.9265C5.92667 5.0065 5.99333 5.1265 6.09333 5.1265H7.25333V10.4132C7.25333 10.4798 7.30667 10.5398 7.38 10.5398H8.32C8.38667 10.5398 8.44667 10.4865 8.44667 10.4132V5.13317H9.6C9.70667 5.13317 9.76 5.01317 9.7 4.93317L7.94 2.71317ZM13.56 9.85317H12.62C12.5533 9.85317 12.4933 9.9065 12.4933 9.97984V12.3865H3.18667V9.97984C3.18667 9.91317 3.13333 9.85317 3.06 9.85317H2.12667C2.06 9.85317 2 9.9065 2 9.97984V13.0732C2 13.3465 2.22667 13.5732 2.5 13.5732H13.1867C13.46 13.5732 13.6867 13.3465 13.6867 13.0732V9.97984C13.6867 9.91317 13.6333 9.85317 13.56 9.85317Z"
      fill={fill}
      transform="rotate(90, 8, 8)"
    />
  </svg>
);
