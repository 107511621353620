import { theme } from "antd";

import { Button } from "components/antd";
import { useBreakpoint } from "hooks";
import { colorGraySemi } from "styles/variables";

import { FilterValues } from "./Filters";

type FilterButtonProps = {
  filter?: FilterValues;
  icon?: React.ReactElement;
  isActive: boolean;
  onClick: (filter: FilterValues) => void;
  title: React.ReactNode;
};

const FilterButton = ({
  filter,
  icon,
  isActive,
  onClick,
  title,
}: FilterButtonProps) => {
  const { lg, sm, xl, xs } = useBreakpoint();
  const { token } = theme.useToken();

  const isTitleVisible = !icon || xs || (sm && !lg) || xl;

  return (
    <Button
      color={isActive ? token.colorTextBase : colorGraySemi}
      icon={icon}
      onClick={() => onClick(filter)}
      shape="round"
      size="large"
      type={isActive ? "primary" : "secondary"}
    >
      {isTitleVisible && title}
    </Button>
  );
};

export default FilterButton;
