import { CopyOutlined, DownOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  ConfigProvider,
  Divider,
  Dropdown,
  Skeleton,
  Space,
  theme,
} from "antd";
import type { MenuProps } from "antd";
import hexToRgba from "hex-to-rgba";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Talk from "talkjs";

import { Typography } from "components/antd";
import { formatUserFullName } from "helpers";
import { isLoaded } from "helpers";
import { useBreakpoint, useDispatch, useSelector } from "hooks";
import { ProtectedPaths } from "routes";
import type { MeetingProps } from "store";
import { fetchAllEventUsers } from "store/appSlice";
import {
  selectAccountId,
  selectEventProfile,
  selectMeetingRequestsReceivedCount,
  selectMeetings,
  selectMeetingsTodayCount,
} from "store/selectors";
import { css } from "styles";
import {
  colorError,
  colorGray900,
  colorGraySemi,
  colorGreenDark,
  colorInfo,
  colorWarning,
  opacityMenuItemActive,
} from "styles/variables";

import Timeline from "./Timeline";

export enum FirestoreStatuses {
  Accepted = "accepted",
  Pending = "pending",
  Declined = "declined",
}

export enum Filters {
  All = "all",
  Accepted = "accepted",
  RequestsReceived = "received",
  RequestsSent = "sent",
  Declined = "declined",
  Archived = "archived",
}

export const filterColors = {
  [Filters.All]: colorGray900,
  [Filters.Accepted]: colorGreenDark,
  [Filters.RequestsReceived]: colorWarning,
  [Filters.RequestsSent]: colorInfo,
  [Filters.Declined]: colorError,
  [Filters.Archived]: colorGraySemi,
};

type ParamsProps = {
  eventId: string;
  filter: Filters;
};

const Meetings = () => {
  const { isDesktop } = useBreakpoint();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { filter = Filters.All, eventId } = useParams<ParamsProps>();
  const meetings = useSelector(selectMeetings);
  const meetingRequestsReceivedCount = useSelector(
    selectMeetingRequestsReceivedCount
  );
  const [talkSession, setTalkSession] = useState(null);
  const [talkPopup, setTalkPopup] = useState(null);
  const accountId = useSelector(selectAccountId);
  const eventProfile = useSelector(selectEventProfile);
  const meetingsTodayCount = useSelector(selectMeetingsTodayCount);
  const { token } = theme.useToken();

  const handleFilterClick = useCallback(
    (key: Filters) => {
      navigate(`/${eventId}/${ProtectedPaths.EventMeetings}/${key}`);
    },
    [eventId, navigate]
  );

  const handleDropdownMenuClick: MenuProps["onClick"] = useCallback(
    ({ key }) => {
      handleFilterClick(key as Filters);
    },
    [handleFilterClick]
  );

  useEffect(() => {
    dispatch(fetchAllEventUsers(accountId, eventId));
  }, [accountId, dispatch, eventId]);

  useEffect(() => {
    Talk.ready.then(() => {
      const currentUser = new Talk.User({
        id: accountId,
        name: formatUserFullName(eventProfile),
        email: eventProfile.email,
        photoUrl: eventProfile.avatarUrl,
        role: "default",
      });

      if (!talkSession) {
        const session = new Talk.Session({
          appId: process.env.REACT_APP_TalkJsAppId,
          me: currentUser,
        });

        setTalkSession(session);
      }
    });
  }, [accountId, eventProfile, talkSession]);

  const filteredMeetings = useMemo(() => {
    const sortedMeetings = _.sortBy(
      meetings,
      ({ proposed_time }) => proposed_time
    );

    const archivedMeetings = sortedMeetings.filter(
      ({ archived }: MeetingProps) => archived[accountId]
    );
    const unarchivedMeetings = sortedMeetings.filter(
      ({ archived }: MeetingProps) => !archived[accountId]
    );

    switch (filter) {
      case Filters.Accepted:
        return unarchivedMeetings.filter(
          ({ status }: MeetingProps) => status === FirestoreStatuses.Accepted
        );
      case Filters.RequestsReceived:
        return unarchivedMeetings.filter(
          ({ status, invitee_id }: MeetingProps) =>
            status === FirestoreStatuses.Pending && invitee_id === accountId
        );
      case Filters.RequestsSent:
        return unarchivedMeetings.filter(
          ({ status, inviter_id }: MeetingProps) =>
            status === FirestoreStatuses.Pending && inviter_id === accountId
        );
      case Filters.Declined:
        return unarchivedMeetings.filter(
          ({ status }: MeetingProps) => status === FirestoreStatuses.Declined
        );
      case Filters.Archived:
        return archivedMeetings;
      default:
        return unarchivedMeetings;
    }
  }, [accountId, filter, meetings]);

  const emphasize = (count: number) =>
    count > 0 ? <strong>{count}</strong> : "no";

  const pluralize = (text: string, count: number) =>
    count !== 1 ? `${text}s` : text;

  const meetingRequestsReceivedText = `You have ${meetingRequestsReceivedCount} ${pluralize(
    "new meeting request",
    meetingRequestsReceivedCount
  )} waiting for your approval`;

  const meetingRequestsReceivedElement = (
    <>
      and {emphasize(meetingRequestsReceivedCount)}{" "}
      {pluralize("new meeting request", meetingRequestsReceivedCount)} waiting
      for your approval
    </>
  );

  const meetingsTodayElement = (
    <>
      You have {emphasize(meetingsTodayCount)}{" "}
      {pluralize("meeting", meetingsTodayCount)} today
    </>
  );

  const filters = [
    {
      key: Filters.All,
      label: "All",
      disabled: filter === Filters.All,
    },
    {
      key: Filters.Accepted,
      label: "Accepted meetings",
      disabled: filter === Filters.Accepted,
    },
    {
      key: Filters.RequestsReceived,
      label: "Meeting requests",
      disabled: filter === Filters.RequestsReceived,
    },
    {
      key: Filters.RequestsSent,
      label: "Invitation sent",
      disabled: filter === Filters.RequestsSent,
    },
    {
      key: Filters.Declined,
      label: "Declined",
      disabled: filter === Filters.Declined,
    },
    {
      key: Filters.Archived,
      label: "Meeting history",
      icon: <CopyOutlined />,
      disabled: filter === Filters.Archived,
    },
  ]; // Dropdown menu prop uses items with type MenuProps["items"]

  const filterWithProperties = _.find(filters, ({ key }) => key === filter);

  const formattedMeetingRequestsReceivedCount =
    meetingRequestsReceivedCount > 0 && `(${meetingRequestsReceivedCount})`;

  const noMeetingsText =
    filter === Filters.All
      ? "You have no scheduled meetings right now."
      : "You have no meetings matching this filter criteria.";

  return isLoaded(meetings) && isLoaded(eventProfile) ? (
    <Space direction="vertical" size="large">
      <ConfigProvider
        theme={{
          components: {
            Alert: {
              colorInfo: token.colorPrimary,
            },
          },
        }}
      >
        {meetingRequestsReceivedCount > 0 && (
          <Alert
            closable
            message={meetingRequestsReceivedText}
            showIcon
            type="info"
          />
        )}
      </ConfigProvider>
      <div>
        <Space direction="vertical" size="large">
          {
            <Typography.Text>
              {meetingsTodayElement}{" "}
              {meetingRequestsReceivedCount > 0 &&
                meetingRequestsReceivedElement}
            </Typography.Text>
          }
          {isDesktop ? (
            <Space>
              {filters &&
                filters.map(({ icon, label, key }, index) => {
                  return (
                    <ConfigProvider
                      key={index}
                      theme={{
                        token: {
                          colorBorder: filterColors[key],
                          colorPrimary: filterColors[key],
                          colorText: filterColors[key],
                          fontSize: 12,
                        },
                      }}
                    >
                      <Button
                        icon={icon}
                        onClick={() => handleFilterClick(key)}
                        shape="round"
                        style={{ fontWeight: "bold" }}
                        type={filter === key ? "primary" : "default"}
                      >
                        {label}{" "}
                        {key === Filters.RequestsReceived &&
                          formattedMeetingRequestsReceivedCount}
                      </Button>
                    </ConfigProvider>
                  );
                })}
            </Space>
          ) : (
            <Space>
              <Typography.Text strong>Selected filter:</Typography.Text>
              <Dropdown
                menu={{
                  items: filters,
                  onClick: handleDropdownMenuClick,
                }}
                trigger={["click"]}
                overlayClassName={css({
                  "&.ant-dropdown": {
                    ".ant-dropdown-menu": {
                      ".ant-dropdown-menu-item": {
                        "&.ant-dropdown-menu-item-disabled": {
                          backgroundColor: hexToRgba(
                            token.colorTextBase,
                            opacityMenuItemActive
                          ),
                          color: token.colorText,
                          cursor: "default",
                        },
                      },
                    },
                  },
                })}
              >
                <Button shape="round">
                  <Space>
                    {filterWithProperties.label}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Space>
          )}
        </Space>
        <Divider />
        {filteredMeetings.length > 0 ? (
          <Timeline
            talkPopup={talkPopup}
            setTalkPopup={setTalkPopup}
            talkSession={talkSession}
            meetings={filteredMeetings}
          />
        ) : (
          noMeetingsText
        )}
      </div>
    </Space>
  ) : (
    <Skeleton />
  );
};

export default Meetings;
