import TweenOne from "rc-tween-one";

import { styled } from "styles";

const animation = [
  { left: "-10px", opacity: "0.7", duration: 90 },
  { left: "2px", opacity: "0.7", duration: 90 },
  { left: "-4px)", opacity: "0.7", duration: 90 },
  { left: "4px", opacity: "0.7", duration: 90 },
  { left: "0", opacity: "1", duration: 90 },
];

const StyledTweenOne = styled(TweenOne)({
  position: "relative",
});

type ShakeProps = {
  active: boolean;
  children: React.ReactNode;
};

const Shake = ({ active, children }: ShakeProps) => (
  <StyledTweenOne animation={animation} paused={!active} repeat={0}>
    {children}
  </StyledTweenOne>
);

export default Shake;
