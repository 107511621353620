import { animationDurationLong } from "styles/variables";

import { formatNotEmptyString } from ".";

const delayInMilliseconds = animationDurationLong * 1000;

export const validateEmail = (rule: any, value: string, callback: any) => {
  const isNotEmptyValue = formatNotEmptyString(value) !== undefined;
  const regEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (isNotEmptyValue && !value.toLowerCase().match(regEx)) {
    setTimeout(() => {
      callback("Enter a correct email address");
    }, delayInMilliseconds);
  } else if (isNotEmptyValue && value.length > 254) {
    setTimeout(() => {
      callback("Email length must not exceed 254 characters");
    }, delayInMilliseconds);
  } else {
    callback();
  }
};

export const validateLinkedInUrl = (
  rule: any,
  value: string,
  callback: any
) => {
  let url: any;

  try {
    url = new URL(value);

    if (!url.includes("linkedin.com")) {
      setTimeout(() => {
        callback("Please add a valid LinkedIn link");
      }, delayInMilliseconds);
    } else {
      callback();
    }
  } catch (_) {
    callback();
  }
};

export const validateName = (rule: any, value: string, callback: any) => {
  const isNotEmptyValue = formatNotEmptyString(value) !== undefined;
  const regEx =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

  if (isNotEmptyValue && !regEx.test(value)) {
    setTimeout(() => {
      callback("Name can only consist of letters");
    }, delayInMilliseconds);
  } else if (isNotEmptyValue && value.length > 150) {
    setTimeout(() => {
      callback("Name cannot exceed 150 characters");
    }, delayInMilliseconds);
  } else {
    callback();
  }
};

export const validatePassword = (rule: any, value: string, callback: any) => {
  const isNotEmptyValue = formatNotEmptyString(value) !== undefined;
  const regEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30}$/;

  if (isNotEmptyValue && !regEx.test(value)) {
    setTimeout(() => {
      callback(
        "Password should be between 8 and 30 characters, contain at least one number, one capital letter and one lower case letter"
      );
    }, delayInMilliseconds);
  } else {
    callback();
  }
};
