import { useEffect } from "react";

import Window from "components/Window";
import { isLoaded } from "helpers";
import { useDispatch, useSelector, useUnmountOnAfterClose } from "hooks";
import type { UnmountOnAfterCloseProps } from "hooks";
import { fetchCompany, unsetEventCompany } from "store/appSlice";
import { selectEventCompany } from "store/selectors";

import Content from "./Content";
import Header from "./Header";

type CompanyWindowProps = {
  companyId: string;
  isMaskVisible?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const CompanyWindow = ({
  companyId,
  isMaskVisible,
  isOpen,
  onAfterClose,
  onClose,
}: CompanyWindowProps & UnmountOnAfterCloseProps) => {
  const dispatch = useDispatch();
  const eventCompany = useSelector(selectEventCompany);

  useEffect(() => {
    dispatch(fetchCompany(companyId));

    return () => {
      dispatch(unsetEventCompany());
    };
  }, [dispatch, companyId]);

  const isLoading = !isLoaded(eventCompany);

  return (
    <Window
      childrenKey={eventCompany?.id}
      headerElement={<Header isLoading={isLoading} />}
      isFullHeight
      isMaskVisible={isMaskVisible}
      isOpen={isOpen}
      onAfterClose={onAfterClose}
      onClose={onClose}
    >
      <Content isLoading={isLoading} />
    </Window>
  );
};

export default (props: CompanyWindowProps) =>
  useUnmountOnAfterClose(CompanyWindow, props);
