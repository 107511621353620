import { Col, ConfigProvider, Row, theme } from "antd";
import { createContext, useContext, useState } from "react";

import EventSwitcherMobile from "components/EventSwitcherMobile";
import { RouteTransitionContext } from "components/RouteTransition";
import { Layout } from "components/antd";
import { useBreakpoint } from "hooks";
import LoadingPage from "pages/Loading";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { Global, useGlobalLayoutStyles } from "styles/global";
import { mobileHeaderHeight, tabBarHeight } from "styles/variables";

import Sidebar from "./Sidebar";
import TabBar from "./TabBar";

export const LayoutContext = createContext({
  isGeneralViewVisible: false,
  isTabBarVisible: false,
  setIsGeneralViewVisible: undefined,
});

const StyledLayoutContent = styled(Layout.Content)(
  ({ styledProps: { marginBottom, padding } }: StyledProps) => ({
    marginBottom,
    padding,
  })
);

type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayout = ({ children }: MainLayoutProps) => {
  const { isDesktop, isLoaded } = useBreakpoint();
  const { isTransitionEntered } = useContext(RouteTransitionContext);
  const globalLayoutStyles = useGlobalLayoutStyles({
    offsetBottom: !isDesktop && isTransitionEntered && tabBarHeight,
    offsetTop: !isDesktop && isTransitionEntered && mobileHeaderHeight,
  });
  const [isGeneralViewVisible, setIsGeneralViewVisible] = useState(false);
  const { token } = theme.useToken();

  const isTabBarVisible = !isDesktop;

  return (
    <>
      <Global styles={globalLayoutStyles} />

      <LayoutContext.Provider
        value={{
          isGeneralViewVisible,
          isTabBarVisible,
          setIsGeneralViewVisible,
        }}
      >
        {isLoaded ? (
          <Layout isFullHeight>
            {isDesktop ? (
              <Sidebar />
            ) : (
              <>
                <ConfigProvider
                  theme={{
                    token: {
                      colorBgLayout: token.colorTextBase,
                      colorLink: token.colorBgContainer,
                      colorTextBase: token.colorBgContainer,
                    },
                  }}
                >
                  <EventSwitcherMobile />
                </ConfigProvider>
                <TabBar />
              </>
            )}

            <Layout>
              <Row justify="center" style={{ width: "100%" }}>
                <Col span={24} lg={20}>
                  <StyledLayoutContent
                    styledProps={{
                      marginBottom: isTabBarVisible && tabBarHeight,
                      padding: token.paddingContentHorizontalLG,
                    }}
                  >
                    {children}
                  </StyledLayoutContent>
                </Col>
              </Row>
            </Layout>
          </Layout>
        ) : (
          <LoadingPage />
        )}
      </LayoutContext.Provider>
    </>
  );
};

export default MainLayout;
