import { ConfigProvider, Modal, theme } from "antd";

import { styled } from "styles";
import type { StyledProps } from "styles";
import {
  colorGrayButtonBorder,
  colorGrayLight,
  fontWeightMedium,
} from "styles/variables";

const StyledModal = styled(Modal)(
  ({ styledProps: { padding } }: StyledProps) => ({
    ".ant-modal-footer": {
      display: "flex",
    },
    ".ant-modal-title": {
      fontWeight: fontWeightMedium,
      paddingBottom: padding,
      paddingTop: padding,
      textAlign: "center",
    },
  })
);

type ConfirmationModalProps = {
  children?: React.ReactNode;
  danger?: boolean;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  onOk: () => void;
};

const ConfirmationModal = ({
  children,
  danger,
  isOpen,
  onClose,
  title,
  onOk,
}: ConfirmationModalProps) => {
  const { token } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: colorGrayLight,
          colorBorder: colorGrayButtonBorder,
          colorText: token.colorBgElevated,
          colorTextDescription: token.colorBgElevated,
          ...(danger && {
            colorPrimary: token.colorError,
          }),
        },
      }}
    >
      <StyledModal
        cancelButtonProps={{ block: true, shape: "round", type: "default" }}
        centered
        children={children}
        onCancel={onClose}
        onOk={onOk}
        okButtonProps={{ block: true, shape: "round" }}
        okText="Confirm"
        open={isOpen}
        styledProps={{ padding: token.paddingSM }}
        title={title}
        width={330}
      />
    </ConfigProvider>
  );
};

export default ConfirmationModal;
