import { createContext } from "react";
import { useOutlet, useParams } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import { appWrapperElementId } from "index";
import { Global } from "styles/global";
import { animationDurationMedium } from "styles/variables";

const animationDurationInMilliseconds = animationDurationMedium * 1000;
const className = "page";

export enum RouteTransitionStates {
  Entering = "entering",
  Entered = "entered",
  Exiting = "exiting",
  Exited = "exited",
}

export const RouteTransitionContext = createContext({
  isTransitionEntering: false,
  isTransitionEntered: false,
  isTransitionExiting: false,
  isTransitionExited: false,
});

type RouteTransitionProps = {
  children: React.ReactNode;
};

const RouteTransition = ({}: RouteTransitionProps) => {
  const currentOutlet = useOutlet();
  const { eventId: key = "darkTheme" } = useParams();

  return (
    <>
      <SwitchTransition>
        <CSSTransition
          key={key}
          timeout={animationDurationInMilliseconds}
          classNames={className}
        >
          {(state) => {
            const isTransitionEntering =
              state === RouteTransitionStates.Entering;
            const isTransitionEntered = state === RouteTransitionStates.Entered;
            const isTransitionExiting = state === RouteTransitionStates.Exiting;
            const isTransitionExited = state === RouteTransitionStates.Exited;

            return (
              <RouteTransitionContext.Provider
                value={{
                  isTransitionEntering,
                  isTransitionEntered,
                  isTransitionExiting,
                  isTransitionExited,
                }}
              >
                <Global
                  styles={{
                    ...((isTransitionEntering || isTransitionExiting) && {
                      [`#${appWrapperElementId}`]: {
                        overflow: "hidden !important",
                      },
                    }),
                    [`.${className}-enter, .${className}-exit`]: {
                      height: "100%",
                      overflow: "hidden",
                      transition: "opacity 300ms, transform 300ms",
                      width: "100%",
                    },
                    [`.${className}-enter`]: {
                      opacity: 0,
                      transform: "scale(1.1)",
                    },
                    [`.${className}-enter-active`]: {
                      opacity: 1,
                      transform: "scale(1)",
                    },
                    [`.${className}-exit`]: {
                      opacity: 1,
                      transform: "scale(1)",
                    },
                    [`.${className}-exit-active`]: {
                      opacity: 0,
                      transform: "scale(0.9)",
                    },
                  }}
                />

                <div className={className}>{currentOutlet}</div>
              </RouteTransitionContext.Provider>
            );
          }}
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};

export default RouteTransition;
