import { MailOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Space } from "antd";
import { useParams } from "react-router-dom";

import { exportContacts } from "api/resources/users";
import {
  Input,
  InputAppearances,
  Typography,
  TypographySizes,
} from "components/antd";
import { useSelector } from "hooks";
import { selectEventProfile } from "store/selectors";
import { styled } from "styles";

enum FieldValues {
  Email = "email",
  Favourites = "favourites",
  Meetings = "accepted_meetings",
}

const StyledCheckbox = styled(Checkbox)({
  justifyContent: "space-between",
  width: "100%",
  ".ant-checkbox": {
    order: 1,
  },
});

type ExportFormProps = {
  onSubmit: () => void;
};

const ExportForm = ({ onSubmit }: ExportFormProps) => {
  const [form] = Form.useForm();
  const { eventId } = useParams();
  const eventProfile = useSelector(selectEventProfile);
  const email = Form.useWatch(FieldValues.Email, form);
  const favouritesCheckboxValue = Form.useWatch(FieldValues.Favourites, form);
  const meetingsCheckboxValue = Form.useWatch(FieldValues.Meetings, form);

  const handleFormSubmit = async () => {
    const data = {
      email,
      receiveFavoriteUsers: favouritesCheckboxValue,
      receiveMeetingAcceptedUsers: meetingsCheckboxValue,
    };

    await exportContacts(eventProfile.id, eventId, data);
    onSubmit();
  };

  const isExportOptionsSelected =
    favouritesCheckboxValue || meetingsCheckboxValue;
  const isEmailAdded = !!email;

  return (
    <Form form={form} onFinish={handleFormSubmit}>
      <Space direction="vertical" size="large">
        <div>
          <Form.Item
            initialValue={true}
            name={FieldValues.Favourites}
            valuePropName="checked"
          >
            <StyledCheckbox>
              <Typography.Text size={TypographySizes.LG}>
                Favourited profiles
              </Typography.Text>
            </StyledCheckbox>
          </Form.Item>
          <Form.Item
            initialValue={true}
            name={FieldValues.Meetings}
            valuePropName="checked"
          >
            <StyledCheckbox>
              <Typography.Text size={TypographySizes.LG}>
                Meeting accepted profiles
              </Typography.Text>
            </StyledCheckbox>
          </Form.Item>
          <Form.List name="checkboxErrors">
            {() => (
              <Form.Item>
                <Form.ErrorList
                  errors={
                    !isExportOptionsSelected ? ["Choose contact type"] : []
                  }
                />
              </Form.Item>
            )}
          </Form.List>
          <Form.Item
            initialValue={eventProfile?.email}
            name={FieldValues.Email}
            rules={[
              {
                message: "Insert email to receive report",
                required: true,
              },
              { type: "email" },
            ]}
          >
            <Input
              appearance={InputAppearances.Underline}
              placeholder="Insert your email to receive report"
              prefix={<MailOutlined />}
            />
          </Form.Item>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            disabled={!isExportOptionsSelected || !isEmailAdded}
            htmlType="submit"
            shape="round"
            type="primary"
          >
            Send report
          </Button>
        </div>
      </Space>
    </Form>
  );
};

export default ExportForm;
