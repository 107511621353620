import { Button, theme } from "antd";

import { Space, SpaceTypes, Typography } from "components/antd";
import { useNavigateToPath } from "hooks";
import { ProtectedPaths } from "routes";

type ConfirmationProps = {
  inviteeFirstName: string;
};

const Confirmation = ({ inviteeFirstName }: ConfirmationProps) => {
  const navigateToEventMeetings = useNavigateToPath(
    ProtectedPaths.EventMeetings
  );
  const { token } = theme.useToken();

  return (
    <Space direction="vertical" size={token.sizeXXL} type={SpaceTypes.Centered}>
      <Typography.Title level={2}>
        Your invitation is succesfully sent!
      </Typography.Title>
      <Typography.Text>
        We’ll let you know when {inviteeFirstName} will confirm your
        appointment!
      </Typography.Text>
      <Button
        onClick={navigateToEventMeetings}
        shape="round"
        size="large"
        type="primary"
      >
        Go to your meetings
      </Button>
    </Space>
  );
};

export default Confirmation;
