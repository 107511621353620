import { useEffect, useState } from "react";

// Chromium browsers do not apply object-fit on first paint, therefore image preload is needed: https://stackoverflow.com/a/64209838
export const useImagePreloader = (src: string) => {
  const [isImageError, setIsImageError] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(
    function preloadImage() {
      if (src) {
        const img = new Image();
        img.src = src;
        img.onload = function () {
          setIsImageLoaded(true);
        };
        img.onerror = function () {
          setIsImageError(true);
        };
      }
    },
    [src]
  );

  return { isImageError, isImageLoaded };
};
