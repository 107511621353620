import classNames from "classnames";

import { colorIconFill } from "styles/variables";

import type { FilledIcon } from ".";

export const ThreeDotsFilled = ({
  className,
  fill = colorIconFill,
  height = 24,
  style,
  width = 24,
}: FilledIcon) => (
  <svg
    className={classNames("anticon", className)}
    fill="none"
    height={height}
    style={style}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.97412 5C9.97412 3.89543 10.8672 3 11.9689 3C13.0706 3 13.9637 3.89543 13.9637 5C13.9637 6.10457 13.0706 7 11.9689 7C10.8672 7 9.97412 6.10457 9.97412 5ZM11.9689 10C10.8672 10 9.97412 10.8954 9.97412 12C9.97412 13.1046 10.8672 14 11.9689 14C13.0706 14 13.9637 13.1046 13.9637 12C13.9637 10.8954 13.0706 10 11.9689 10ZM11.9689 17C10.8672 17 9.97412 17.8954 9.97412 19C9.97412 20.1046 10.8672 21 11.9689 21C13.0706 21 13.9637 20.1046 13.9637 19C13.9637 17.8954 13.0706 17 11.9689 17Z"
      fill={fill}
    />
  </svg>
);
