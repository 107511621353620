import { Badge, theme } from "antd";

import { Typography, TypographySizes } from "components/antd";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { colorWarning } from "styles/variables";

const StyledWrapper = styled.div(
  ({ styledProps: { paddingBottom, paddingTop } }: StyledProps) => ({
    display: "flex",
    flexDirection: "column",
    paddingBottom,
    paddingTop,
  })
);

type MenuItemProps = {
  isOnboardingUnfinished?: boolean;
  notificationsCount?: number;
  title: string;
};

const MenuItem = ({
  isOnboardingUnfinished,
  notificationsCount,
  title,
}: MenuItemProps) => {
  const { token } = theme.useToken();

  return (
    <StyledWrapper
      styledProps={{
        paddingBottom: token.paddingXXS,
        paddingTop: notificationsCount > 0 ? token.paddingXS : token.paddingXXS,
      }}
    >
      <Badge count={notificationsCount} style={{ top: token.paddingXXS }}>
        <Typography.Text
          size={TypographySizes.LG}
          strong
          style={{ paddingRight: token.paddingSM }}
        >
          {title}
        </Typography.Text>
      </Badge>
      {isOnboardingUnfinished && (
        <Typography.Text
          size={TypographySizes.XS}
          style={{ color: colorWarning }}
        >
          Finish onboarding
        </Typography.Text>
      )}
    </StyledWrapper>
  );
};

export default MenuItem;
