import { Tag } from "components/antd";
import type { EventInterestTagProps } from "store";

type GroupTagsProps = {
  onAddTag: (tag: string) => void;
  onRemoveTag: (tagId: string) => void;
  selectedTags: string[];
  tags: EventInterestTagProps[];
};

const GroupTags = ({
  onAddTag,
  onRemoveTag,
  selectedTags,
  tags,
}: GroupTagsProps) => {
  return (
    <>
      {tags.map(({ id, name }: EventInterestTagProps) => {
        const isSelected = selectedTags?.indexOf(id) !== -1;

        return (
          <Tag
            key={id}
            color={isSelected ? "cyan" : ""}
            style={{ cursor: "pointer" }}
            onClick={() => (isSelected ? onRemoveTag(id) : onAddTag(id))}
          >
            {name}
          </Tag>
        );
      })}
    </>
  );
};

export default GroupTags;
