export type IconProps = {
  className?: string;
  fontSize?: number;
  height?: number;
  size?: IconSizes;
  style?: React.CSSProperties;
  width?: number;
};

export enum IconSizes {
  SM,
  MD,
  LG,
}

export type FilledIcon = IconProps & {
  fill?: string;
};

export type OutlinedIcon = IconProps & {
  fill?: string;
};

export type TwoToneIcon = IconProps;

export * from "./BackgroundSphere";
export * from "./Export";
export * from "./External";
export * from "./HandWave";
export * from "./Mail";
export * from "./MutualInterests";
export * from "./ThreeDots";
export * from "./Ticket";
export * from "./VideoCall";
