import { LinkedinFilled, MailFilled, PhoneFilled } from "@ant-design/icons";
import { theme } from "antd";

import iconCircleSrc from "assets/images/icon-circle.png";
import {
  Button,
  Link,
  Space,
  SpaceTypes,
  StyledRadiantAvatar,
  Typography,
  TypographySizes,
} from "components/antd";
import {
  formatUserCompanyNameWithJobTitle,
  formatUserFullName,
  formatUserInitials,
} from "helpers";
import type { ProfileProps } from "store";
import { styled } from "styles";
import { colorGraySemi, colorIconFill } from "styles/variables";

import Confirmation from "./Confirmation";
import Error from "./Error";

const StyledIcon = styled.img({
  height: 22,
  width: 22,
  background: colorIconFill,
  borderRadius: "50%",
});

type ContentProps = {
  onCompanyLinkClick: () => void;
  isError: boolean;
  isInstructionsSent: boolean;
  user: ProfileProps;
};

const Content = ({
  onCompanyLinkClick,
  isError,
  isInstructionsSent,
  user,
}: ContentProps) => {
  const { token } = theme.useToken();

  if (isError) {
    return <Error />;
  } else if (isInstructionsSent) {
    return <Confirmation email={user.email} />;
  } else {
    return (
      <Space direction="vertical" size="large" type={SpaceTypes.Centered}>
        <StyledRadiantAvatar
          initials={formatUserInitials(user)}
          size={120}
          src={user.avatarUrl}
          styledProps={{
            borderColor: token.colorPrimary,
            borderWidth: 10,
          }}
        />

        <Space direction="vertical">
          <Space size="large" type={SpaceTypes.Centered}>
            {user.linkedIn && (
              <Link to={user.linkedIn}>
                <LinkedinFilled
                  style={{ fontSize: 22, color: colorIconFill }}
                />
              </Link>
            )}
            {user.companyId && (
              <Link onClick={onCompanyLinkClick}>
                <StyledIcon src={iconCircleSrc} />
              </Link>
            )}
          </Space>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {formatUserFullName(user)}
          </Typography.Title>
          <Typography.Text
            color={colorGraySemi}
            size={TypographySizes.SM}
            strong
          >
            {formatUserCompanyNameWithJobTitle(user)}
          </Typography.Text>
        </Space>

        <Typography.Text color={colorGraySemi} size={TypographySizes.SM}>
          {user.description}
        </Typography.Text>

        <Space direction="vertical" size={0}>
          {user.email && (
            <a href={`mailto:${user.email}`}>
              <Button
                icon={
                  <MailFilled
                    style={{
                      color: colorGraySemi,
                      marginRight: token.marginXS,
                    }}
                  />
                }
                type="link"
              >
                <Typography.Text color={colorGraySemi}>
                  {user.email}
                </Typography.Text>
              </Button>
            </a>
          )}
          {user.phoneNumber && (
            <a href={`tel:${user.phoneNumber}`}>
              <Button
                icon={
                  <PhoneFilled
                    style={{
                      color: colorGraySemi,
                      marginRight: token.marginXS,
                    }}
                  />
                }
                type="link"
              >
                <Typography.Text color={colorGraySemi}>
                  {user.phoneNumber}
                </Typography.Text>
              </Button>
            </a>
          )}
        </Space>
      </Space>
    );
  }
};

export default Content;
