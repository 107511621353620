import classNames from "classnames";

import { colorIconFill } from "styles/variables";

import type { OutlinedIcon } from ".";
import { IconSizes } from ".";

export const ExternalOutlined = ({
  className,
  fill = colorIconFill,
  size = IconSizes.MD,
  style,
}: OutlinedIcon) => {
  switch (size) {
    case IconSizes.SM:
      return (
        <svg
          className={classNames("anticon", className)}
          fill="none"
          height="8"
          style={style}
          viewBox="0 0 8 8"
          width="8"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.880968 8H7.11903C7.60484 8 8 7.60484 8 7.11903V4.44558C8 4.20542 7.80466 4.01008 7.56451 4.01008C7.32435 4.01008 7.12901 4.20542 7.12901 4.44558V6.67356C7.12901 6.92491 6.92451 7.12942 6.67315 7.12942H1.32644C1.07509 7.12942 0.870579 6.92491 0.870579 6.67356V1.32644C0.870579 1.07509 1.07509 0.870579 1.32644 0.870579H3.55442C3.79458 0.870579 3.98992 0.675239 3.98992 0.435086C3.98992 0.194933 3.79458 0 3.55442 0H0.880968C0.395162 0 0 0.395162 0 0.880968V7.11903C0 7.60484 0.395162 8 0.880968 8Z"
            fill={fill}
          />
          <path
            d="M5.33673 0.870783H6.51529L2.42984 4.95623C2.34775 5.03832 2.30253 5.14729 2.30253 5.2632C2.30253 5.3791 2.34775 5.48807 2.42984 5.57016C2.51193 5.65225 2.6209 5.69747 2.7368 5.69747C2.85271 5.69747 2.96168 5.65225 3.04377 5.57016L7.12922 1.48471V2.66327C7.12922 2.90342 7.32456 3.09877 7.56471 3.09877C7.80486 3.09877 8.0002 2.90342 8.0002 2.66327V0.435493C8.0002 0.195341 7.80486 0 7.56471 0H5.33673C5.09658 0 4.90124 0.195341 4.90124 0.435493C4.90124 0.675646 5.09658 0.870783 5.33673 0.870783Z"
            fill={fill}
          />
        </svg>
      );

    case IconSizes.MD:
      return (
        <svg
          className={classNames("anticon", className)}
          fill="none"
          height="11"
          style={style}
          viewBox="0 0 11 11"
          width="11"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.21133 11H9.78867C10.4567 11 11 10.4567 11 9.78867V6.11267C11 5.78246 10.7314 5.51386 10.4012 5.51386C10.071 5.51386 9.80239 5.78246 9.80239 6.11267V9.17614C9.80239 9.52176 9.5212 9.80295 9.17558 9.80295H1.82386C1.47824 9.80295 1.19705 9.52176 1.19705 9.17614V1.82386C1.19705 1.47824 1.47824 1.19705 1.82386 1.19705H4.88733C5.21754 1.19705 5.48614 0.928453 5.48614 0.598243C5.48614 0.268033 5.21754 0 4.88733 0H1.21133C0.543348 0 0 0.543348 0 1.21133V9.78867C0 10.4567 0.543348 11 1.21133 11Z"
            fill={fill}
          />
          <path
            d="M7.33803 1.19733H8.95856L3.34106 6.81482C3.22819 6.92769 3.16602 7.07753 3.16602 7.23689C3.16602 7.39626 3.22819 7.5461 3.34106 7.65897C3.45393 7.77184 3.60378 7.83402 3.76314 7.83402C3.9225 7.83402 4.07234 7.77184 4.18521 7.65897L9.80271 2.04148V3.662C9.80271 3.99221 10.0713 4.2608 10.4015 4.2608C10.7317 4.2608 11.0003 3.99221 11.0003 3.662V0.598803C11.0003 0.268593 10.7317 0 10.4015 0H7.33803C7.00782 0 6.73923 0.268593 6.73923 0.598803C6.73923 0.929013 7.00782 1.19761 7.33803 1.19761V1.19733Z"
            fill={fill}
          />
        </svg>
      );
  }
};
