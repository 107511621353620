import { ConfigProvider, Layout, Space, theme } from "antd";
import hexToRgba from "hex-to-rgba";

import logoImageSrc from "assets/images/logo-dark.png";
import EventSwitcher from "components/EventSwitcher";
import { styled } from "styles";
import type { StyledProps } from "styles";
import {
  opacitySkeletonLight,
  paddingLayoutVertical,
  sidebarWidth,
} from "styles/variables";

import Menu from "./Menu";

const StyledEventSwitcher = styled(EventSwitcher)(
  ({ styledProps: { padding } }: StyledProps) => ({
    paddingLeft: padding,
    paddingRight: padding,
  })
);

const StyledLayoutSider = styled(Layout.Sider)(
  ({ styledProps: { backgroundColor } }: StyledProps) => ({
    "&.ant-layout-sider": {
      backgroundColor: `${backgroundColor}`,
      maxHeight: "100vh",
      paddingBottom: paddingLayoutVertical,
      paddingTop: paddingLayoutVertical,
      position: "sticky",
      top: "0",
    },
  })
);

const StyledLogo = styled.img(({ styledProps: { padding } }: StyledProps) => ({
  paddingLeft: padding,
  paddingRight: padding,
  width: 158,
}));

const Sidebar = () => {
  const { token } = theme.useToken();

  return (
    <StyledLayoutSider
      styledProps={{ backgroundColor: token.colorWhite }}
      width={sidebarWidth}
    >
      <ConfigProvider
        theme={{
          token: {
            colorFillSecondary: hexToRgba(
              token.colorTextBase,
              opacitySkeletonLight
            ),
          },
        }}
      >
        <Space direction="vertical" size={token.sizeXXL}>
          <StyledEventSwitcher
            styledProps={{
              padding: token.paddingContentHorizontalLG,
            }}
          />
          <Menu />
        </Space>
      </ConfigProvider>
      <div style={{ textAlign: "center" }}>
        <StyledLogo
          src={logoImageSrc}
          styledProps={{ padding: token.paddingContentHorizontalLG }}
        />
      </div>
    </StyledLayoutSider>
  );
};

export default Sidebar;
