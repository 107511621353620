import { Col, Row, Space } from "antd";
import { useState } from "react";

import { Typography, TypographySizes } from "components/antd";

import ExportForm from "./Form";
import ExportLanding from "./Landing";

type ExportProps = {
  isFavouritedUsers: boolean;
  onDone: () => void;
};

const Export = ({ isFavouritedUsers, onDone }: ExportProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const isFormVisible = activeStepIndex === 0 && isFavouritedUsers;

  return (
    <Row justify="center">
      <Col span={24} lg={12}>
        <Space direction="vertical">
          <Space direction="vertical" style={{ textAlign: "center" }}>
            <Typography.Title>Export contacts</Typography.Title>
            {isFormVisible && (
              <Typography.Text size={TypographySizes.LG}>
                To export contacts choose contact types and insert your
                preferred email where you will receive the export
              </Typography.Text>
            )}
          </Space>
          {isFormVisible ? (
            <ExportForm onSubmit={() => setActiveStepIndex(1)} />
          ) : (
            <ExportLanding
              isFavouritedUsers={isFavouritedUsers}
              onDone={onDone}
            />
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default Export;
