import { InfoCircleFilled } from "@ant-design/icons";
import { Alert, Col, Row, Space, theme } from "antd";
import { useCallback, useMemo } from "react";

import Delay from "components/Delay";
import SuccessDivider from "components/SuccessDivider";
import { Card, Link, LinkTypes, Typography } from "components/antd";
import { isEqual } from "helpers";
import { useSelector } from "hooks";
import type { ProfileProps } from "store";
import {
  selectEventProfile,
  selectEventProfileInterests,
  selectEventProfileIsSingleEventAccessAndOnboardingNotFinished,
} from "store/selectors";

import { Loading } from ".";
import { FilterValues } from "./Filters";
import Item from "./Item";

type BodyRowProps = {
  children: React.ReactNode;
};

export const BodyRow = ({ children }: BodyRowProps) => {
  const { token } = theme.useToken();

  return (
    <Row
      gutter={[
        token.paddingContentHorizontalLG * 1.5,
        token.paddingContentHorizontalLG * 1.5,
      ]}
    >
      {children}
    </Row>
  );
};

type BodyProps = {
  activeFilter: FilterValues;
  onFilterLinkClick: (filterValue: FilterValues) => void;
  onNetworkPreferencesLinkClick: () => void;
  searchKeyword: string;
  users: ProfileProps[];
};

const Body = ({
  activeFilter,
  onFilterLinkClick,
  onNetworkPreferencesLinkClick,
  searchKeyword,
  users,
}: BodyProps) => {
  const eventProfile = useSelector(selectEventProfile);
  const eventProfileInterests = useSelector(selectEventProfileInterests);
  const isSingleEventAccessAndOnboardingNotFinished = useSelector(
    selectEventProfileIsSingleEventAccessAndOnboardingNotFinished
  );
  const { token } = theme.useToken();

  const defaultSorting = useCallback(
    (a: ProfileProps, b: ProfileProps) => {
      if (a.interests && b.interests && eventProfile) {
        const aMatchingItems = a.interests.business?.filter((item: string) =>
          eventProfile.interests?.network?.includes(item)
        );
        const bMatchingItems = b.interests.business?.filter((item: string) =>
          eventProfile.interests?.network?.includes(item)
        );

        return bMatchingItems?.length - aMatchingItems?.length;
      }
    },
    [eventProfile]
  );

  const matchingUsers: ProfileProps[] = useMemo(() => {
    return users
      .filter((user) => {
        const matchingInterests = eventProfile?.interests?.network?.filter(
          (interestTag: string) =>
            user?.interests?.business?.includes(interestTag)
        );

        return matchingInterests?.length > 0;
      })
      .sort(defaultSorting);
  }, [defaultSorting, eventProfile, users]);

  const otherUsers: ProfileProps[] = useMemo(() => {
    return users
      .filter((user) => {
        const matchingInterests = eventProfile?.interests?.network?.filter(
          (interestTag: string) =>
            user?.interests?.business?.includes(interestTag)
        );

        return matchingInterests?.length === 0;
      })
      .sort(defaultSorting);
  }, [defaultSorting, eventProfile, users]);

  const isMatchesFilterActive =
    (activeFilter as FilterValues) === FilterValues.Matches;
  const isFavoritesFilterActive =
    (activeFilter as FilterValues) === FilterValues.Favourites;
  const isMatchesFilterActiveAndResults =
    isMatchesFilterActive && matchingUsers.length > 0;
  const isSelectedTagsEqualToExpertiseTags = isEqual(
    eventProfileInterests.network,
    eventProfileInterests.business
  );

  const noResultsElement = !isSingleEventAccessAndOnboardingNotFinished && (
    <Col span={24}>
      <Typography.Text>
        You have no matches. To see potential matches, please{" "}
        <Link onClick={onNetworkPreferencesLinkClick} type={LinkTypes.Primary}>
          change your interests
        </Link>
        <br />
        Meanwhile you can browse attendees outside of your interests.
      </Typography.Text>
    </Col>
  );

  const matchingUsersLengthWithPluralizedText = `${
    matchingUsers.length
  } potential match${matchingUsers.length === 1 ? "" : "es"}`;

  return (
    <Space direction="vertical" size={token.sizeXXL}>
      {isMatchesFilterActiveAndResults &&
        !searchKeyword &&
        !isSelectedTagsEqualToExpertiseTags &&
        !isSingleEventAccessAndOnboardingNotFinished && (
          <Typography.Text>
            Showing {matchingUsersLengthWithPluralizedText}
          </Typography.Text>
        )}
      {isMatchesFilterActiveAndResults && searchKeyword && (
        <Typography.Text>Showing results for {searchKeyword}</Typography.Text>
      )}
      {isMatchesFilterActiveAndResults &&
        isSelectedTagsEqualToExpertiseTags &&
        !isSingleEventAccessAndOnboardingNotFinished && (
          <Alert
            icon={<InfoCircleFilled style={{ color: token.colorPrimary }} />}
            message={
              <>
                Showing{" "}
                {searchKeyword
                  ? `matches`
                  : matchingUsersLengthWithPluralizedText}{" "}
                <Typography.Text strong>
                  based on your expertise
                </Typography.Text>
                . See matches outside of your expertise by changing your{" "}
                <Link
                  onClick={onNetworkPreferencesLinkClick}
                  type={LinkTypes.Primary}
                >
                  networking preferences
                </Link>
              </>
            }
            showIcon
          />
        )}

      <BodyRow>
        {isMatchesFilterActive && (
          <>
            {matchingUsers.length > 0 ? (
              matchingUsers.map((user, index) => (
                <Delay milliseconds={index * 150} placeholder={<Loading />}>
                  <Col span={24} md={12}>
                    <Item user={user} />
                  </Col>
                </Delay>
              ))
            ) : (
              <>
                {searchKeyword ? (
                  <Col span={24}>
                    <Typography.Text>
                      No results for {searchKeyword}
                    </Typography.Text>
                  </Col>
                ) : (
                  noResultsElement
                )}
              </>
            )}

            {matchingUsers.length > 0 && otherUsers.length > 0 && (
              <SuccessDivider
                description="View event attendees outside of your matches below."
                title="You’ve seen your matches!"
              />
            )}

            {otherUsers.length > 0 &&
              otherUsers.map((user, index) => (
                <Col span={24} md={12}>
                  <Delay
                    milliseconds={index * 150}
                    placeholder={<Card loading />}
                  >
                    <Item user={user} />
                  </Delay>
                </Col>
              ))}
          </>
        )}
        {isFavoritesFilterActive && (
          <>
            {users.length === 0 && (
              <Col span={24}>
                <Typography.Text>
                  You have no favourites. To add a favourite{" "}
                  <Link
                    onClick={() => onFilterLinkClick(FilterValues.Matches)}
                    type={LinkTypes.Primary}
                  >
                    go to Matches
                  </Link>{" "}
                  and use the star icon on attendee profile
                </Typography.Text>
              </Col>
            )}

            {users.map((user, index) => (
              <Col span={24} md={12}>
                <Delay
                  milliseconds={index * 150}
                  placeholder={<Card loading />}
                >
                  <Item user={user} />
                </Delay>
              </Col>
            ))}
          </>
        )}
      </BodyRow>
    </Space>
  );
};

export default Body;
