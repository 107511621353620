import React, { useEffect } from "react";

import { useIsPathActive, useNavigateToPath, useSelector } from "hooks";
import LoadingPage from "pages/Loading";
import UnauthorizedPage from "pages/Unauthorized";
import { ProtectedPaths, UnprotectedPaths } from "routes";
import {
  selectAccount,
  selectAuthIsLoading,
  selectAuthUserType,
} from "store/selectors";

type AuthenticationGateProps = {
  children: React.ReactNode;
  isProtectedPath?: boolean;
};

export const AuthenticationGate = ({
  children,
  isProtectedPath,
}: AuthenticationGateProps) => {
  const isEntryPathActive = useIsPathActive(UnprotectedPaths.Entry);
  const isLoginPathActive = useIsPathActive(UnprotectedPaths.Login);
  const isRegisterPathActive = useIsPathActive(UnprotectedPaths.Register);
  const navigateToEvents = useNavigateToPath(ProtectedPaths.Events, {
    replace: true,
  });
  const { isAuthenticatedUser, isGuestUser } = useSelector(selectAuthUserType);
  const account = useSelector(selectAccount);
  const isLoading = useSelector(selectAuthIsLoading);

  useEffect(() => {
    isAuthenticatedUser &&
      !account.isTerminating &&
      (isEntryPathActive || isLoginPathActive || isRegisterPathActive) &&
      navigateToEvents();
  }, [
    account,
    isAuthenticatedUser,
    isEntryPathActive,
    isLoginPathActive,
    isRegisterPathActive,
    navigateToEvents,
  ]);

  switch (true) {
    case isLoading && isProtectedPath:
      return <LoadingPage />;
    case isGuestUser && isProtectedPath:
      return <UnauthorizedPage />;
    default:
      return <>{children}</>;
  }
};
