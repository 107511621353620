import Loader from "components/Loader";
import { Layout } from "components/antd";

type LoadingPageProps = {
  text?: string;
};

const LoadingPage = ({ text }: LoadingPageProps) => {
  return (
    <Layout isFullHeight style={{ justifyContent: "center" }}>
      <Loader text={text} />
    </Layout>
  );
};

export default LoadingPage;
