export const appLinkAppleStore =
  "https://apps.apple.com/us/app/exponaut-matchmaking/id6461381631";

export const appLinkGooglePlay =
  "https://play.google.com/store/apps/details?id=me.exponaut.matchmaking";

export const comparingDateAndTimeFormat = "YYMMDD HH:mm";

export const comparingTodaysEndDateAndTimeFormat = "YYMMDD 23:59";

export const dayFormat = "D";

export const dateAndTimeFormat = "DDMMYY HH:mm";

export const firestoreTimestampFormat = "MM/DD/YYYY HH:mm";

export const flexibleSpaceId = "flexibleSpace";

export const forwardSlashedEuropeanDateFormat = "DD/MM/YYYY";

export const humanReadableDateFormat = "dddd, Do of MMMM, YYYY";

export const humanReadableDateAndTimeFormat = "dddd, Do of MMMM, YYYY HH:mm";

export const timeFormat = "HH:mm";

export const twoHundredCharacterDescriptionMaxWidth = 450;
