import classNames from "classnames";

import { colorIconFill } from "styles/variables";

import type { OutlinedIcon } from ".";

export const TicketOutlined = ({
  className,
  fill = colorIconFill,
  height = 16,
  style,
  width = 16,
}: OutlinedIcon) => (
  <svg
    className={classNames("anticon", className)}
    fill="none"
    height={height}
    style={style}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6673 2.6665C13.7324 2.6665 14.6031 3.49912 14.6639 4.54899L14.6673 4.6665V6.6665C14.6673 7.00839 14.41 7.29017 14.0784 7.32869L14.0007 7.33317C13.6325 7.33317 13.334 7.63165 13.334 7.99984C13.334 8.34173 13.5913 8.62351 13.9229 8.66202L14.0007 8.6665C14.3425 8.6665 14.6243 8.92386 14.6628 9.25542L14.6673 9.33317V11.3332C14.6673 12.3983 13.8347 13.2689 12.7848 13.3298L12.6673 13.3332H10.0007H3.33398C2.26886 13.3332 1.39821 12.5006 1.33738 11.4507L1.33398 11.3332V9.33317C1.33398 8.99128 1.59134 8.7095 1.9229 8.67099L2.00065 8.6665C2.36884 8.6665 2.66732 8.36803 2.66732 7.99984C2.66732 7.65795 2.40996 7.37617 2.0784 7.33766L2.00065 7.33317C1.65876 7.33317 1.37698 7.07581 1.33847 6.74425L1.33398 6.6665V4.6665C1.33398 3.60138 2.1666 2.73073 3.21647 2.6699L3.33398 2.6665H10.0007H12.6673ZM9.33398 3.99984V4.6665L9.33847 4.74425C9.37698 5.07581 9.65876 5.33317 10.0007 5.33317C10.3688 5.33317 10.6673 5.03469 10.6673 4.6665V3.99984H12.6673L12.7451 4.00432C13.0766 4.04283 13.334 4.32461 13.334 4.6665V6.11317L13.2119 6.16137C12.4995 6.4674 12.0007 7.17535 12.0007 7.99984L12.004 8.11735C12.0514 8.93392 12.5886 9.61906 13.3261 9.88324L13.334 9.88584V11.3332L13.3295 11.4109C13.291 11.7425 13.0092 11.9998 12.6673 11.9998H10.6673V11.3332L10.6628 11.2554C10.6243 10.9239 10.3425 10.6665 10.0007 10.6665C9.63246 10.6665 9.33398 10.965 9.33398 11.3332V11.9998H3.33398L3.25624 11.9954C2.92468 11.9568 2.66732 11.6751 2.66732 11.3332V9.88584L2.78938 9.8383C3.50176 9.53228 4.00065 8.82432 4.00065 7.99984L3.99726 7.88232C3.94994 7.06576 3.41274 6.38062 2.67517 6.11643L2.66732 6.11317V4.6665L2.6718 4.58876C2.70735 4.2827 2.95018 4.03987 3.25624 4.00432L3.33398 3.99984H9.33398ZM10.6628 7.25542C10.6243 6.92386 10.3425 6.6665 10.0007 6.6665C9.63246 6.6665 9.33398 6.96498 9.33398 7.33317V8.6665L9.33847 8.74425C9.37698 9.07581 9.65876 9.33317 10.0007 9.33317C10.3688 9.33317 10.6673 9.03469 10.6673 8.6665V7.33317L10.6628 7.25542Z"
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
