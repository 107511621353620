import { Capacitor } from "@capacitor/core";
import { LocalNotifications } from "@capacitor/local-notifications";
import { PushNotifications } from "@capacitor/push-notifications";
import { useEffect } from "react";

import { useDispatch } from "hooks";

import { setFirebaseToken } from "../store/appSlice";

const usePushNotifications = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const isPushNotificationsAvailable =
      Capacitor.isPluginAvailable("PushNotifications");

    if (isPushNotificationsAvailable) {
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === "granted") {
          PushNotifications.register();
        } else {
          // Handle user denying permissions
        }
      });

      const registerNotifications = async () => {
        const permission = await PushNotifications.requestPermissions();
        if (permission.receive === "granted") {
          await PushNotifications.register();
        } else {
          console.error("Permission not granted for push notifications");
        }
      };

      registerNotifications();

      const registrationListener = PushNotifications.addListener(
        "registration",
        (token) => {
          dispatch(setFirebaseToken(token.value));
        }
      );

      const registrationErrorListener = PushNotifications.addListener(
        "registrationError",
        (err) => {
          console.error("Registration error: ", err.error);
        }
      );

      const pushReceivedListener = PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          console.log("Push notification received: ", notification);
          LocalNotifications.schedule({
            notifications: [
              {
                title: notification.title,
                body: notification.body,
                id: new Date().getTime() % 2147483647,
              },
            ],
          });
        }
      );

      const pushActionPerformedListener = PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          console.log(
            "Push notification action performed",
            notification.actionId,
            notification.inputValue
          );
        }
      );

      return () => {
        registrationListener.remove();
        registrationErrorListener.remove();
        pushReceivedListener.remove();
        pushActionPerformedListener.remove();
      };
    }
  }, [dispatch]);
};

export default usePushNotifications;
