import { ClockCircleOutlined } from "@ant-design/icons";
import { Space } from "antd";

import { Typography, TypographySizes } from "components/antd";

type CountdownProps = {
  days: number;
};

const Countdown = ({ days }: CountdownProps) => {
  const isActive = days > 0;
  const dueText = days < 0 ? "Past event" : "Happening now";

  return isActive ? (
    <Space>
      <ClockCircleOutlined style={{ fontSize: TypographySizes.SM }} />
      <Typography size={TypographySizes.XXS}>In {days} days</Typography>
    </Space>
  ) : (
    <Typography size={TypographySizes.XXS}>{dueText}</Typography>
  );
};

export default Countdown;
