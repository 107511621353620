import { CustomerServiceOutlined, MessageOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { theme } from "antd";
import { useNavigate } from "react-router-dom";

import { useIsPathActive, usePath } from "hooks";
import { ProtectedPaths } from "routes";

import { StyledMenu } from "./Menu";

const SupportMenu = () => {
  const isEventSupportPathActive = useIsPathActive(ProtectedPaths.EventSupport);
  const navigate = useNavigate();
  const eventSupportPath = usePath(ProtectedPaths.EventSupport);
  const { token } = theme.useToken();

  const handleClick: MenuProps["onClick"] = ({ key }) => {
    navigate(key);
  };

  const supportMenuItems: MenuProps["items"] = [
    {
      children: [
        {
          icon: <MessageOutlined />,
          key: eventSupportPath,
          label: "Message to support",
        },
      ],
      icon: <CustomerServiceOutlined />,
      key: "support",
      label: "Support",
    },
  ];

  return (
    <StyledMenu
      defaultOpenKeys={isEventSupportPathActive ? ["support"] : []}
      items={supportMenuItems}
      mode="inline"
      onClick={handleClick}
      selectedKeys={isEventSupportPathActive ? [eventSupportPath] : []}
      styledProps={{ borderRadius: token.borderRadius }}
    />
  );
};

export default SupportMenu;
