import { App } from "@capacitor/app";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DeepLinkHandler = (): any => {
  const navigate = useNavigate();
  useEffect(() => {
    App.addListener("appUrlOpen", (event) => {
      navigate(new URL(event.url).pathname);
    });
  }, [navigate]);

  return null;
};

export default DeepLinkHandler;
