import { Space, Typography } from "antd";

import { styled } from "styles";

const StyledDate = styled(Space)({
  marginTop: 15,
  textAlign: "left",
});

const StyledDay = styled(Typography.Title)({
  fontSize: "40px !important",
  marginBottom: "0 !important",
});

const StyledMonth = styled(Typography.Text)({
  fontSize: 12,
});

type DateProps = {
  day: string;
  month: string;
  weekday: string;
};

const Date = ({ day, month, weekday }: DateProps) => (
  <StyledDate>
    <StyledDay level={2}>{day}</StyledDay>
    <Space direction="vertical" size={0}>
      <Typography.Text>
        <strong>{weekday}</strong>
      </Typography.Text>
      <StyledMonth type="secondary">{month}</StyledMonth>
    </Space>
  </StyledDate>
);

export default Date;
