import { Col, Row } from "antd";

import SinglePageLayout from "layouts/SinglePage";

import AccountForm from "./Form";

export default () => (
  <SinglePageLayout title="Change your account information">
    <Row justify="center">
      <Col span={24} sm={22} md={20} lg={18} xl={16}>
        <AccountForm />
      </Col>
    </Row>
  </SinglePageLayout>
);
