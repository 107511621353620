import { Space } from "components/antd";
import { styled } from "styles";
import EditLayoutFooter, { minHeight } from "views/Edit/layout/Footer";

const StyledSpace = styled(Space)({
  justifyContent: "space-between",
  minHeight,
});

type FooterProps = {
  children?: React.ReactNode;
  contentElement?: React.ReactElement;
};

const Footer = ({ children, contentElement }: FooterProps) => (
  <EditLayoutFooter>
    {contentElement}
    <StyledSpace>{children}</StyledSpace>
  </EditLayoutFooter>
);

export default Footer;
