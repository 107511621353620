import {
  ArrowDownOutlined,
  CaretDownFilled,
  CaretUpFilled,
  CheckOutlined,
  CloseCircleFilled,
  CloseOutlined,
  FolderOpenOutlined,
  LinkedinFilled,
  MessageOutlined,
  QuestionOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Badge,
  Col,
  Collapse,
  ConfigProvider,
  Divider,
  Dropdown,
  Row,
  Tag,
  theme,
} from "antd";
import type { MenuProps } from "antd";
import dayjs from "dayjs";
import { getFunctions, httpsCallable } from "firebase/functions";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useNavigate } from "react-router-dom";

import iconCircleDarkSrc from "assets/images/icon-circle-dark.png";
import Swipe from "components/Swipe";
import { zIndexOffset } from "components/TabSwitcher";
import {
  Avatar,
  Button,
  Card,
  CardHead,
  Link,
  Space,
  SpaceTypes,
  StyledRadiantAvatar,
  Typography,
  TypographySizes,
} from "components/antd";
import {
  BackgroundSphereFilled,
  HandWaveTwoTone,
  MailFilled,
  ThreeDotsFilled,
  VideoCallFilled,
} from "components/icons";
import sendEmail from "database/handles/sendEmail";
import { formatUserCompanyNameWithJobTitle, formatUserFullName } from "helpers";
import { MeetingTypes } from "helpers/enums";
import type { InterestTag } from "hooks";
import {
  useBreakpoint,
  useDatabase,
  useMatchingInterests,
  usePath,
  useSelector,
} from "hooks";
import { ProtectedPaths } from "routes";
import type { MeetingProps } from "store";
import {
  selectAccountId,
  selectEventProfile,
  selectEventUsers,
} from "store/selectors";
import { boxShadow, boxShadowCard, boxShadowHover, styled } from "styles";
import type { StyledProps } from "styles";
import {
  colorGraySemi,
  colorGreenDark,
  colorIconFill,
  colorWhite,
} from "styles/variables";

import { Filters, FirestoreStatuses, filterColors } from ".";
import {
  acceptEventMeeting,
  declineEventMeeting,
} from "../../api/resources/events";
import CompanyWindow from "../CompanyWindow";
import CancelMeetingWindow from "./CancelMeetingWindow";
import DeclineMeetingWindow from "./DeclineMeetingWindow";

export const MEETING_DURATION_IN_MINUTES = 25;
export const MEETING_ROOM_AVAILABLE_BEFORE_MEETING_STARTS_IN_MINUTES = 60;

function encodeSvg(reactElement: any) {
  return (
    "data:image/svg+xml," +
    encodeURIComponent(ReactDOMServer.renderToStaticMarkup(reactElement))
  );
}

const StyledAvatar = styled(Avatar)(
  ({ styledProps: { backgroundColor, color } }: StyledProps) => ({
    ...boxShadow,
    backgroundColor,
    color,
    zIndex: 1,
  })
);

const StyledBackground = styled.div(
  ({
    styledProps: { backgroundColor, negativeMargin, padding },
  }: StyledProps) => ({
    backgroundColor,
    marginLeft: -negativeMargin,
    marginRight: -negativeMargin,
    padding,
  })
);

const StyledButton = styled(Button)(({ disabled }: any) => ({
  ...boxShadow,
  border: 0,
  ...(!disabled && {
    ":hover": {
      ...boxShadowHover,
    },
  }),
}));

const StyledCard = styled(Card)(
  ({ styledProps: { accentColor } }: StyledProps) => ({
    backgroundImage: `url(${encodeSvg(
      <BackgroundSphereFilled fill={accentColor} />
    )})`,
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    border: 0,
    boxShadow: boxShadowCard,
  })
);

const StyledCardOverlay = styled.div(
  ({ styledProps: { borderRadius, zIndex } }: StyledProps) => ({
    backgroundColor: colorGraySemi,
    borderRadius,
    height: "100%",
    opacity: 0.125,
    pointerEvents: "none",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    zIndex,
  })
);

const StyledCardMeta = styled(Card.Meta)({
  ".ant-card-meta-description": {
    color: colorGraySemi,
  },
  ".ant-card-meta-detail": {
    overflow: "auto",
  },
  ".ant-card-meta-title": {
    marginBottom: "0 !important",
    whiteSpace: "normal",
  },
});

const StyledCollapse = styled(Collapse)(
  ({ styledProps: { negativeMargin } }: StyledProps) => ({
    marginLeft: -negativeMargin,
    marginRight: -negativeMargin,
    ".ant-collapse-content-box": {
      padding: "0 !important",
    },
  })
);

const StyledDescription = styled.div(
  ({ styledProps: { color } }: StyledProps) => ({
    color,
    textAlign: "center",
  })
);

const StyledSpace = styled(Space)({
  position: "absolute",
  right: 0,
  top: 15,
});

const StyledUserTitleText = styled(Typography.Text)({
  color: colorGraySemi,
});

type ItemProps = {
  meeting: MeetingProps;
  talkSession: any;
  talkPopup: any;
  setTalkPopup: any;
};

const Item = ({ meeting, talkSession }: ItemProps) => {
  const database = useDatabase();
  const { isDesktop } = useBreakpoint();
  const functions = getFunctions();
  const navigate = useNavigate();
  const eventChatPath = usePath(ProtectedPaths.EventChat);
  const eventUsers = useSelector(selectEventUsers);
  const accountId = useSelector(selectAccountId);
  const eventProfile = useSelector(selectEventProfile);
  const [declineMeeting, setDeclineMeeting] = useState(null);
  const [cancelMeeting, setCancelMeeting] = useState(null);
  const [isCollapseActive, setIsCollapseActive] = useState<boolean>(false);
  const [isCompanyWindowOpen, setIsCompanyWindowOpen] =
    useState<boolean>(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isVideoCallAvailable, setIsVideoCallAvailable] = useState(false);
  const { token } = theme.useToken();

  const now = dayjs();
  const startTime = dayjs(meeting.proposed_time.toDate());

  const isAccepted = meeting.status === FirestoreStatuses.Accepted;
  const isDeclined = meeting.status === FirestoreStatuses.Declined;
  const isPending = meeting.status === FirestoreStatuses.Pending;

  const isViewerInvitee = meeting.invitee_id === accountId;
  const isViewerInviter = meeting.inviter_id === accountId;

  const isArchived = meeting.archived[accountId];
  const isArchivable = !isArchived && (isDeclined || isExpired);

  const isCancelable =
    !isExpired &&
    ((isAccepted && !now.isAfter(startTime)) ||
      (isPending && !now.isAfter(startTime) && isViewerInviter));

  const isAcceptDeclineButtonsVisible =
    isPending && isViewerInvitee && !isExpired;

  const isAcceptedAndVideoCallAvailable = isAccepted && isVideoCallAvailable;

  const isDeclinedMessageOrCancelledMessageAdded =
    meeting.decline_message || meeting.cancelled_message;

  useEffect(
    function setMeetingEndTime() {
      let timeout: null | ReturnType<typeof setTimeout> = null;

      const endTime = startTime.add(MEETING_DURATION_IN_MINUTES, "minutes");
      const timeUntilExpired = endTime.diff(now);

      if (timeUntilExpired > 0) {
        setIsExpired(false);

        timeout = setTimeout(() => {
          setIsExpired(true);
        }, timeUntilExpired);
      } else {
        setIsExpired(true);
      }

      return () => {
        clearTimeout(timeout);
      };
    },
    [meeting.status, now, startTime]
  );

  useEffect(
    function setVideoCallAvailability() {
      let timeout: null | ReturnType<typeof setTimeout> = null;

      if (isAccepted) {
        const timeUntilAvailable = startTime
          .subtract(
            MEETING_ROOM_AVAILABLE_BEFORE_MEETING_STARTS_IN_MINUTES,
            "minutes"
          )
          .diff(now);

        if (timeUntilAvailable > 0) {
          setIsVideoCallAvailable(false);

          timeout = setTimeout(() => {
            setIsVideoCallAvailable(true);
          }, timeUntilAvailable);
        } else {
          setIsVideoCallAvailable(!isExpired);
        }
      } else {
        setIsVideoCallAvailable(false);
      }

      return () => {
        clearTimeout(timeout);
      };
    },
    [isAccepted, isExpired, meeting.status, now, startTime]
  );

  const targetUserId =
    meeting.inviter_id === accountId ? meeting.invitee_id : meeting.inviter_id;
  const targetProfile = _.find(eventUsers, ({ id }) => id === targetUserId);
  const isTargetProfileLoaded = !!targetProfile?.id;
  const { isMatchingInterestsLoaded, userBusinessTagsWithMatchingInterests } =
    useMatchingInterests(targetProfile);

  const zIndexDivider = token.zIndexBase + 1;
  const zIndexOverlay = token.zIndexPopupBase + zIndexOffset - 1;
  const zIndexCardHead = zIndexOverlay;

  const handleAcceptMeeting = useCallback(async () => {
    await acceptEventMeeting(meeting.id);
    await database
      .collection("meetings")
      .doc(meeting.id)
      .update({
        status: "accepted",
      })
      .then(() => {});
  }, [database, meeting]);

  const handleArchiveMeeting = useCallback(async () => {
    await database
      .collection("meetings")
      .doc(meeting.id)
      .update({
        [`archived.${accountId}`]: true,
      });
  }, [accountId, database, meeting.id]);

  const handleCollapse = ({ isActive }: any) => setIsCollapseActive(isActive);

  const handleDeclineMeeting = useCallback(async () => {
    await declineEventMeeting(meeting.id);
    setDeclineMeeting(meeting);
  }, [meeting]);

  const handleCancelMeeting = useCallback(async () => {
    setCancelMeeting(meeting);
  }, [meeting]);

  const onClearDeclineMeeting = useCallback(() => {
    setDeclineMeeting(null);
  }, [setDeclineMeeting]);

  const onClearCancelMeeting = useCallback(() => {
    setCancelMeeting(null);
  }, [setCancelMeeting]);

  const handleJoinMeeting = useCallback(() => {
    window.open(meeting.meetingLink, "_blank");
  }, [meeting]);

  const handleStartChatButtonClick = useCallback(() => {
    navigate(eventChatPath + "/" + targetProfile.id);

    return null;
  }, [eventChatPath, navigate, targetProfile]);

  const handleStartMeeting = useCallback(async () => {
    const startMeeting = httpsCallable(functions, "startMeeting");

    startMeeting()
      .then(async (result: any) => {
        window.open(result.data.roomUrl, "_blank");

        await database.collection("meetings").doc(meeting.id).update({
          meetingLink: result.data.roomUrl,
        });

        // Send an email to the recipient when viewer has started the video call
        const emailData = {
          recipientName: targetProfile.firstName,
          starterFullName: eventProfile.firstName + " " + eventProfile.lastName,
          meetingLink: result.data.roomUrl,
        };
        sendEmail(
          targetProfile.email,
          emailData,
          "d-69b9b957453c487ca47248b8ef5c9805"
        );
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, [eventProfile, functions, database, meeting, targetProfile]);

  const filterKey = useMemo(() => {
    switch (meeting.status) {
      case FirestoreStatuses.Pending:
        return isViewerInvitee
          ? Filters.RequestsReceived
          : Filters.RequestsSent;

      default:
        return meeting.status as Filters;
    }
  }, [isViewerInvitee, meeting.status]);

  const dropdownItems = useMemo(() => {
    const items: MenuProps["items"] = [];

    isCancelable && items.push({ key: "cancel", label: "Cancel meeting" });

    return items;
  }, [isCancelable]);

  const handleDropdownMenuClick: MenuProps["onClick"] = useCallback(
    ({ key }) => {
      if (key === "cancel") handleCancelMeeting();
    },
    [handleCancelMeeting]
  );

  const buttonsElement = useMemo(
    () => (
      <Space style={{ justifyContent: "center" }} wrap>
        {isAcceptDeclineButtonsVisible && (
          <>
            <StyledButton
              danger
              icon={<CloseOutlined style={{ marginLeft: -token.marginXXS }} />}
              key="decline"
              onClick={handleDeclineMeeting}
              shape="round"
            >
              Decline
            </StyledButton>

            <StyledButton
              icon={<CheckOutlined style={{ marginLeft: -token.marginXXS }} />}
              key="accept"
              onClick={handleAcceptMeeting}
              shape="round"
              type="primary"
            >
              Accept
            </StyledButton>
          </>
        )}

        <StyledButton
          icon={<MessageOutlined style={{ marginLeft: -token.marginXXS }} />}
          key="chat"
          onClick={handleStartChatButtonClick}
          shape="round"
        >
          Chat
        </StyledButton>

        {isAccepted && !isExpired && meeting.type === MeetingTypes.Virtual && (
          <StyledButton
            disabled={!isAcceptedAndVideoCallAvailable}
            icon={
              <VideoCallFilled
                fill={
                  !isAcceptedAndVideoCallAvailable
                    ? token.colorTextDisabled
                    : undefined
                }
                fontSize={14}
                style={{ marginLeft: -token.marginXXS }}
              />
            }
            key="join"
            onClick={
              meeting.meetingLink ? handleJoinMeeting : handleStartMeeting
            }
            shape="round"
          >
            Join meeting
          </StyledButton>
        )}

        {isArchivable && (
          <StyledButton
            color={filterColors[Filters.Declined]}
            icon={
              <FolderOpenOutlined style={{ marginLeft: -token.marginXXS }} />
            }
            key="archive"
            onClick={handleArchiveMeeting}
            shape="round"
          >
            Archive
          </StyledButton>
        )}
      </Space>
    ),
    [
      handleAcceptMeeting,
      handleArchiveMeeting,
      handleDeclineMeeting,
      handleJoinMeeting,
      handleStartChatButtonClick,
      handleStartMeeting,
      isAcceptDeclineButtonsVisible,
      isAccepted,
      isArchivable,
      isAcceptedAndVideoCallAvailable,
      isExpired,
      meeting,
      token,
    ]
  );

  const extraContentElement = useMemo(() => {
    const isVisible = isCancelable;

    return (
      <StyledSpace>
        {isVisible && (
          <Dropdown
            trigger={["click"]}
            menu={{
              items: dropdownItems,
              onClick: handleDropdownMenuClick,
            }}
          >
            <Button type="link">
              <ThreeDotsFilled />
            </Button>
          </Dropdown>
        )}
      </StyledSpace>
    );
  }, [dropdownItems, handleDropdownMenuClick, isCancelable]);

  const infoElement = useMemo(() => {
    if (isExpired) {
      return (
        <Typography.Text size={TypographySizes.XS} strong>
          This meeting is expired
        </Typography.Text>
      );
    } else if (isAccepted) {
      if (meeting.type === MeetingTypes.InPerson) {
        if (meeting.tableNumber) {
          return (
            <Typography.Text size={TypographySizes.XS} strong>
              Your assigned table number is {meeting.tableNumber}
            </Typography.Text>
          );
        } else {
          return (
            <Typography.Text size={TypographySizes.XS} strong>
              Meeting locations are open for your choice
            </Typography.Text>
          );
        }
      } else if (meeting.type === MeetingTypes.Virtual) {
        if (meeting.meetingLink) {
          return (
            <Typography.Text size={TypographySizes.XS} strong>
              Meeting is ongoing
            </Typography.Text>
          );
        } else if (isVideoCallAvailable) {
          return (
            <Typography.Text size={TypographySizes.XS} strong>
              Virtual meeting room is open
            </Typography.Text>
          );
        } else {
          return (
            <Typography.Text size={TypographySizes.XS} strong>
              Meeting room will open 1h before meeting
            </Typography.Text>
          );
        }
      }
    } else if (isPending) {
      if (isViewerInvitee) {
        return (
          <Typography.Text size={TypographySizes.XS} strong>
            {meeting.type} meeting request
          </Typography.Text>
        );
      } else if (isViewerInviter) {
        return (
          <Typography.Text size={TypographySizes.XS} strong>
            Invitation sent for {meeting.type} meeting
          </Typography.Text>
        );
      }
    } else if (isDeclined) {
      if (isViewerInvitee) {
        return (
          <Typography.Text size={TypographySizes.XS} strong>
            You declined {meeting.type} meeting
          </Typography.Text>
        );
      } else if (isViewerInviter) {
        return (
          <Typography.Text size={TypographySizes.XS} strong>
            {meeting.type} meeting declined
          </Typography.Text>
        );
      }
    }
  }, [
    isAccepted,
    isDeclined,
    isPending,
    isExpired,
    isVideoCallAvailable,
    isViewerInvitee,
    isViewerInviter,
    meeting,
  ]);

  const inPersonTypeAvatarElement = useMemo(
    () => (
      <div
        style={{
          height: 24,
          lineHeight: "24px",
        }}
      >
        {meeting.tableNumber ? (
          <Typography.Title color={colorIconFill} level={4}>
            {meeting.tableNumber}
          </Typography.Title>
        ) : (
          <TeamOutlined style={{ color: colorIconFill, fontSize: 24 }} />
        )}
      </div>
    ),
    [meeting]
  );

  const linksElement = useMemo(
    () => (
      <>
        <a href={`mailto:${targetProfile?.email}`}>
          <MailFilled style={{ display: "block" }} />
        </a>
        {targetProfile?.linkedIn && (
          <Link to={targetProfile.linkedIn}>
            <LinkedinFilled
              style={{
                color: colorIconFill,
                display: "block",
                fontSize: 21,
              }}
            />
          </Link>
        )}
        {targetProfile?.companyId && (
          <Link onClick={() => setIsCompanyWindowOpen(true)}>
            <img
              src={iconCircleDarkSrc}
              style={{ display: "block", width: "24px" }}
            />
          </Link>
        )}
      </>
    ),
    [targetProfile]
  );

  const meetingsElement = useMemo(
    () =>
      (meeting.message || isDeclinedMessageOrCancelledMessageAdded) && (
        <Row gutter={[10, 10]} justify="center">
          {meeting.message && (
            <Col>
              <Alert
                icon={
                  <HandWaveTwoTone
                    height={18}
                    width={18}
                    style={{ marginTop: 2 }}
                  />
                }
                message={
                  <Typography.Text size={TypographySizes.SM}>
                    {isViewerInviter && <strong>Your message:</strong>}{" "}
                    {meeting.message}
                  </Typography.Text>
                }
                showIcon
                style={{
                  ...boxShadow,
                  alignItems: "flex-start",
                  textAlign: "initial",
                  width: "100%",
                }}
              />
            </Col>
          )}
          {meeting.message && isDeclinedMessageOrCancelledMessageAdded && (
            <Col span={24}>
              <ArrowDownOutlined style={{ display: "block", margin: "auto" }} />
            </Col>
          )}
          {isDeclinedMessageOrCancelledMessageAdded && (
            <Col>
              <Alert
                icon={
                  <CloseCircleFilled
                    style={{
                      color: token.colorError,
                      marginTop: 4,
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  />
                }
                message={
                  <Typography.Text size={TypographySizes.SM}>
                    <strong>Reason for cancellation: </strong>
                    {meeting.decline_message || meeting.cancelled_message}
                  </Typography.Text>
                }
                showIcon
                style={{
                  ...boxShadow,
                  alignItems: "flex-start",
                  textAlign: "initial",
                  width: "100%",
                }}
              />
            </Col>
          )}
        </Row>
      ),
    [isDeclinedMessageOrCancelledMessageAdded, isViewerInviter, meeting, token]
  );

  const virtualTypeAvatarElement = useMemo(
    () => (
      <div
        style={{
          height: 20,
          lineHeight: "20px",
        }}
      >
        {!isExpired && meeting.meetingLink ? (
          <Badge
            dot
            offset={[-18, 12]}
            status="success"
            style={{ boxShadow: "none", transform: "scale(1.667)" }}
          >
            <VideoCallFilled fontSize={20} />
          </Badge>
        ) : (
          <VideoCallFilled fontSize={20} />
        )}
      </div>
    ),
    [isExpired, meeting]
  );

  const accentColor = filterColors[filterKey];
  const alertTypeInfoColor = colorWhite;
  const cardPaddingBase = token.paddingLG;
  const collapseHeaderPadding = token.paddingSM;

  return isTargetProfileLoaded ? (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: colorGreenDark,
          },
          Collapse: {
            borderRadiusLG: 0,
          },
          Divider: {
            colorSplit: token.colorTextBase,
          },
        },
        token: {
          colorInfo: alertTypeInfoColor,
        },
      }}
    >
      <StyledCard
        bodyStyle={{
          borderRadius: token.borderRadiusLG,
          overflow: "hidden",
          paddingBottom: 0,
        }}
        styledProps={{
          accentColor,
        }}
      >
        {isExpired && (
          <StyledCardOverlay
            styledProps={{
              borderRadius: token.borderRadiusLG,
              zIndex: zIndexOverlay,
            }}
          />
        )}
        <CardHead zIndex={zIndexCardHead}>
          <Tag color={accentColor} style={{ margin: 0 }}>
            {meeting.type} meeting
          </Tag>
        </CardHead>
        {isDesktop && (
          <Space size="middle" style={{ position: "absolute" }}>
            {linksElement}
          </Space>
        )}
        {extraContentElement}
        <Space
          direction="vertical"
          size="large"
          style={{ marginTop: token.marginSM }}
          type={SpaceTypes.Centered}
        >
          <Space size={token.sizeXXS} style={{ justifyContent: "center" }}>
            <StyledAvatar
              src={
                isViewerInviter
                  ? eventProfile?.avatarUrl
                  : targetProfile?.avatarUrl
              }
              size={46}
              styledProps={{
                backgroundColor: token.colorWhite,
                color: token.colorText,
              }}
            >
              <QuestionOutlined />
            </StyledAvatar>
            <StyledRadiantAvatar
              size={58}
              styledProps={{
                backgroundColor: token.colorWhite,
                borderColor: accentColor,
                borderOffset: 1,
                borderWidth: 5,
                margin: 0,
              }}
            >
              {meeting.type === MeetingTypes.Virtual
                ? virtualTypeAvatarElement
                : inPersonTypeAvatarElement}
            </StyledRadiantAvatar>
            <StyledAvatar
              src={
                isViewerInvitee
                  ? eventProfile?.avatarUrl
                  : targetProfile?.avatarUrl
              }
              size={46}
              styledProps={{
                backgroundColor: token.colorWhite,
                color: token.colorText,
              }}
            >
              <QuestionOutlined />
            </StyledAvatar>
          </Space>
          <Space direction="vertical">
            {infoElement}
            <StyledCardMeta
              title={formatUserFullName(targetProfile)}
              description={
                <StyledUserTitleText size={TypographySizes.SM} strong>
                  {formatUserCompanyNameWithJobTitle(targetProfile)}
                </StyledUserTitleText>
              }
            />
          </Space>
          {buttonsElement}
          {meetingsElement}
        </Space>
        <StyledCollapse
          bordered={false}
          defaultActiveKey={[]}
          destroyInactivePanel={true}
          expandIcon={handleCollapse}
          expandIconPosition="end"
          ghost
          styledProps={{ negativeMargin: cardPaddingBase }}
        >
          <Collapse.Panel
            header={
              <>
                <div style={{ textAlign: isDesktop ? "center" : "right" }}>
                  <Typography.Text
                    style={{ fontSize: token.fontSizeSM }}
                    strong
                    underline
                  >
                    Read more
                  </Typography.Text>{" "}
                  {isCollapseActive ? (
                    <CaretUpFilled
                      style={{
                        fontSize: token.fontSizeLG,
                      }}
                    />
                  ) : (
                    <CaretDownFilled
                      style={{
                        fontSize: token.fontSizeLG,
                      }}
                    />
                  )}
                </div>

                <Divider
                  dashed
                  style={{
                    left: 0,
                    marginBottom: 0,
                    marginTop: collapseHeaderPadding,
                    position: "absolute",
                    zIndex: zIndexDivider,
                  }}
                />
              </>
            }
            key="more"
          >
            {!isDesktop && (
              <StyledBackground
                style={{ paddingBottom: 0 }}
                styledProps={{
                  backgroundColor: token.colorBgLayout,
                  padding: cardPaddingBase,
                }}
              >
                <Space size="middle">{linksElement}</Space>
              </StyledBackground>
            )}
            <StyledBackground
              styledProps={{
                backgroundColor: token.colorBgLayout,
                padding: cardPaddingBase,
              }}
            >
              <Row justify="center">
                <Col span={24} lg={20}>
                  <StyledDescription
                    styledProps={{
                      color: colorGraySemi,
                    }}
                  >
                    {targetProfile?.description}
                  </StyledDescription>
                </Col>
              </Row>
            </StyledBackground>
            <StyledBackground
              style={{ paddingTop: 0 }}
              styledProps={{
                backgroundColor: token.colorBgLayout,
                negativeMargin: cardPaddingBase,
                padding: cardPaddingBase,
              }}
            >
              <ConfigProvider
                theme={{
                  token: {
                    colorBgContainer: token.colorBgLayout,
                  },
                }}
              >
                <Swipe
                  fontSize={TypographySizes.XS}
                  isLoading={!isMatchingInterestsLoaded}
                  justifyContent="center"
                  padding={token.paddingLG}
                >
                  {userBusinessTagsWithMatchingInterests.map(
                    ({ id, label }: InterestTag) => (
                      <span key={id}>{label}</span>
                    )
                  )}
                </Swipe>
              </ConfigProvider>
            </StyledBackground>
          </Collapse.Panel>
        </StyledCollapse>
      </StyledCard>

      <CancelMeetingWindow
        viewer={eventProfile}
        user={targetProfile}
        cancelMeeting={cancelMeeting}
        onClearCancelMeeting={onClearCancelMeeting}
      />
      <DeclineMeetingWindow
        viewer={eventProfile}
        user={targetProfile}
        declineMeeting={declineMeeting}
        onClearDeclineMeeting={onClearDeclineMeeting}
      />
      <CompanyWindow
        companyId={targetProfile?.companyId}
        isOpen={isCompanyWindowOpen}
        onClose={() => setIsCompanyWindowOpen(false)}
      />
    </ConfigProvider>
  ) : (
    <Card loading />
  );
};

export default Item;
