import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

import type { Store } from "store";

import usePushNotifications from "../hooks/usePushNotifications";
import { firebaseConfig } from "./firebase.config";

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
firebase.firestore();
firebase.functions();

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users", // where profiles are stored in database
  useFirestoreForProfile: true,
};

type FirebaseProviderProps = {
  children: React.ReactNode;
  store: Store;
};

export const FirebaseProvider = ({
  children,
  store,
}: FirebaseProviderProps) => {
  usePushNotifications();

  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
  };

  return (
    <ReactReduxFirebaseProvider {...rrfProps}>
      {children}
    </ReactReduxFirebaseProvider>
  );
};

export type Listener = {
  collection: string;
  storeAs: string;
  doc?: string;
  where?: any;
  orderBy?: string[][];
  startAt?: string;
  limit?: number;
  queryParams?: string[];
};
