import { Button, ConfigProvider, theme } from "antd";

import iconCircleSrc from "assets/images/icon-circle.png";
import { TypographySizes } from "components/antd";
import { styled } from "styles";
import { fontWeightMedium } from "styles/variables";

const StyledBoothButton = styled(Button)({
  alignItems: "center",
  display: "flex",
  fontWeight: fontWeightMedium,
  height: "28px !important",
  width: 170,
  ".ant-btn-icon": {
    height: "100%",
  },
});

const StyledIcon = styled.img({
  height: "100%",
  transform: "scale(1.5)",
});

type BoothButtonProps = {
  link?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
};

const BoothButton = ({ onClick, style }: BoothButtonProps) => {
  const { token } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: token.colorTextBase,
            fontSize: TypographySizes.SM,
          },
        },
      }}
    >
      <StyledBoothButton
        icon={<StyledIcon src={iconCircleSrc} />}
        onClick={onClick}
        shape="round"
        size="small"
        style={style}
        type="primary"
      >
        Project information
      </StyledBoothButton>
    </ConfigProvider>
  );
};

export default BoothButton;
