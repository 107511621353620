import { Space, message } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { isFunction } from "helpers";
import { useDispatch, useSelector } from "hooks";
import type { ProfileInterestsProps } from "store";
import { fetchUserEventUpdateProfile } from "store/appSlice";
import { selectAccountId, selectEventProfileInterests } from "store/selectors";

import EditLayoutFooter from "../layout/Footer";
import Actions from "./Actions";
import Groups from "./Groups";

export enum FirestoreKeys {
  "business",
  "network",
}

export const defaultUserInterests: any = {
  [FirestoreKeys[0]]: [],
  [FirestoreKeys[1]]: [],
};

type ParamsProps = {
  eventId: string;
};

type EditInterestsProps = {
  firestoreKeys?: FirestoreKeys[];
  userInterestTagsOverride?: string[];
  onChange?: (interests: ProfileInterestsProps) => void;
  onDone?: () => void;
};

const EditInterests = ({
  firestoreKeys = [FirestoreKeys.business],
  userInterestTagsOverride,
  onChange,
  onDone,
}: EditInterestsProps) => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const { eventId } = useParams<ParamsProps>();
  const accountId = useSelector(selectAccountId);
  const eventProfileInterests = useSelector(selectEventProfileInterests);
  const [interests, setInterests] = useState(defaultUserInterests);
  const interestKeys = useMemo(
    () =>
      firestoreKeys.map(
        (firestoreKey: FirestoreKeys) => FirestoreKeys[firestoreKey]
      ),
    [firestoreKeys]
  );
  const mainInterestKey = FirestoreKeys[firestoreKeys[0]];

  useEffect(() => {
    if (userInterestTagsOverride) {
      setInterests((state: any) => {
        const updatedState = { ...state };

        interestKeys.map((interestKey: string) => {
          updatedState[interestKey] = userInterestTagsOverride;
        });

        return updatedState;
      });
    }
  }, [interestKeys, userInterestTagsOverride]);

  useEffect(() => {
    setInterests(eventProfileInterests);
  }, [eventProfileInterests]);

  const handleInterestsSave = useCallback(async () => {
    const data = {
      interest_tags: interests,
    };

    dispatch(fetchUserEventUpdateProfile(accountId, eventId, data));
  }, [accountId, dispatch, eventId, interests]);

  const handleSave = useCallback(() => {
    handleInterestsSave();

    isFunction(onDone)
      ? onDone()
      : messageApi.open({
          type: "success",
          content: "Your interests were saved.",
        });
  }, [handleInterestsSave, messageApi, onDone]);

  const handleTagAdd = useCallback(
    (tag: string) => {
      setInterests((state: any) => {
        const updatedState = { ...state };

        interestKeys.map((interestKey: string) => {
          updatedState[interestKey] = [...state[interestKey], tag];
        });

        isFunction(onChange) && onChange(updatedState);
        return updatedState;
      });
    },
    [interestKeys, onChange]
  );

  const handleTagRemove = useCallback(
    (tagId: string) => {
      setInterests((state: any) => {
        const updatedState = { ...state };

        interestKeys.map((interestKey: string) => {
          const filteredTags = state[interestKey].filter(
            (id: string) => id !== tagId
          );

          updatedState[interestKey] = [...filteredTags];
        });

        isFunction(onChange) && onChange(updatedState);
        return updatedState;
      });
    },
    [interestKeys, onChange]
  );

  return (
    <>
      {contextHolder}

      <Space direction="vertical" size="large">
        <Groups
          onAddTag={handleTagAdd}
          onRemoveTag={handleTagRemove}
          userInterestTags={interests[mainInterestKey]}
        />
      </Space>

      <EditLayoutFooter>
        <Actions onSave={handleSave} />
      </EditLayoutFooter>
    </>
  );
};

export default EditInterests;
