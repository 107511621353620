export const bodyTextBig: React.CSSProperties = {
  fontSize: "16px",
};

export const boxShadow: React.CSSProperties = {
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.17)",
};

export const boxShadowHover: React.CSSProperties = {
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
};

export const boxShadowCard = "0px 0px 24px rgba(0, 0, 0, 0.1)";

export const textCenter: React.CSSProperties = {
  textAlign: "center",
};
