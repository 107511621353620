import { Col, Form, Row, message, theme } from "antd";
import { useCallback, useState } from "react";

import { deleteUser } from "api/resources/users";
import ConfirmationModal from "components/ConfirmationModal";
import {
  Button,
  Input,
  InputAppearances,
  Space,
  SpaceTypes,
  Typography,
  TypographySizes,
} from "components/antd";
import { isLoaded } from "helpers";
import { validateName } from "helpers/validators";
import {
  useDatabase,
  useLogOut,
  useNavigateToPath,
  useReturnPath,
  useSelector,
} from "hooks";
import { UnprotectedPaths } from "routes";
import { selectAccount, selectAccountId } from "store/selectors";

enum FieldValues {
  FirstName = "first_name",
  LastName = "last_name",
}

const AccountForm = () => {
  const database = useDatabase();
  const logOut = useLogOut();
  const returnPath = useReturnPath();
  const accountId = useSelector(selectAccountId);
  const account = useSelector(selectAccount);
  const [
    isDeleteAccountConfirmationModalOpen,
    setIsDeleteAccountConfirmationModalOpen,
  ] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { token } = theme.useToken();

  const navigateToResetPassword = useNavigateToPath(
    UnprotectedPaths.ResetPassword,
    {
      state: {
        email: account.email,
        returnPath,
      },
    }
  );

  const handleAccountDeletion = async () => {
    setIsDeleteAccountConfirmationModalOpen(false);

    await deleteUser(accountId)
      .then(logOut)
      .catch(() => {
        message.error("Account deletion failed.");
      });
  };

  const onSubmit = useCallback(
    async (values: { [key: string]: FieldValues }) => {
      setDisabled(true);

      await database
        .collection("users")
        .doc(accountId)
        .update(values)
        .then(() => {
          setDisabled(false);
        });
    },
    [database, accountId]
  );

  return (
    isLoaded(account) && (
      <>
        <Form disabled={disabled} onFinish={onSubmit} size="large">
          <Space direction="vertical">
            <Typography.Text
              size={TypographySizes.LG}
              style={{ display: "block", textAlign: "center" }}
            >
              This information is universal
            </Typography.Text>
            <Space type={SpaceTypes.FormContentAndButton}>
              <Row
                gutter={[
                  token.paddingContentHorizontalLG,
                  token.paddingContentVerticalSM,
                ]}
              >
                <Col span={24} sm={18} md={16} lg={14} xl={12}>
                  <Form.Item
                    initialValue={account.firstName}
                    name={FieldValues.FirstName}
                    rules={[
                      {
                        message: "Enter first name to continue",
                        required: true,
                      },
                      { validator: validateName },
                    ]}
                  >
                    <Input
                      appearance={InputAppearances.Underline}
                      data-qa="first-name"
                      placeholder="First name"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} sm={18} md={16} lg={14} xl={12}>
                  <Form.Item
                    initialValue={account.lastName}
                    name={FieldValues.LastName}
                    rules={[
                      {
                        message: "Enter last name to continue",
                        required: true,
                      },
                      { validator: validateName },
                    ]}
                  >
                    <Input
                      appearance={InputAppearances.Underline}
                      data-qa="last-name"
                      placeholder="Last name"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={24} sm={18} md={16} lg={14} xl={12}>
                  <Space type={SpaceTypes.VerticalButtonGroup}>
                    <Button
                      data-qa="submit"
                      block
                      htmlType="submit"
                      shape="round"
                      size="middle"
                      type="primary"
                    >
                      Save
                    </Button>
                    <Button
                      block
                      ghost
                      onClick={navigateToResetPassword}
                      shape="round"
                      size="middle"
                    >
                      Change your password
                    </Button>
                    <Button
                      block
                      ghost
                      onClick={() =>
                        setIsDeleteAccountConfirmationModalOpen(true)
                      }
                      shape="round"
                      size="middle"
                    >
                      Delete account
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Space>
          </Space>
        </Form>

        <ConfirmationModal
          danger
          isOpen={isDeleteAccountConfirmationModalOpen}
          onClose={() => setIsDeleteAccountConfirmationModalOpen(false)}
          title={`Are you sure you want to delete your account?`}
          onOk={handleAccountDeletion}
        />
      </>
    )
  );
};

export default AccountForm;
