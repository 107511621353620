import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { isEmpty, isLoaded } from "helpers";
import { useDispatch, useSelector } from "hooks";
import LoadingPage from "pages/Loading";
import NotFoundPage from "pages/NotFound";
import { fetchEvent, fetchEventInterests, unsetEvent } from "store/appSlice";
import { selectAccountId, selectEvent } from "store/selectors";

type EventGateProps = {
  children: React.ReactNode;
};

export const EventGate = ({ children }: EventGateProps) => {
  const event = useSelector(selectEvent);

  switch (true) {
    case !isLoaded(event):
      return <LoadingPage text="Loading event..." />;
    case isEmpty(event):
      return <NotFoundPage />;
    default:
      return <>{children}</>;
  }
};

type EventProviderProps = {
  children: React.ReactNode;
};

export const EventProvider = ({ children }: EventProviderProps) => {
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const accountId = useSelector(selectAccountId);

  useEffect(
    function fetchOnMountAndUnsetOnUnmount() {
      dispatch(fetchEvent(accountId, eventId));
      dispatch(fetchEventInterests(eventId));

      return () => {
        dispatch(unsetEvent());
      };
    },
    [accountId, dispatch, eventId]
  );

  return <>{children}</>;
};
