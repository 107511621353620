import { CheckCircleTwoTone } from "@ant-design/icons";
import { Divider, theme } from "antd";
import { useEffect, useState } from "react";

import { Typography } from "components/antd";
import { useIsRefInViewport } from "hooks";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { animationDurationLong } from "styles/variables";

const StyledCheckCircleTwoTone = styled(CheckCircleTwoTone)({
  svg: {
    path: {
      transition: `fill ${animationDurationLong}s ${animationDurationLong}s`,
    },
  },
});

const StyledContainer = styled.div(
  ({ styledProps: { marginBottom } }: StyledProps) => ({
    marginBottom,
    textAlign: "center",
    width: "100%",
  })
);

type SuccessDividerProps = {
  description: string;
  title: string;
};

const SuccessDivider = ({ description, title }: SuccessDividerProps) => {
  const { isRefInViewport, ref } = useIsRefInViewport();
  const [isShown, setIsShown] = useState<boolean>(false);
  const { token } = theme.useToken();

  useEffect(() => {
    isRefInViewport && setIsShown(true);
  }, [isRefInViewport]);

  return (
    <StyledContainer ref={ref} styledProps={{ marginBottom: token.margin }}>
      <Divider>
        <StyledCheckCircleTwoTone
          style={{ fontSize: 32 }}
          twoToneColor={isShown ? token.colorPrimary : token.colorBorder}
        />
      </Divider>
      <Typography.Title level={5}>{title}</Typography.Title>
      <Typography.Text>{description}</Typography.Text>
    </StyledContainer>
  );
};

export default SuccessDivider;
