import { useContext, useMemo } from "react";

import { WindowContext, scrollContainerClassName } from "components/Window";
import { appWrapperElementId } from "index";

type ScrollOptions = {
  behavior?: ScrollBehavior;
  top?: number;
};

export const useScroll = ({
  behavior = "smooth",
  top = 0,
}: ScrollOptions = {}) => {
  const { isWindowOpen } = useContext(WindowContext);

  const container = isWindowOpen
    ? document.getElementsByClassName(scrollContainerClassName)[0]
    : document.getElementById(appWrapperElementId);

  const scroll = useMemo(() => {
    return () => container?.scroll({ behavior, top });
  }, [behavior, container, top]);

  return scroll;
};
