import {
  LinkedinFilled,
  MessageOutlined,
  PlusOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { ConfigProvider, Divider, Skeleton, Space, Tag, theme } from "antd";
import { Image } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  addUserToFavorites,
  removeUserFromFavorites,
} from "api/resources/users";
import BoothButton from "components/BoothButton";
import ProjectButton from "components/ProjectButton";
import Swipe from "components/Swipe";
import { Button } from "components/antd";
import {
  Avatar,
  Card,
  CardHead,
  Link,
  Typography,
  TypographySizes,
} from "components/antd";
import { MutualInterestsOutlined } from "components/icons";
import {
  formatUserCompanyNameWithJobTitle,
  formatUserFullName,
  formatUserInitials,
  formatUserTypeProjectName,
  isLoaded,
} from "helpers";
import { twoHundredCharacterDescriptionMaxWidth } from "helpers/constants";
import { useRefElementProperties } from "hooks";
import {
  useBreakpoint,
  useDispatch,
  useIsUserViewer,
  useMatchingInterests,
  usePath,
  useSelector,
} from "hooks";
import type { InterestTag } from "hooks";
import { ProtectedPaths } from "routes";
import type { ProfileProps } from "store";
import { fetchEvent } from "store/appSlice";
import {
  isMeetingsCreationAllowed,
  selectAccountId,
  selectEventProfile,
  selectEventProfileIsSingleEventAccessAndOnboardingNotFinished,
  selectIsUserFavourite,
} from "store/selectors";
import { boxShadowCard, styled } from "styles";
import type { StyledProps } from "styles";
import { colorGrayButtonBackground, colorGraySemi } from "styles/variables";

const StyledButtonsContainer = styled.div(
  ({ styledProps: { gap } }: StyledProps) => ({
    display: "flex",
    flexWrap: "wrap",
    gap,
  })
);

const StyledCard = styled(Card)({
  boxShadow: `${boxShadowCard} !important`,
  ".ant-card-actions": {
    borderTop: 0,
    "> li": {
      margin: 0,
      "> span": {
        cursor: "inherit",
        "&:hover": {
          color: "inherit",
        },
      },
    },
  },
});

const StyledCardMeta = styled(Card.Meta)({
  ".ant-card-meta-detail": {
    "> div:not(:last-child)": {
      marginBottom: 0,
      whiteSpace: "normal",
    },
  },
});

type ProfileCardProps = {
  isLoading?: boolean;
  onBoothButtonClick: () => void;
  onProjectButtonClick: () => void;
  onProposeMeetingButtonClick?: (user: ProfileProps) => void;
  user: ProfileProps;
};

const ProfileCard = ({
  isLoading = false,
  onBoothButtonClick,
  onProjectButtonClick,
  onProposeMeetingButtonClick,
  user,
}: ProfileCardProps) => {
  const { isDesktop } = useBreakpoint();
  const dispatch = useDispatch();
  const isUserViewer = useIsUserViewer(user);
  const { eventId } = useParams();
  const navigate = useNavigate();
  const eventChatPath = usePath(ProtectedPaths.EventChat);
  const {
    ref,
    relativeToViewport: { width },
  } = useRefElementProperties();
  const accountId = useSelector(selectAccountId);
  const myUserEventProfile = useSelector(selectEventProfile);
  const isSingleEventAccessAndOnboardingNotFinished = useSelector(
    selectEventProfileIsSingleEventAccessAndOnboardingNotFinished
  );
  const { token } = theme.useToken();
  const isUserFavourite = useSelector((state) =>
    selectIsUserFavourite(state, user.id)
  );
  const [isFavourite, setIsFavourite] = useState(isUserFavourite);

  const handleProposeMeetingButtonClick = useCallback(() => {
    onProposeMeetingButtonClick(user);
  }, [onProposeMeetingButtonClick, user]);

  const handleStartChatButtonClick = useCallback(() => {
    navigate(eventChatPath + "/" + user.id);
  }, [user, navigate, eventChatPath]);

  const isMeetingsCreationAllowedSelector = useSelector(
    isMeetingsCreationAllowed
  );

  const handleAddFavoriteClick = async () => {
    try {
      const data = {
        userId: user.id,
      };

      await addUserToFavorites(accountId, eventId, data);
      setIsFavourite(!isFavourite);
      dispatch(fetchEvent(accountId, eventId));
    } catch (err) {
      throw err;
    }
  };

  const handleRemoveFavoriteClick = async () => {
    try {
      await removeUserFromFavorites(accountId, eventId, user.id);
      setIsFavourite(!isFavourite);
      dispatch(fetchEvent(accountId, eventId));
    } catch (err) {
      throw err;
    }
  };

  const isProject = user?.type === "project";
  const cardHeaderSubtitle = isProject ? user?.category : user?.role;

  const isAllowedToRequestMeeting = () => {
    if (myUserEventProfile?.type === "project") {
      return user?.role === "Market Guest";
    } else {
      if (eventId === "industry-meet-poff-2024") {
        return user?.type === "project";
      }
    }

    return true;
  };

  const {
    isMatchingInterestsLoaded,
    matchingInterestsCount,
    userBusinessTagsWithMatchingInterests,
  } = useMatchingInterests(user);

  const interestTagsElement = useMemo(() => {
    const noInterestsText = isUserViewer
      ? "You have not selected any interests"
      : "You have no matching interests";

    const placeholderElement = isMatchingInterestsLoaded ? (
      <Typography.Paragraph type="secondary">
        {noInterestsText}
      </Typography.Paragraph>
    ) : (
      <Space>
        <Skeleton.Button shape="round" />
        <Skeleton.Button shape="round" />
        <Skeleton.Button shape="round" />
      </Space>
    );

    return [
      <Swipe
        fontSize={TypographySizes.XS}
        padding={token.paddingLG}
        style={{ marginBottom: token.marginLG }}
      >
        {(userBusinessTagsWithMatchingInterests.length > 0 &&
          userBusinessTagsWithMatchingInterests.map(
            ({ id, label }: InterestTag) => <span key={id}>{label}</span>
          )) ||
          placeholderElement}
      </Swipe>,
    ];
  }, [
    isMatchingInterestsLoaded,
    isUserViewer,
    userBusinessTagsWithMatchingInterests,
    token,
  ]);

  const chatButtonPlaceholderElement = <div />;
  const fontSize = token.sizeLG;
  const isEllipsisExpandable = true;
  const normalizeEllipsisOffsetCausedByAntdBug = isEllipsisExpandable ? 1 : 0;
  const linkedinPlaceholderElement = (
    <LinkedinFilled style={{ fontSize, visibility: "hidden" }} />
  );

  return (
    <StyledCard
      actions={!isLoading && !isProject && interestTagsElement}
      bordered={false}
      headStyle={{
        backgroundColor: token.colorPrimary,
        color: token.colorBgLayout,
      }}
      loading={isLoading}
      ref={ref}
      style={{ width: "100%" }}
      type="inner"
    >
      {isProject && (
        <CardHead justifyContent="flex-end" zIndex={9}>
          <ProjectButton onClick={onProjectButtonClick} />
        </CardHead>
      )}
      {user?.companyId && !isProject && (
        <CardHead justifyContent="flex-end">
          <BoothButton onClick={onBoothButtonClick} />
        </CardHead>
      )}
      {user?.role && (
        <CardHead justifyContent="flex-start">
          <Tag color={"#108ee9"}>{cardHeaderSubtitle}</Tag>
        </CardHead>
      )}
      <Space direction="vertical" size="large">
        {!isProject && (
          <StyledCardMeta
            avatar={
              <Avatar
                initials={formatUserInitials(user)}
                size={82}
                src={user.avatarUrl}
              />
            }
            description={
              isLoaded(user) ? (
                <Typography.Text
                  color={colorGraySemi}
                  size={TypographySizes.SM}
                  strong
                >
                  {formatUserCompanyNameWithJobTitle(user)}
                </Typography.Text>
              ) : (
                <Skeleton.Input active={true} size="small" />
              )
            }
            style={{ alignItems: "center", paddingTop: 15 }}
            title={
              isLoaded(user) ? (
                formatUserFullName(user)
              ) : (
                <Skeleton.Input active={true} size="small" />
              )
            }
          />
        )}
        {isProject && (
          <Image
            alt="Project image"
            src={user.avatarUrl}
            style={{
              width: "100%",
            }}
          />
        )}
        {isProject && (
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            {formatUserTypeProjectName(user)}
          </Typography.Title>
        )}
        {!isProject && (
          <Typography.Paragraph
            color={colorGraySemi}
            ellipsis={{
              expandable: isEllipsisExpandable,
              rows:
                (width > twoHundredCharacterDescriptionMaxWidth ? 3 : 4) +
                normalizeEllipsisOffsetCausedByAntdBug,
              symbol: "more",
            }}
            isEllipsisRowsVisible
            size={TypographySizes.SM}
          >
            {user.description}
          </Typography.Paragraph>
        )}
        {isProject && (
          // Wrap the following code in a div element
          <Space direction="vertical">
            <Typography.Text color={colorGraySemi} size={TypographySizes.SM}>
              Genre: {user.genres.join(", ")}
            </Typography.Text>
            <Typography.Text color={colorGraySemi} size={TypographySizes.SM}>
              Countries: {user.countries.join(", ")}
            </Typography.Text>
            <Typography.Text color={colorGraySemi} size={TypographySizes.SM}>
              Languages: {user.languages.join(", ")}
            </Typography.Text>
            <Typography.Text color={colorGraySemi} size={TypographySizes.SM}>
              Companies: {user.companies.join(", ")}
            </Typography.Text>
            <Typography.Text color={colorGraySemi} size={TypographySizes.SM}>
              Logline: {user.logline.join(", ")}
            </Typography.Text>
          </Space>
        )}
        <StyledButtonsContainer styledProps={{ gap: token.sizeSM }}>
          {!isUserViewer &&
            isMeetingsCreationAllowedSelector &&
            isAllowedToRequestMeeting() && (
              <Button
                block={!isDesktop}
                disabled={isSingleEventAccessAndOnboardingNotFinished}
                icon={<PlusOutlined />}
                onClick={handleProposeMeetingButtonClick}
                shape="round"
                size="large"
                type="primary"
              >
                Propose a meeting
              </Button>
            )}
          <Space
            size="middle"
            style={{
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            {!isUserViewer ? (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: colorGrayButtonBackground,
                    colorPrimaryHover: colorGrayButtonBackground,
                    colorTextLightSolid: token.colorText,
                  },
                }}
              >
                <Button
                  disabled={isSingleEventAccessAndOnboardingNotFinished}
                  icon={<MessageOutlined />}
                  onClick={handleStartChatButtonClick}
                  shape="round"
                  size="large"
                  type="primary"
                >
                  Chat
                </Button>
              </ConfigProvider>
            ) : (
              chatButtonPlaceholderElement
            )}
            <Space size="large">
              {user.linkedIn ? (
                <Link to={user.linkedIn}>
                  <LinkedinFilled style={{ fontSize }} />
                </Link>
              ) : (
                linkedinPlaceholderElement
              )}
              {!isUserViewer && !isProject && (
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  size="large"
                  onClick={
                    isFavourite
                      ? handleRemoveFavoriteClick
                      : handleAddFavoriteClick
                  }
                >
                  {isFavourite ? (
                    <StarFilled style={{ fontSize, color: "#FADB14" }} />
                  ) : (
                    <StarOutlined style={{ fontSize }} />
                  )}
                </Button>
              )}
            </Space>
          </Space>
        </StyledButtonsContainer>
        {!isUserViewer && !isProject && (
          <>
            <Divider style={{ margin: 0 }} />
            <Space>
              <Typography.Text
                color={colorGraySemi}
                size={TypographySizes.SM}
                strong
                style={{ marginBottom: -token.paddingXXS }}
              >
                {matchingInterestsCount > 0
                  ? `${matchingInterestsCount} mutual interests`
                  : "No mutual interests"}
              </Typography.Text>
              {matchingInterestsCount > 0 && (
                <MutualInterestsOutlined fill={token.colorPrimary} />
              )}
            </Space>
          </>
        )}
      </Space>
    </StyledCard>
  );
};

export default ProfileCard;
