import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Calendar, Col, Row, theme } from "antd";
import type { Dayjs } from "dayjs";
import { useCallback } from "react";

import {
  Button,
  Space,
  SpaceTypes,
  Typography,
  TypographySizes,
} from "components/antd";
import {
  getDateAtEndOfPreviousMonth,
  getDateAtStartOfNextMonth,
  getValidDates,
  isEqual,
} from "helpers";
import { dayFormat } from "helpers/constants";
import { styled } from "styles";
import type { StyledProps } from "styles";

import { useSelector } from "../../../../hooks";
import { selectEventProfile } from "../../../../store/selectors";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const calendarCellPadding = "4px 2px";

const StyledBackButton = styled(LeftCircleOutlined)(
  ({ styledProps: { color } }: StyledProps) => ({ color })
);

const StyledCalendarCellButton = styled(Button)({
  border: 0,
});

const StyledNextButton = styled(RightCircleOutlined)(
  ({ styledProps: { color } }: StyledProps) => ({ color })
);

const StyledCalendar = styled(Calendar)({
  "&.ant-picker-calendar": {
    ".ant-picker-content": {
      th: {
        padding: calendarCellPadding,
      },
      "td.ant-picker-cell": {
        padding: calendarCellPadding,
        ":is(.ant-picker-cell-disabled)": {
          ":before": {
            backgroundColor: "transparent",
          },
        },
        ":not(.ant-picker-cell-in-view)": {
          visibility: "hidden",
        },
        "> *": {
          transition: "none",
        },
      },
    },
  },
});

const StyledCalendarHeader = styled(Space)(
  ({ styledProps: { paddingX, marginBottom } }: StyledProps) => ({
    justifyContent: "space-between",
    paddingLeft: paddingX,
    paddingRight: paddingX,
    margin: "auto",
    marginBottom,
    userSelect: "none",
    width: "75%",
  })
);

type DateSelectorProps = {
  onSelect: (date: Dayjs) => void;
  validEndDate: Dayjs;
  validStartDate: Dayjs;
  value: Dayjs;
};

const DateSelector = ({
  onSelect,
  validEndDate,
  validStartDate,
  value,
}: DateSelectorProps) => {
  const { token } = theme.useToken();
  const authUserEventProfile = useSelector(selectEventProfile);

  const calendarHeaderElement = useCallback(
    ({ value: currentValue, onChange }) => {
      const nextMonthStartDate = getDateAtStartOfNextMonth(currentValue);
      const previousMonthEndDate = getDateAtEndOfPreviousMonth(currentValue);
      const selectedMonth = currentValue.month();
      const selectedYear = currentValue.year();
      const title = `${months[selectedMonth]} ${selectedYear}`;

      const isNextMonthButtonClickable = validEndDate >= nextMonthStartDate;
      const isPreviousMonthButtonClickable =
        validStartDate <= previousMonthEndDate;

      return (
        <StyledCalendarHeader
          styledProps={{
            paddingX: token.paddingXL,
            marginBottom: token.margin,
          }}
        >
          <Typography.Title level={4}>
            <StyledBackButton
              onClick={
                isPreviousMonthButtonClickable
                  ? () => onChange(previousMonthEndDate)
                  : undefined
              }
              styledProps={{
                color: !isPreviousMonthButtonClickable && token.colorBorder,
              }}
            />
          </Typography.Title>

          <Typography.Title level={4}>{title}</Typography.Title>
          <Typography.Title level={4}>
            <StyledNextButton
              onClick={
                isNextMonthButtonClickable
                  ? () => onChange(nextMonthStartDate)
                  : undefined
              }
              styledProps={{
                color: !isNextMonthButtonClickable && token.colorBorder,
              }}
            />
          </Typography.Title>
        </StyledCalendarHeader>
      );
    },
    [token, validEndDate, validStartDate]
  );

  return (
    <Space
      direction="vertical"
      size="large"
      type={SpaceTypes.SpaceBetween}
      style={{ textAlign: "center" }}
    >
      <Typography.Text size={TypographySizes.LG} strong>
        Select a Day
      </Typography.Text>

      <Row justify="center">
        <Col span={24} md={12}>
          <StyledCalendar
            dateFullCellRender={(date: Dayjs) => {
              const day = date.format(dayFormat);
              const { isDateInValidRange, isDateInPast } = getValidDates(
                date,
                [validStartDate, validEndDate],
                null
              );
              const isSelectedDate = isEqual(date, value);

              return isDateInValidRange ? (
                <StyledCalendarCellButton
                  block
                  disabled={isDateInPast}
                  type={isSelectedDate ? "primary" : "default"}
                >
                  {day}
                </StyledCalendarCellButton>
              ) : (
                <StyledCalendarCellButton block disabled ghost>
                  {day}
                </StyledCalendarCellButton>
              );
            }}
            fullscreen={false}
            headerRender={calendarHeaderElement}
            onSelect={(date: Dayjs) => onSelect(date)}
            validRange={[validStartDate, validEndDate]}
            value={value}
          />
        </Col>
      </Row>

      <div />
    </Space>
  );
};

export default DateSelector;
