import { Space as AntdSpace, theme } from "antd";
import type { SpaceProps as AntdSpaceProps } from "antd";
import type { SpaceSize as AntdSpaceSize } from "antd/lib/space";
import classNames from "classnames";

import { flexibleSpaceId } from "helpers/constants";
import { css } from "styles";

export enum SpaceTypes {
  Centered,
  FormContentAndButton,
  SpaceAround,
  SpaceBetween,
  SpaceEvenly,
  VerticalButtonGroup,
}

type SpaceDirection = "vertical" | "horizontal";

const centeredClassName = css({
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
});

const spaceAroundClassName = css({
  height: "100%",
  justifyContent: "space-around",
});

const spaceBetweenClassName = css({
  height: "100%",
  justifyContent: "space-between",
});

const spaceEvenlyClassName = css({
  height: "100%",
  justifyContent: "space-evenly",
});

type SpaceProps = AntdSpaceProps & {
  type?: SpaceTypes;
};

const Space = ({ type, ...restProps }: SpaceProps) => {
  const { token } = theme.useToken();

  const props = {
    ...restProps,
    ...(type === SpaceTypes.Centered && {
      className: classNames(centeredClassName, restProps.className),
    }),
    ...(type === SpaceTypes.FormContentAndButton && {
      direction: "vertical" as SpaceDirection,
      size: token.sizeXXL as AntdSpaceSize,
    }),
    ...(type === SpaceTypes.SpaceAround && {
      direction: "vertical" as SpaceDirection,
      className: classNames(spaceAroundClassName, restProps.className),
      id: flexibleSpaceId,
    }),
    ...(type === SpaceTypes.SpaceBetween && {
      direction: "vertical" as SpaceDirection,
      className: classNames(spaceBetweenClassName, restProps.className),
      id: flexibleSpaceId,
    }),
    ...(type === SpaceTypes.SpaceEvenly && {
      direction: "vertical" as SpaceDirection,
      className: classNames(spaceEvenlyClassName, restProps.className),
      id: flexibleSpaceId,
    }),
    ...(type === SpaceTypes.VerticalButtonGroup && {
      direction: "vertical" as SpaceDirection,
      size: "middle" as AntdSpaceSize,
    }),
  };

  return <AntdSpace {...props} />;
};

export default Space;
