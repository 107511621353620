import { Tag as AntdTag } from "antd";
import type { TagProps as AntdTagProps } from "antd";

import { styled } from "styles";
import { fontWeightMedium } from "styles/variables";

const StyledTag = styled(AntdTag)({
  fontWeight: fontWeightMedium,
  lineHeight: "25px",
  marginBottom: 8,
  marginRight: 8, // margin-inline-end not supported by some browsers
  padding: "1px 11px 2px", // padding-inline not supported by some browsers
});

export const Tag = (props: AntdTagProps) => <StyledTag {...props} />;
