import { useEffect, useState } from "react";

type DelayProps = {
  children: React.ReactNode;
  milliseconds: number;
  placeholder: React.ReactNode;
};

const Delay = ({ children, milliseconds, placeholder }: DelayProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, milliseconds);

    () => {
      clearTimeout(timeout);
    };
  }, [milliseconds]);

  return show ? <>{children}</> : <>{placeholder}</>;
};

export default Delay;
