import { FileImageOutlined } from "@ant-design/icons";
import { Col, Space, theme } from "antd";

import Loader from "components/Loader";
import {
  Card,
  StyledCardCoverPlaceholder,
  Typography,
  TypographySizes,
} from "components/antd";
import { ExternalOutlined } from "components/icons";
import { useBreakpoint } from "hooks";
import { useImagePreloader } from "hooks/useImagePreloader";
import type { CompanyProductProps } from "store";
import { boxShadowCard, styled } from "styles";
import type { StyledProps } from "styles";

import { logoSize } from ".";

const StyledCard = styled(Card)(
  ({ styledProps: { borderRadius } }: StyledProps) => ({
    boxShadow: `${boxShadowCard} !important`,
    ".ant-card-cover": {
      borderRadius,
      height: 200,
      overflow: "hidden",
      img: {
        height: "100%",
        objectFit: "cover",
      },
    },
  })
);

export const StyledCardPlaceholder = () => {
  const { token } = theme.useToken();

  return (
    <Col lg={12} span={24}>
      <StyledCard
        bordered={false}
        cover={<StyledCardCoverPlaceholder />}
        loading={true}
        styledProps={{ borderRadius: token.borderRadiusLG }}
      />
    </Col>
  );
};

const StyledTextWithMultilineEllipsis = styled(Typography.Text)(
  ({ styledProps: { isDesktop } }: StyledProps) => ({
    display: "-webkit-box",
    height: isDesktop && 40,
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": isDesktop ? "2" : "3",
  })
);

type ProductItemProps = {
  product: CompanyProductProps;
};

const ProductItem = ({ product }: ProductItemProps) => {
  const { isDesktop } = useBreakpoint();
  const { isImageError, isImageLoaded } = useImagePreloader(product.image);
  const { token } = theme.useToken();

  const imagePlaceholderElement = (
    <StyledCardCoverPlaceholder>
      {isImageError ? (
        <FileImageOutlined style={{ fontSize: logoSize / 2 }} />
      ) : (
        <Loader />
      )}
    </StyledCardCoverPlaceholder>
  );

  return (
    <StyledCard
      bordered={false}
      cover={
        isImageLoaded ? <img src={product.image} /> : imagePlaceholderElement
      }
      styledProps={{ borderRadius: token.borderRadiusLG }}
    >
      <Space direction="vertical">
        <Space>
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            {product.name}
          </Typography.Title>
          <ExternalOutlined />
        </Space>
        <StyledTextWithMultilineEllipsis
          size={TypographySizes.SM}
          styledProps={{ isDesktop }}
        >
          {product.description}
        </StyledTextWithMultilineEllipsis>
        {isDesktop && (
          <Typography.Text size={TypographySizes.SM} strong underline>
            Read more
          </Typography.Text>
        )}
      </Space>
    </StyledCard>
  );
};

export default ProductItem;
