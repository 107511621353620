import { FilterOutlined, StarOutlined } from "@ant-design/icons";
import { ConfigProvider, Space, theme } from "antd";

import Swipe from "components/Swipe";
import { TypographySizes } from "components/antd";
import { ExportOutlined } from "components/icons";
import { useBreakpoint, useSelector } from "hooks";
import { selectEventProfileIsSingleEventAccessAndOnboardingNotFinished } from "store/selectors";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { paddingContentHorizontalLG } from "styles/variables";

import FilterButton from "./FilterButton";
import Search, { searchButtonWidth } from "./Search";

export enum FilterValues {
  Matches = "matches",
  Favourites = "favourites",
}

const StyledSpace = styled(Space)(
  ({ styledProps: { isSeparated } }: StyledProps) =>
    isSeparated && {
      display: "flex",
      justifyContent: "space-between",
    }
);

type FiltersProps = {
  activeFilter: FilterValues;
  onChangeFilter: (filter: FilterValues) => void;
  onExportButtonClick: () => void;
  onNetworkingPreferencesButtonClick: () => void;
  onSearchKeywordChange: (value: string) => void;
  searchKeyword: string;
};

const Filters = ({
  activeFilter,
  onChangeFilter,
  onExportButtonClick,
  onNetworkingPreferencesButtonClick,
  onSearchKeywordChange,
  searchKeyword,
}: FiltersProps) => {
  const { isDesktop } = useBreakpoint();
  const isSingleEventAccessAndOnboardingNotFinished = useSelector(
    selectEventProfileIsSingleEventAccessAndOnboardingNotFinished
  );
  const { token } = theme.useToken();

  const isMatchesFilterActive =
    (activeFilter as FilterValues) === FilterValues.Matches;
  const isFavoritesFilterActive =
    (activeFilter as FilterValues) === FilterValues.Favourites;
  const isSearchExpandable = !isDesktop;

  const exportButtonElement = isFavoritesFilterActive &&
    !isSingleEventAccessAndOnboardingNotFinished && (
      <FilterButton
        icon={<ExportOutlined style={{ transform: "rotate(-90deg)" }} />}
        isActive={false}
        title="Export contacts"
        onClick={onExportButtonClick}
      />
    );

  const filterButtonsElement = (
    <>
      {Object.values(FilterValues).map((value, index) => (
        <FilterButton
          filter={value as FilterValues}
          icon={value === FilterValues.Favourites && <StarOutlined />}
          isActive={value === activeFilter}
          key={index}
          title={Object.keys(FilterValues)[index]}
          onClick={onChangeFilter}
        />
      ))}
    </>
  );

  const networkingPreferencesButtonElement = isMatchesFilterActive &&
    !isSingleEventAccessAndOnboardingNotFinished && (
      <FilterButton
        icon={<FilterOutlined />}
        isActive={false}
        title="Networking preferences"
        onClick={onNetworkingPreferencesButtonClick}
      />
    );

  const searchElement = (
    <Search
      isExpandable={isSearchExpandable}
      keyword={searchKeyword}
      onChange={(keyword: string) => onSearchKeywordChange(keyword)}
      onSearchKeywordChange={onSearchKeywordChange}
    />
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            fontSizeLG: TypographySizes.MD,
          },
        },
        token: {
          colorBgContainer: token.colorBgLayout,
        },
      }}
    >
      {isDesktop ? (
        <StyledSpace styledProps={{ isSeparated: isDesktop }}>
          <Space>{filterButtonsElement}</Space>

          <Space>
            {networkingPreferencesButtonElement}
            {exportButtonElement}
            {searchElement}
          </Space>
        </StyledSpace>
      ) : (
        <>
          <Swipe
            isSmoothGradient
            padding={paddingContentHorizontalLG}
            style={{
              marginLeft: -paddingContentHorizontalLG,
              width: `calc(100% - ${
                searchButtonWidth - paddingContentHorizontalLG
              }px)`,
            }}
          >
            <Space>
              {filterButtonsElement}
              {networkingPreferencesButtonElement}
              {exportButtonElement}
            </Space>
          </Swipe>

          {searchElement}
        </>
      )}
    </ConfigProvider>
  );
};

export default Filters;
