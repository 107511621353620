import { useState } from "react";

import ProfileCard from "components/ProfileCard";
import type { ProfileProps } from "store";

import CompanyWindow from "../CompanyWindow";
import ProjectWindow from "../ProjectWindow";
import NewMeetingWindow from "./NewMeetingWindow";

type ItemProps = {
  user: ProfileProps;
};

const Item = ({ user }: ItemProps) => {
  const [isCompanyWindowOpen, setIsCompanyWindowOpen] =
    useState<boolean>(false);
  const [isProjectWindowOpen, setIsProjectWindowOpen] =
    useState<boolean>(false);
  const [isNewMeetingWindowOpen, setIsNewMeetingWindowOpen] = useState(false);

  return (
    <>
      <ProfileCard
        key={user.id}
        onBoothButtonClick={() => setIsCompanyWindowOpen(true)}
        onProjectButtonClick={() => setIsProjectWindowOpen(true)}
        onProposeMeetingButtonClick={() => setIsNewMeetingWindowOpen(true)}
        user={user}
      />

      <CompanyWindow
        companyId={user?.companyId}
        isOpen={isCompanyWindowOpen}
        onClose={() => setIsCompanyWindowOpen(false)}
      />

      <ProjectWindow
        user={user}
        isOpen={isProjectWindowOpen}
        onClose={() => setIsProjectWindowOpen(false)}
      />

      <NewMeetingWindow
        isOpen={isNewMeetingWindowOpen}
        onClose={() => setIsNewMeetingWindowOpen(false)}
        user={user}
      />
    </>
  );
};

export default Item;
