import { Col, Row } from "antd";

import SinglePageLayout from "layouts/SinglePage";

import RegisterForm from "./Form";

export default () => (
  <SinglePageLayout
    isCentered
    title="Using Exponaut Matchmaking for the first time?"
  >
    <Row justify="center">
      <Col span={24} sm={16} md={12} lg={10} xl={8}>
        <RegisterForm />
      </Col>
    </Row>
  </SinglePageLayout>
);
