import axiosInstance from "../axiosInstance";
import { Company, Products } from "../models/Company";

export const getCompanies = async (): Promise<Company[]> => {
  const response = await axiosInstance.get("/companies");
  return response.data;
};

export const getCompany = async (companyId: string): Promise<Company> => {
  const response = await axiosInstance.get(`/companies/${companyId}`);
  return response.data;
};

export const getCompanyProducts = async (
  companyExternalId: string
): Promise<Products> => {
  const response = await axiosInstance.get(
    `/companies/${companyExternalId}/products`
  );
  return response.data;
};
