import { Button as AntdButton, ConfigProvider, theme } from "antd";
import type { ButtonProps as AntdButtonProps } from "antd";
import classNames from "classnames";
import hexToRgba from "hex-to-rgba";
import { cloneElement, forwardRef } from "react";

import { css } from "styles";

type CustomButtonProps = {
  color?: string;
  isPadded?: boolean;
};

type StyledButtonProps = CustomButtonProps & {
  element: React.ReactElement;
};

const StyledButton = ({ color, element, isPadded }: StyledButtonProps) => {
  const { token } = theme.useToken();
  const colorPrimary = color || token.colorPrimary;
  const colorSecondary = color || token.colorTextBase;

  const isGhost = !!element.props.ghost;
  const isTypeSecondary = element.props.type === "secondary";

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            ...(color && {
              colorPrimary,
              colorPrimaryActive: colorPrimary,
              colorPrimaryHover: colorPrimary,
            }),
            ...(isGhost && {
              ghostBg: token.colorTextBase,
              colorPrimaryActive: token.colorBgLayout,
              colorPrimaryHover: token.colorBgLayout,
            }),
            ...(isTypeSecondary && {
              colorPrimary: hexToRgba(colorSecondary, 0.05),
              colorPrimaryActive: hexToRgba(colorSecondary, 0.2),
              colorPrimaryHover: hexToRgba(colorSecondary, 0.1),
              colorTextLightSolid: colorSecondary,
            }),
          },
        },
      }}
    >
      {cloneElement(element, {
        ...element.props,
        ...(isTypeSecondary && { type: "primary" }),
        className: classNames(
          css({
            "&.ant-btn": {
              ...(isGhost && {
                "&.ant-btn-background-ghost": {
                  "&:active, &:hover": { borderColor: "transparent" },
                  "&:not(:active):not(:hover)": {
                    backgroundColor: "transparent",
                  },
                },
              }),
              ...(isPadded && {
                paddingInlineEnd: token.paddingXL,
                paddingInlineStart: token.paddingXL,
              }),
            },
          }),
          element.props.className
        ),
      })}
    </ConfigProvider>
  );
};

type ButtonProps = (AntdButtonProps | any) & CustomButtonProps;

type CompoundedComponent = React.ForwardRefExoticComponent<
  ButtonProps & React.RefAttributes<JSX.Element>
>;

const Button = forwardRef((props: ButtonProps, ref) => (
  <StyledButton element={<AntdButton {...props} ref={ref} />} {...props} />
)) as CompoundedComponent;

export default Button;
