import { SearchOutlined } from "@ant-design/icons";
import { theme } from "antd";
import { useEffect, useRef, useState } from "react";

import { Button, Input } from "components/antd";
import { useBreakpoint } from "hooks";
import { styled } from "styles";
import type { StyledProps } from "styles";
import {
  animationDuration,
  colorGraySemi,
  inputControlPaddingHorizontal,
  inputHeightLG as searchButtonWidth,
} from "styles/variables";

export { inputHeightLG as searchButtonWidth } from "styles/variables";

const isAllowClearEnabled = true;
const expandableInitialWidth =
  searchButtonWidth +
  inputControlPaddingHorizontal * 2 +
  (isAllowClearEnabled ? 15 : 0);

const StyledButton = styled(Button)({
  float: "right",
  pointerEvents: "auto",
});

const StyledSearchInput = styled(Input.Search)({
  ".ant-input-group": {
    ".ant-input-affix-wrapper:not(:last-child)": {
      borderRightWidth: 0,
    },
    ".ant-input-group-addon:last-child": {
      insetInlineStart: 0,
    },
  },
});

const StyledWrapper = styled.div(
  ({
    styledProps: { isAnimationEnabled, pointerEvents, position, width, zIndex },
  }: StyledProps) => ({
    pointerEvents,
    position,
    right: 0,
    overflow: "hidden",
    top: 0,
    width,
    zIndex,
    ...(isAnimationEnabled && {
      transition: `width ${animationDuration}s`,
    }),
  })
);

type SearchProps = {
  isExpandable: boolean;
  keyword: string;
  onChange: (keyword: string) => void;
  onSearchKeywordChange: (value: string) => void;
};

const Search = ({
  isExpandable,
  keyword,
  onChange,
  onSearchKeywordChange,
}: SearchProps) => {
  const { isDesktop } = useBreakpoint();
  const [isExpanded, setIsExpanded] = useState(false);
  const inputRef = useRef(null);
  const { token } = theme.useToken();

  const isSearchInputVisible = !isExpandable || (isExpandable && isExpanded);
  const expandableWidth = isExpanded ? "100%" : expandableInitialWidth;

  useEffect(
    function handleResize() {
      isDesktop && setIsExpanded(false);
    },
    [isDesktop]
  );

  const handleSearch = (value: string) => {
    onChange(value);
    inputRef.current?.blur();
    isExpandable && setIsExpanded(false);
  };

  return (
    <StyledWrapper
      styledProps={{
        isAnimationEnabled: isExpandable,
        pointerEvents: !isSearchInputVisible ? "none" : "auto",
        position: isExpandable ? "absolute" : "relative",
        width: isExpandable ? expandableWidth : 330,
        zIndex: token.zIndexBase + 3,
      }}
    >
      {isSearchInputVisible ? (
        <StyledSearchInput
          allowClear={isAllowClearEnabled}
          autoFocus={isExpandable}
          defaultValue={keyword}
          onBlur={() => setIsExpanded(false)}
          onChange={(e: any) => onSearchKeywordChange(e.target.value)}
          onSearch={handleSearch}
          placeholder="Search by company or name..."
          ref={inputRef}
          size="large"
        />
      ) : (
        <StyledButton
          color={colorGraySemi}
          icon={<SearchOutlined />}
          onClick={() => setIsExpanded(true)}
          shape="circle"
          size="large"
          type="secondary"
        />
      )}
    </StyledWrapper>
  );
};

export default Search;
