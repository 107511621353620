import countryCodes from "country-calling-code";
import _ from "lodash";

import { Input, InputAppearances } from "components/antd";

const defaultSelectedValue = "+372";

const getFlagEmoji = (countryCode: any) =>
  String.fromCodePoint(
    ...[...countryCode.toUpperCase()].map((x) => 0x1f1a5 + x.charCodeAt())
  );

type CountryCodeSearchInputProps = {
  onChange: (value: string) => void;
  selectedValue: string;
};

const CountryCodeSearchInput = ({
  onChange,
  selectedValue = defaultSelectedValue,
}: CountryCodeSearchInputProps) => {
  const options = countryCodes.flatMap(
    ({ country, countryCodes: callingCodes, isoCode2 }) =>
      callingCodes.map((callingCode) => {
        const prefixedCallingCode = `+${callingCode}`;

        return {
          value: prefixedCallingCode,
          label: `${getFlagEmoji(isoCode2)} ${prefixedCallingCode}`,
          searchPhrase: `${prefixedCallingCode} ${country}`,
        };
      })
  );

  return (
    <Input.Select
      appearance={InputAppearances.Underline}
      filterOption={(input: any, option: any) =>
        (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
      }
      filterSort={(optionA: any, optionB: any) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      options={options}
      onChange={onChange}
      showSearch
      value={_.find(options, ({ value }) => value === selectedValue)}
    />
  );
};

export default CountryCodeSearchInput;
