import { Button, Col, Form, Row, Space, theme } from "antd";
import _ from "lodash";
import { useState } from "react";

import { getEventByCode } from "api/resources/events";
import { Input, InputAppearances, Typography } from "components/antd";
import { TicketOutlined } from "components/icons";
import { useSelector } from "hooks";
import { selectJoinedEvents } from "store/selectors";
import { styled } from "styles";
import type { StyledProps } from "styles";

const CLOSE_ICON_WIDTH_WITH_MARGIN_INLINE_START = 12 + 4;

const StyledFormItem = styled(Form.Item)({
  marginBottom: 100,
  marginTop: 100,
  ".ant-form-item-explain-error": {
    textAlign: "center",
  },
});

const StyledInput = styled(Input)(
  ({ styledProps: { isClearingAllowed } }: StyledProps) =>
    isClearingAllowed
      ? {
          input: {
            marginLeft: CLOSE_ICON_WIDTH_WITH_MARGIN_INLINE_START,
            textAlign: "center",
          },
        }
      : {
          textAlign: "center",
        }
);

const StyledRow = styled(Row)({
  justifyContent: "center",
  paddingBottom: 50,
  paddingTop: 50,
});

type EnterCodeProps = {
  inputRef: any;
  setSelectedEvent: (value: any) => void;
  setIsConfirmationModalOpen: (value: boolean) => void;
  setIsEnterCodeWindowOpen: (value: boolean) => void;
  ticketUrl: string;
};

const EnterCode = ({
  inputRef,
  setSelectedEvent,
  setIsConfirmationModalOpen,
  setIsEnterCodeWindowOpen,
  ticketUrl,
}: EnterCodeProps) => {
  const [form] = Form.useForm();
  const joinedEvents = useSelector(selectJoinedEvents);
  const [isAlreadyJoinedEvent, setIsAlreadyJoinedEvent] = useState(false);
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = theme.useToken();

  const onCodeSubmit = (code: string) => {
    setIsLoading(true);

    // TODO: Combine this API call with public event joining API call
    getEventByCode(code)
      .then(({ event }: any) => {
        setIsLoading(false);

        if (event !== null) {
          const isJoinedEvent = !!_.find(
            joinedEvents,
            (joinedEvent) => joinedEvent.id === event.id
          );

          if (isJoinedEvent) {
            setIsAlreadyJoinedEvent(true);
            form.validateFields();
          } else {
            setSelectedEvent({
              ...event,
              path: event.alias,
            });

            setIsEnterCodeWindowOpen(false);
            setIsConfirmationModalOpen(true);
          }
        } else {
          setIsInvalidCode(true);
          form.validateFields();
        }
      })
      .catch(() => {
        setIsLoading(false);

        setIsInvalidCode(true);
        form.validateFields();

        setIsConfirmationModalOpen(false);
      });
  };

  const validateCode = (rule: any, value: string, callback: any) => {
    if (isAlreadyJoinedEvent) {
      callback("You have already joined event with this code.");
    } else if (isInvalidCode) {
      callback("Please enter a valid code.");
    }
  };

  const isClearingAllowed = true;

  return (
    <StyledRow>
      <Col span={18} sm={14} md={12}>
        <Space direction="vertical">
          <Form
            form={form}
            onValuesChange={({ code }) => {
              if (code.length === 4) {
                onCodeSubmit(code);
              } else {
                setIsAlreadyJoinedEvent(false);
                setIsInvalidCode(false);
              }
            }}
          >
            <StyledFormItem
              name="code"
              rules={[
                {
                  message: "Please insert access code",
                  required: true,
                },
                { validator: validateCode },
              ]}
            >
              <StyledInput
                allowClear={isClearingAllowed}
                appearance={InputAppearances.Underline}
                autoFocus
                disabled={isLoading}
                maxLength={4}
                placeholder="Enter event code"
                ref={inputRef}
                size="large"
                styledProps={{ isClearingAllowed }}
              />
            </StyledFormItem>
          </Form>
          {ticketUrl && (
            <Space direction="vertical" style={{ textAlign: "center" }}>
              <Typography>Don’t have the event ticket yet?</Typography>
              <Button
                icon={<TicketOutlined fill={token.colorText} />}
                shape="round"
                type="primary"
              >
                Buy ticket
              </Button>
            </Space>
          )}
        </Space>
      </Col>
    </StyledRow>
  );
};

export default EnterCode;
