import type { TabsProps } from "antd";

import ChatBox from "components/ChatBox";
import TabSwitcher from "components/TabSwitcher";
import { useBreakpoint } from "hooks";

const Chat = () => {
  const { isDesktop } = useBreakpoint();

  const tabItems: TabsProps["items"] = [
    {
      key: "chat",
      label: <>Chat</>,
    },
  ];

  return (
    <>
      {isDesktop && (
        <TabSwitcher
          activeKey={"chat"}
          items={tabItems}
          onChange={() => console.log("hey")}
        />
      )}
      <ChatBox />
    </>
  );
};

export default Chat;
