import axios from "axios";
import firebase from "firebase/compat/app";

const axiosInstance = (() => {
  const getAuthToken = async () => {
    // Get your Firebase Token to use in Postman
    // console.log(await firebase.auth().currentUser?.getIdToken());

    try {
      return "Bearer " + (await firebase.auth().currentUser?.getIdToken());

      const token = await firebase.auth().currentUser?.getIdToken();
      console.log(token);
    } catch (err) {}
  };

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  instance.interceptors.request.use(async (config) => {
    config.headers.Authorization = await getAuthToken();
    return config;
  });

  return instance;
})();

export default axiosInstance;
