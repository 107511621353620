import { useEffect, useRef } from "react";

export const useEffectOnValueChange = (callback: any, value: any) => {
  const previousValue = useRef(value);

  useEffect(() => {
    if (value !== undefined && value !== previousValue.current) {
      previousValue.current = value;

      callback();
    }
  }, [callback, previousValue, value]);
};
