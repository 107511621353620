import { EditOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, theme } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { serverTimestamp } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { fetchUserDeviceToken } from "api/resources/users";
import ErrorField from "components/ErrorField";
import {
  Input,
  InputAppearances,
  Link,
  Space,
  SpaceTypes,
  Typography,
  TypographySizes,
} from "components/antd";
import sendEmail from "database/handles/sendEmail";
import { formatNotEmptyString } from "helpers";
import { validateName, validatePassword } from "helpers/validators";
import { useFirebase, useNavigateToPath, useSelector } from "hooks";
import { ProtectedPaths, UnprotectedPaths } from "routes";
import { getFirebaseToken } from "store/selectors";

enum FieldValues {
  Email = "email",
  Password = "password",
  PasswordConfirm = "password-confirm",
  FirstName = "first-name",
  LastName = "last-name",
}

const RegisterForm = () => {
  const firebase = useFirebase();
  const location = useLocation();
  const navigateToEntry = useNavigateToPath(UnprotectedPaths.Entry, {
    replace: true,
  });
  const navigateToEvents = useNavigateToPath(ProtectedPaths.Events);
  const firebaseToken = useSelector(getFirebaseToken);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const { token } = theme.useToken();
  const isPasswordStep = useMemo(
    () => activeStepIndex === 0,
    [activeStepIndex]
  );
  const emailFromPreviousStep = location.state?.email;
  const navigateToEntryWithEmail = useNavigateToPath(UnprotectedPaths.Entry, {
    state: { email: emailFromPreviousStep },
  });

  useEffect(
    function navigateDirectLinkToEntry() {
      !emailFromPreviousStep && navigateToEntry();
    },
    [emailFromPreviousStep, navigateToEntry]
  );

  const onSavePassword = useCallback((value) => {
    setPassword(value);
    setActiveStepIndex(1);
  }, []);

  const onCheckPassword = useCallback(
    (values) =>
      values[FieldValues.Password] === values[FieldValues.PasswordConfirm]
        ? onSavePassword(values[FieldValues.Password])
        : setErrorMessage("Passwords are not identical"),
    [onSavePassword]
  );

  const onSubmit = useCallback(
    async (values) => {
      setDisabled(true);

      await firebase
        .createUser(
          { email: emailFromPreviousStep, password },
          {
            first_name: values[FieldValues.FirstName],
            last_name: values[FieldValues.LastName],
            email: emailFromPreviousStep,
            created_at: serverTimestamp(),
          }
        )
        .then(async (user) => {
          const emailData = {
            firstName: values[FieldValues.FirstName],
          };

          if (firebaseToken) {
            await fetchUserDeviceToken(user.uid, firebaseToken);
          }

          sendEmail(
            emailFromPreviousStep,
            emailData,
            "d-9ea2cd82e87041e18ef4120dbb7089f2"
          );

          navigateToEvents(); // TODO: Navigate to confirmation page
        })
        .catch((error) => {
          setErrorMessage(error.code + ": " + error.message);
        });
    },
    [emailFromPreviousStep, firebase, firebaseToken, navigateToEvents, password]
  );

  const onStepBack = useCallback(() => setActiveStepIndex(0), []);

  const truncate = (string: string) => {
    if (string.length > 36) {
      return string.substring(0, 36) + "...";
    }
    return string;
  };

  return (
    <Form
      disabled={disabled}
      onFinish={isPasswordStep ? onCheckPassword : onSubmit}
      size="large"
    >
      <Space direction="vertical" size="large">
        <Space type={SpaceTypes.FormContentAndButton}>
          {isPasswordStep && emailFromPreviousStep ? (
            <div>
              <Typography.Text size={TypographySizes.LG}>
                Please create a password for your new account
              </Typography.Text>
              <br />
              <Typography.Text strong size={TypographySizes.LG}>
                {truncate(emailFromPreviousStep)}{" "}
                <EditOutlined
                  onClick={navigateToEntryWithEmail}
                  style={{
                    display: "inline",
                    color: token.colorPrimary,
                    cursor: "pointer",
                  }}
                />
              </Typography.Text>
            </div>
          ) : (
            <Typography.Text size={TypographySizes.LG}>
              To create an account please insert your name
            </Typography.Text>
          )}

          <Space direction="vertical">
            {isPasswordStep ? (
              <>
                <Form.Item
                  name={FieldValues.Password}
                  rules={[
                    {
                      message: "Enter password to continue",
                      required: true,
                    },
                    { validator: validatePassword },
                  ]}
                >
                  <Input.Password
                    appearance={InputAppearances.Underline}
                    autoFocus
                    data-qa="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item
                  name={FieldValues.PasswordConfirm}
                  dependencies={["password"]}
                  rules={[
                    {
                      message: "Confirm your password to continue",
                      required: true,
                    },
                    ({ getFieldValue }) => ({
                      validator: (rule: any, value: string, callback: any) => {
                        const isNotEmptyValue =
                          formatNotEmptyString(value) !== undefined;

                        if (
                          isNotEmptyValue &&
                          getFieldValue("password") !== value
                        ) {
                          setTimeout(() => {
                            callback("Passwords must match");
                          }, 1000);
                        } else {
                          callback();
                        }
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    appearance={InputAppearances.Underline}
                    data-qa="confirm-password"
                    placeholder="Confirm password"
                  />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  name={FieldValues.FirstName}
                  rules={[
                    {
                      message: "Enter first name to continue",
                      required: true,
                    },
                    { validator: validateName },
                  ]}
                >
                  <Input
                    appearance={InputAppearances.Underline}
                    autoFocus
                    data-qa="first-name"
                    placeholder="First name"
                  />
                </Form.Item>
                <Form.Item
                  name={FieldValues.LastName}
                  rules={[
                    {
                      message: "Enter last name to continue",
                      required: true,
                    },
                    { validator: validateName },
                  ]}
                >
                  <Input
                    appearance={InputAppearances.Underline}
                    data-qa="last-name"
                    placeholder="Last name"
                  />
                </Form.Item>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (rule: any, value: string) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                "Please read and accept the T&C and Privacy Policy"
                              )
                            ),
                    },
                  ]}
                >
                  <Checkbox
                    onChange={(event: CheckboxChangeEvent) => {
                      setIsAgreementChecked(event.target.checked);
                    }}
                    style={{ textAlign: "left" }}
                    value={isAgreementChecked}
                  >
                    I have read and agree to the{" "}
                    <Link to="https://www.exponaut.me/terms-and-conditions">
                      Terms & Conditions
                    </Link>{" "}
                    and{" "}
                    <Link to="https://www.exponaut.me/privacy-policy">
                      Privacy Policy
                    </Link>
                  </Checkbox>
                </Form.Item>
              </>
            )}

            {errorMessage && <ErrorField>{errorMessage}</ErrorField>}
          </Space>

          {isPasswordStep ? (
            <Space
              direction="vertical"
              style={{
                justifyContent: "center",
              }}
            >
              <Button
                block
                htmlType="submit"
                shape="round"
                size="middle"
                type="primary"
              >
                Set password
              </Button>
            </Space>
          ) : (
            <Space type={SpaceTypes.VerticalButtonGroup}>
              <Button
                block
                data-qa="submit"
                htmlType="submit"
                shape="round"
                size="middle"
                type="primary"
              >
                Create user
              </Button>
              <Button
                block
                ghost
                onClick={onStepBack}
                shape="round"
                size="middle"
              >
                Edit password
              </Button>
            </Space>
          )}
        </Space>
      </Space>
    </Form>
  );
};

export default RegisterForm;
