import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getEventUser } from "api/resources/users";
import { UncontrolledWindow } from "components/Window";
import { isEmpty, isLoaded, isObject } from "helpers";
import { twoHundredCharacterDescriptionMaxWidth } from "helpers/constants";
import { formatDataToProfileProps } from "helpers/store";
import { useBreakpoint, useSelector } from "hooks";
import LoadingPage from "pages/Loading";
import type { ProfileProps } from "store";
import { selectAccountId, selectEvent } from "store/selectors";
import CompanyWindow from "views/CompanyWindow";

import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";

export type ParamsProps = {
  eventId: string;
  targetUserId: string;
};

const ProfileBadge = () => {
  const { isDesktop } = useBreakpoint();
  const { eventId, targetUserId } = useParams<ParamsProps>();
  const accountId = useSelector(selectAccountId);
  const event = useSelector(selectEvent);
  const [isCompanyWindowOpen, setIsCompanyWindowOpen] =
    useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isInstructionsSent, setIsInstructionsSent] = useState<boolean>(false);
  const [targetProfile, setTargetProfile] = useState<ProfileProps>();

  const isViewerTargetUser = accountId === targetUserId;
  const isViewerJoinedEvent = !isEmpty(event);

  useEffect(() => {
    const fetchTargetUser = async () => {
      try {
        const { profile, ...user } = await getEventUser(targetUserId, eventId);

        setTargetProfile(formatDataToProfileProps(user, profile));
      } catch (error) {
        setIsError(true);
      }
    };

    fetchTargetUser();
  }, [eventId, targetUserId]);

  const isFooterVisible =
    !isError && !isInstructionsSent && !isViewerTargetUser;
  const isHeaderVisible = !isError && !isInstructionsSent;

  return (isError || isObject(targetProfile)) && isLoaded(event) ? (
    <>
      <UncontrolledWindow
        childrenKey={isInstructionsSent ? 1 : 0}
        footerElement={
          isFooterVisible && (
            <Footer
              isViewerJoinedEvent={isViewerJoinedEvent}
              user={targetProfile}
            />
          )
        }
        headerElement={
          isHeaderVisible && (
            <Header
              isViewerTargetUser={isViewerTargetUser}
              onInstructionsSent={() => setIsInstructionsSent(true)}
              user={targetProfile}
            />
          )
        }
        isCloseButtonVisible={false}
        isFullPage={!isDesktop}
        onAfterClose={null}
        onClose={null}
        width={twoHundredCharacterDescriptionMaxWidth}
      >
        <Content
          isError={isError}
          isInstructionsSent={isInstructionsSent}
          onCompanyLinkClick={() => setIsCompanyWindowOpen(true)}
          user={targetProfile}
        />
      </UncontrolledWindow>

      <CompanyWindow
        companyId={targetProfile.companyId}
        isMaskVisible={false}
        isOpen={isCompanyWindowOpen}
        onClose={() => setIsCompanyWindowOpen(false)}
      />
    </>
  ) : (
    <LoadingPage />
  );
};

export default ProfileBadge;
