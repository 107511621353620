import { LoadingOutlined } from "@ant-design/icons";
import { ConfigProvider, Spin, theme } from "antd";

import { styled } from "styles";
import type { StyledProps } from "styles";

const StyledIndicator = styled(LoadingOutlined)(
  ({ styledProps: { opacity } }: StyledProps) => ({
    opacity,
  })
);

const StyledSpin = styled(Spin)({
  "&.ant-spin": {
    ".ant-spin-text": { textShadow: "none !important" },
  },
});

type LoaderProps = {
  isIndicatorVisible?: boolean;
  text?: string;
};

const Loader = ({ isIndicatorVisible = true, text }: LoaderProps) => {
  const { token } = theme.useToken();

  const textProps = {
    children: <div />,
    tip: text,
  };

  return (
    <ConfigProvider theme={{ token: { colorPrimary: token.colorText } }}>
      <StyledSpin
        indicator={
          <StyledIndicator
            spin
            styledProps={{ opacity: isIndicatorVisible ? 1 : 0 }}
          />
        }
        size="large"
        {...(text && textProps)}
      />
    </ConfigProvider>
  );
};

export default Loader;
