import { Button } from "antd";

import { useIsPathActive } from "hooks";
import { ProtectedPaths } from "routes";

type ActionsProps = {
  onSave: () => void;
};

const Actions = ({ onSave }: ActionsProps) => {
  const isEventOnboardingPathActive = useIsPathActive(
    ProtectedPaths.EventOnboarding
  );

  return (
    <Button shape="round" onClick={onSave} type="primary">
      {isEventOnboardingPathActive ? "Next" : "Save"}
    </Button>
  );
};

export default Actions;
