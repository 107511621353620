import classNames from "classnames";

import { colorIconFill, colorWhite } from "styles/variables";

import type { OutlinedIcon } from ".";

const mutualInterestsOutlinedClipPathId = "MutualInterestsOutlined";

export const MutualInterestsOutlined = ({
  className,
  fill = colorIconFill,
  height = 16,
  style,
  width = 16,
}: OutlinedIcon) => (
  <svg
    className={classNames("anticon", className)}
    fill="none"
    height={height}
    style={style}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath={`url(#${mutualInterestsOutlinedClipPathId})`} fill={fill}>
      <path d="M8 15.5C7.645 15.5 7.305 15.385 7.017 15.165L6.165 14.507C5.525 14.007 4.886 13.497 4.29 13.006L3.921 12.698C2.878 11.827 2.162 11.171 1.733 10.69C0.535 9.342 0 7.858 0 5.877C0 2.912 2.177 0.5 4.852 0.5C6.01 0.5 7.131 0.962 8 1.784C8.869 0.962 9.99 0.5 11.147 0.5C13.823 0.5 16 2.912 16 5.877C16 7.858 15.465 9.342 14.267 10.689C13.841 11.168 13.125 11.825 12.079 12.7L11.722 12.997C11.115 13.497 10.476 14.007 9.837 14.505L8.993 15.158C8.70902 15.3788 8.35974 15.4991 8 15.5ZM4.852 1.5C2.728 1.5 1 3.463 1 5.877C1 7.595 1.457 8.874 2.48 10.025C2.875 10.467 3.556 11.091 4.562 11.93L4.929 12.236C5.516 12.719 6.149 13.223 6.781 13.717L7.627 14.371C7.73582 14.4546 7.8695 14.4993 8.00671 14.498C8.14392 14.4968 8.27675 14.4496 8.384 14.364L9.224 13.715C9.85041 13.2263 10.4708 12.7299 11.085 12.226L11.439 11.931C12.447 11.089 13.127 10.465 13.519 10.025C14.543 8.874 15 7.595 15 5.877C15 3.463 13.271 1.5 11.147 1.5C10.091 1.5 9.109 1.974 8.381 2.833L8 3.284L7.618 2.833C6.891 1.974 5.908 1.5 4.852 1.5Z" />
      <path d="M11.4888 7H4.50977C4.37716 7 4.24998 6.94732 4.15621 6.85355C4.06244 6.75979 4.00977 6.63261 4.00977 6.5C4.00977 6.36739 4.06244 6.24021 4.15621 6.14645C4.24998 6.05268 4.37716 6 4.50977 6H11.4888C11.6214 6 11.7486 6.05268 11.8423 6.14645C11.9361 6.24021 11.9888 6.36739 11.9888 6.5C11.9888 6.63261 11.9361 6.75979 11.8423 6.85355C11.7486 6.94732 11.6214 7 11.4888 7Z" />
      <path d="M11.4883 6.89601C11.4161 6.89609 11.3447 6.88048 11.2791 6.85026C11.2135 6.82005 11.1552 6.77595 11.1083 6.72101L9.85831 5.26301C9.77211 5.16223 9.72948 5.03134 9.7398 4.89912C9.75011 4.76691 9.81252 4.64421 9.91331 4.55801C10.0141 4.47182 10.145 4.42919 10.2772 4.4395C10.4094 4.44982 10.5321 4.51223 10.6183 4.61301L11.8683 6.07101C11.9304 6.14365 11.9704 6.23257 11.9836 6.32725C11.9967 6.42192 11.9825 6.51838 11.9425 6.60519C11.9025 6.69201 11.8385 6.76554 11.758 6.81709C11.6775 6.86863 11.5839 6.89602 11.4883 6.89601V6.89601Z" />
      <path d="M11.4888 9H4.50977C4.37716 9 4.24998 8.94732 4.15621 8.85355C4.06244 8.75979 4.00977 8.63261 4.00977 8.5C4.00977 8.36739 4.06244 8.24021 4.15621 8.14645C4.24998 8.05268 4.37716 8 4.50977 8H11.4888C11.6214 8 11.7486 8.05268 11.8423 8.14645C11.9361 8.24021 11.9888 8.36739 11.9888 8.5C11.9888 8.63261 11.9361 8.75979 11.8423 8.85355C11.7486 8.94732 11.6214 9 11.4888 9Z" />
      <path d="M5.75979 10.562C5.68757 10.562 5.61621 10.5464 5.55061 10.5162C5.48501 10.486 5.42674 10.4419 5.37979 10.387L4.12979 8.92903C4.0436 8.82825 4.00097 8.69735 4.01128 8.56514C4.0216 8.43293 4.08401 8.31023 4.18479 8.22403C4.28557 8.13783 4.41647 8.09521 4.54868 8.10552C4.6809 8.11583 4.8036 8.17825 4.88979 8.27903L6.13979 9.73703C6.20192 9.80967 6.24193 9.89859 6.25507 9.99326C6.26822 10.0879 6.25395 10.1844 6.21397 10.2712C6.17398 10.358 6.10994 10.4316 6.02945 10.4831C5.94896 10.5346 5.85537 10.562 5.75979 10.562Z" />
    </g>
    <clipPath id={mutualInterestsOutlinedClipPathId}>
      <rect width={width} height={height} fill={colorWhite} />
    </clipPath>
  </svg>
);
