import { CheckCircleFilled } from "@ant-design/icons";
import { theme } from "antd";
import { useRef, useState } from "react";

import { isValue } from "helpers";
import { useOnMount } from "hooks";
import type { StyledProps } from "styles";
import { styled } from "styles";
import { animationDuration } from "styles/variables";

const StyledIcon = styled(CheckCircleFilled)(
  ({ styledProps: { backgroundColor, color, iconSize } }: StyledProps) => ({
    backgroundColor,
    borderRadius: "50%",
    color,
    fontSize: iconSize,
    position: "absolute",
    right: -(iconSize / 2),
    top: -(iconSize / 2),
  })
);

const StyledOverlay = styled.div(
  ({
    styledProps: {
      borderRadius,
      boxShadow,
      isSelected,
      outlineColor,
      outlineWidth,
    },
  }: StyledProps) => ({
    borderRadius,
    boxShadow,
    cursor: "pointer",
    height: "100%",
    outlineColor,
    outlineOffset: 0,
    outlineStyle: "solid",
    outlineWidth: 0,
    overflow: "hidden",
    position: "absolute",
    top: 0,
    transition: `${animationDuration}s all`,
    width: "100%",
    ...(isSelected && {
      outlineOffset: -(outlineWidth / 2),
      outlineWidth,
    }),
  })
);

const StyledWrapper = styled.div({
  position: "relative",
  userSelect: "none",
});

type SelectableProps = {
  boxShadow?: string;
  children: React.ReactNode;
  isSelected: boolean;
  onClick: () => void;
};

const Selectable = ({
  boxShadow,
  children,
  isSelected,
  onClick,
}: SelectableProps) => {
  const wrapperRef = useRef<any>(null);
  const [borderRadius, setBorderRadius] = useState<number>();
  const { token } = theme.useToken();

  useOnMount(function setBorderRadiusBasedOnFirstChildElement() {
    if (wrapperRef.current) {
      const { borderRadius: firstChildBorderRadius } = getComputedStyle(
        wrapperRef.current.firstChild
      );

      setBorderRadius(parseInt(firstChildBorderRadius));
    }
  });

  return (
    <StyledWrapper onClick={onClick} ref={wrapperRef}>
      {children}

      <StyledOverlay
        styledProps={{
          borderRadius,
          boxShadow,
          isSelected: isSelected && isValue(borderRadius),
          outlineColor: token.colorPrimary,
          outlineWidth: token.lineWidthFocus,
        }}
      />

      {isSelected && (
        <StyledIcon
          styledProps={{
            backgroundColor: token.colorBgContainer,
            color: token.colorPrimary,
            iconSize: token.sizeXL,
          }}
        />
      )}
    </StyledWrapper>
  );
};

export default Selectable;
