import { Col, Row } from "antd";
import { useEffect } from "react";

import { Link } from "components/antd";
import { isLoaded } from "helpers";
import { useBreakpoint, useDispatch, useSelector } from "hooks";
import { CompanyProductProps } from "store";
import { fetchCompanyProducts } from "store/appSlice";
import { selectEventCompanyProducts } from "store/selectors";

import ProductItem from "./ProductItem";
import { StyledCardPlaceholder } from "./ProductItem";

type CompanyProductsProps = {
  companyExternalId: string;
};

export const Products = ({ companyExternalId }: CompanyProductsProps) => {
  const { isDesktop } = useBreakpoint();
  const dispatch = useDispatch();
  const eventCompanyProducts = useSelector(selectEventCompanyProducts);

  useEffect(() => {
    dispatch(fetchCompanyProducts(companyExternalId));
  }, [dispatch, companyExternalId]);

  const loaderElement = isDesktop ? (
    <>
      <StyledCardPlaceholder />
      <StyledCardPlaceholder />
    </>
  ) : (
    <StyledCardPlaceholder />
  );

  return (
    <Row gutter={[20, 20]}>
      {isLoaded(eventCompanyProducts)
        ? eventCompanyProducts.map((product: CompanyProductProps, index) => (
            <Col key={index} lg={12} span={24}>
              <Link to={product.url}>
                <ProductItem product={product} />
              </Link>
            </Col>
          ))
        : loaderElement}
    </Row>
  );
};
