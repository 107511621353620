import { Button, Form, Space } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import ErrorField from "components/ErrorField";
import Shake from "components/Shake";
import {
  Animate,
  Input,
  Link,
  Typography,
  TypographySizes,
} from "components/antd";
import { validateEmail, validatePassword } from "helpers/validators";
import {
  useFirebase,
  useNavigateToPath,
  useReturnPath,
  useSelector,
} from "hooks";
import { ProtectedPaths, UnprotectedPaths } from "routes";

import { fetchUserDeviceToken } from "../../../api/resources/users";
import { getFirebaseToken, selectAccountId } from "../../../store/selectors";

enum FieldValues {
  Email = "email",
  Password = "password",
}

const LoginForm = () => {
  const firebase = useFirebase();
  const location = useLocation();
  const navigateToEntry = useNavigateToPath(UnprotectedPaths.Entry, {
    replace: true,
  });
  const navigateToEvents = useNavigateToPath(ProtectedPaths.Events);
  const returnPath = useReturnPath();
  const firebaseToken = useSelector(getFirebaseToken);
  const accountId = useSelector(selectAccountId);
  const [disabled, setDisabled] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [isShaking, setIsShaking] = useState(false);

  const emailFromPreviousStep = location.state?.email;

  useEffect(
    function navigateDirectLinkToEntry() {
      !emailFromPreviousStep && navigateToEntry();
    },
    [emailFromPreviousStep, navigateToEntry]
  );

  const errorMessages = {
    "auth/invalid-email": (
      <>
        Incorrect email.{" "}
        <Link to={UnprotectedPaths.Entry}>Click here to create an account</Link>
      </>
    ),
    "auth/user-not-found": (
      <>
        Incorrect email.{" "}
        <Link to={UnprotectedPaths.Entry}>Click here to create an account</Link>
      </>
    ),
    "auth/too-many-requests": "Too many login attempts, please wait",
    "auth/wrong-password": (
      <>
        Incorrect password.{" "}
        <Link
          state={{ email: emailFromPreviousStep, returnPath }}
          to={UnprotectedPaths.ResetPassword}
        >
          Click here to reset your password
        </Link>
      </>
    ),
  };

  const clearErrorCodeOnValueChange = () => {
    setErrorCode("");
  };

  const onSubmit = async (values: any) => {
    setDisabled(true);
    setIsShaking(false);

    await firebase
      .login({
        email: values[FieldValues.Email],
        password: values[FieldValues.Password],
      })
      .then(() => {
        if (firebaseToken) {
          fetchUserDeviceToken(accountId, firebaseToken);
        }
        navigateToEvents();
      })
      .catch((error) => {
        setDisabled(false);
        setErrorCode(error.code);
        setIsShaking(true);
      });
  };

  return (
    <Form
      disabled={disabled}
      onFinish={onSubmit}
      onValuesChange={clearErrorCodeOnValueChange}
      size="large"
    >
      <Space direction="vertical">
        <Typography.Text size={TypographySizes.LG}>
          To enter matchmaking please insert your password
        </Typography.Text>

        <Form.Item
          hasFeedback
          initialValue={emailFromPreviousStep}
          name={FieldValues.Email}
          rules={[
            {
              message: "Enter your email to continue",
              required: true,
            },
            { validator: validateEmail },
          ]}
        >
          <Input
            autocapitalize="off"
            autocorrect="off"
            data-qa="email"
            placeholder="Email"
          />
        </Form.Item>

        <Animate
          elements={[
            <>
              <Form.Item name={FieldValues.Password}>
                <Input.Password
                  autoFocus
                  data-qa="password"
                  placeholder="Password"
                />
              </Form.Item>
            </>,
            <Shake active={isShaking}>
              <Button
                block
                data-qa="submit"
                htmlType="submit"
                shape="round"
                type="primary"
              >
                Log in
              </Button>
            </Shake>,
          ]}
        />
      </Space>
    </Form>
  );
};

export default LoginForm;
