import { Timeline as AntdTimeline, Space, Typography } from "antd";
import dayjs from "dayjs";

import { useBreakpoint } from "hooks";
import type { MeetingProps } from "store";
import { bodyTextBig, styled } from "styles";
import type { StyledProps } from "styles";

import Date from "./Date";
import Item, { MEETING_DURATION_IN_MINUTES } from "./Item";

const item = {
  content: {
    spacing: {
      new: 25,
      old: 0,
    },
  },
  head: {
    height: 10,
  },
  tail: {
    offset: {
      top: 18,
      bottom: -50,
    },
  },
};

const StyledTimeline = styled(AntdTimeline)(
  ({ styledProps: { contentWidth } }: StyledProps) => ({
    ".ant-timeline-item": {
      display: "flex",
      paddingBottom: item.content.spacing.old,
      ".ant-timeline-item-content": {
        insetInlineStart: `calc(${100 - contentWidth}% - 4px) !important`,
        width: `calc(${contentWidth}% - 12px) !important`,
        "> div": {
          marginBottom: item.content.spacing.new,
          marginTop: item.content.spacing.new,
        },
      },
      ".ant-timeline-item-head": {
        insetBlockStart: `calc(50% - ${item.head.height}px)`,
        insetInlineStart: `${100 - contentWidth}%`,
      },
      ".ant-timeline-item-label": {
        insetBlockStart: "calc(50% - 15px)",
        width: `calc(${100 - contentWidth}% - 12px)`,
      },
      ".ant-timeline-item-tail": {
        height: "100%",
        insetBlockStart: item.tail.offset.top,
        insetInlineStart: `${100 - contentWidth}%`,
      },
      "&.ant-timeline-item-last > .ant-timeline-item-tail": {
        display: "block",
        height: `calc(100% + ${item.tail.offset.bottom}px)`,
      },
    },
  })
);

const StyledLabel = styled(Typography.Text)({
  ...bodyTextBig,
});

const getDateItem = (time: any) => {
  const day = dayjs(time.toDate()).format("DD");
  const month = dayjs(time.toDate()).format("MMMM");
  const weekday = dayjs(time.toDate()).format("dddd");

  return {
    children: "",
    dot: <></>,
    label: <Date day={day} month={month} weekday={weekday} />,
  };
};

const getMeetingItem = (
  meeting: MeetingProps,
  time: any,
  talkPopup: any,
  setTalkPopup: any,
  talkSession: any
) => {
  const startTime = dayjs(time.toDate()).format("HH:mm");
  const endTime = dayjs(time.toDate())
    .add(MEETING_DURATION_IN_MINUTES, "minutes")
    .format("HH:mm");

  return {
    children: (
      <Item
        key={meeting.id}
        meeting={meeting}
        setTalkPopup={setTalkPopup}
        talkPopup={talkPopup}
        talkSession={talkSession}
      />
    ),
    color: "gray",
    label: (
      <StyledLabel strong type="secondary">
        {`${startTime}-${endTime}`}
      </StyledLabel>
    ),
  };
};

type TimelineProps = {
  meetings: MeetingProps[];
  talkPopup: any;
  setTalkPopup: any;
  talkSession: any;
};

const Timeline = ({
  meetings,
  talkPopup,
  setTalkPopup,
  talkSession,
}: TimelineProps) => {
  const { isDesktop } = useBreakpoint();

  const items = meetings.flatMap((meeting: MeetingProps, index: number) => {
    const previousMeeting = meetings[index - 1];
    const showDate =
      index === 0 ||
      dayjs(previousMeeting?.proposed_time.toDate()).format("YYMMDD") <
        dayjs(meeting.proposed_time.toDate()).format("YYMMDD");

    const dateItem = getDateItem(meeting.proposed_time);
    const meetingItem = getMeetingItem(
      meeting,
      meeting.proposed_time,
      talkPopup,
      setTalkPopup,
      talkSession
    );

    return showDate ? [dateItem, meetingItem] : meetingItem;
  });

  return isDesktop ? (
    <StyledTimeline
      items={items}
      mode="left"
      styledProps={{ contentWidth: 80 }}
    />
  ) : (
    <Space direction="vertical" size="middle">
      {items.map(({ children, label }, index) => (
        <Space key={"Timeline-" + index} direction="vertical">
          {label}
          {children}
        </Space>
      ))}
    </Space>
  );
};

export default Timeline;
