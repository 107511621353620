import { useState } from "react";

import { Input, InputAppearances } from "components/antd";
import { formatNotEmptyString } from "helpers";
import type { CompanyProps } from "store";
import { styled } from "styles";

const StyledInputSelect = styled(Input.Select)({
  ".ant-select-selection-placeholder": {
    whiteSpace: "initial",
  },
});

type CompanySearchInputProps = {
  onChange: (value: string) => void;
  options: CompanyProps[];
  value: string;
};

const CompanySearchInput = ({
  onChange,
  options,
  value,
}: CompanySearchInputProps) => {
  const [searchValue, setSearchValue] = useState("");

  const formatOptions = (data: CompanyProps[]) =>
    data.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));

  return (
    <StyledInputSelect
      allowClear
      appearance={InputAppearances.Underline}
      filterOption={(input: any, option: any) =>
        (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
      }
      filterSort={(optionA: any, optionB: any) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      optionFilterProp="children"
      options={[
        ...(searchValue.length > 2
          ? [
              {
                label:
                  "By clicking on a choice below your profile will display the chosen company exhibitor booth and added products",
                options: formatOptions(options),
              },
            ]
          : []),
        ...(searchValue.length > 0
          ? [
              {
                label: "To add custom company name click on the choice below",
                options: [{ label: searchValue, value: searchValue }],
              },
            ]
          : [
              {
                label: "Type to add company name",
                options: [],
              },
            ]),
      ]}
      defaultValue={formatNotEmptyString(value)}
      onChange={onChange}
      onSearch={setSearchValue}
      placeholder="Your Company / Organization"
      showSearch
    />
  );
};

export default CompanySearchInput;
