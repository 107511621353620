export const getPreviousEnumValue = (Enum: any, value: any) => {
  const enumKeys = Object.values(Enum);
  const currentEnumValueIndex = enumKeys.indexOf(Enum[value]);
  const previousEnumValue = enumKeys[currentEnumValueIndex - 1] as typeof Enum;

  return Enum[previousEnumValue];
};

export const getEnumValues = (Enum: any) => Object.values(Enum);

export enum MeetingTypes {
  InPerson = "In-person",
  Virtual = "Virtual",
}
