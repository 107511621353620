import { LinkedinFilled } from "@ant-design/icons";
import { Capacitor } from "@capacitor/core";
import { Button, Divider, Form, Space } from "antd";
import { useEffect, useState } from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useLocation, useNavigate } from "react-router-dom";

import {
  checkUserEmailExists,
  requestPasswordResetLink,
  verifyLinkedinUser,
} from "api/resources/users";
import ErrorField from "components/ErrorField";
import { Input, Typography, TypographySizes } from "components/antd";
import { validateEmail } from "helpers/validators";
import {
  useFirebase,
  useFocusOnTransitionEnd,
  useNavigateToPath,
  useReturnPath,
} from "hooks";
import { ProtectedPaths, UnprotectedPaths } from "routes";
import { fontWeightRegular } from "styles/variables";

enum FieldValues {
  Email = "email",
}

const AuthenticationForm = () => {
  const firebase = useFirebase();
  const focusRef = useFocusOnTransitionEnd();
  const location = useLocation();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const emailFromPreviousStep = location.state?.email;
  const navigateToEvents = useNavigateToPath(ProtectedPaths.Events);
  const returnPath = useReturnPath();
  const [errorMessage] = useState("");

  useEffect(
    function removeEmailFromLocationState() {
      emailFromPreviousStep && navigate(".", { replace: true });
    },
    [emailFromPreviousStep, navigate]
  );

  const checkIfEmailExists = async (email: string) => {
    const {
      eventName,
      exists: isEmailRegistered,
      singleEventAccess: isSingleEventAccess,
    } = await checkUserEmailExists(encodeURIComponent(email.toLowerCase()));

    return {
      eventName,
      isEmailRegistered,
      isSingleEventAccess,
    };
  };

  const onSubmit = async (values: { [key: string]: string }) => {
    setDisabled(true);

    const { eventName, isEmailRegistered, isSingleEventAccess } =
      await checkIfEmailExists(values[FieldValues.Email].toLowerCase());

    if (isSingleEventAccess) {
      await requestPasswordResetLink({
        email: values[FieldValues.Email].toLowerCase(),
      });

      navigate(`/${UnprotectedPaths.PasswordlessLogin}`, {
        state: {
          email: values[FieldValues.Email].toLowerCase(),
          eventName,
          returnPath,
        },
      });
    } else if (isEmailRegistered) {
      navigate(`/${UnprotectedPaths.Login}`, {
        state: {
          email: values[FieldValues.Email].toLowerCase(),
          returnPath,
        },
      });
    } else {
      navigate(`/${UnprotectedPaths.Register}`, {
        state: {
          email: values[FieldValues.Email].toLowerCase(),
          returnPath,
        },
      });
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "email,profile,openid",
    onSuccess: async (code: string) => {
      const response = await verifyLinkedinUser(code);
      await firebase
        .auth()
        .signInWithCustomToken(response.firebaseToken)
        .then(navigateToEvents);
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  return (
    <Form onFinish={onSubmit} size="large">
      <Space direction="vertical">
        <Typography.Text size={TypographySizes.LG}>
          To enter matchmaking please insert your email
        </Typography.Text>

        <div>
          <Form.Item
            hasFeedback
            initialValue={emailFromPreviousStep}
            name={FieldValues.Email}
            rules={[
              {
                message: "Enter your email to continue",
                required: true,
              },
              { validator: validateEmail },
            ]}
          >
            <Input
              autocapitalize="off"
              autocorrect="off"
              placeholder="Enter your e-mail"
              ref={focusRef}
              data-qa="email"
            />
          </Form.Item>

          {errorMessage && <ErrorField>{errorMessage}</ErrorField>}
        </div>

        <Button
          block
          disabled={disabled}
          htmlType="submit"
          shape="round"
          type="primary"
        >
          Continue with e-mail
        </Button>
        {typeof Capacitor === "undefined" && (
          <>
            <Divider style={{ fontWeight: fontWeightRegular }}>or</Divider>
            <Button
              block
              disabled={disabled}
              shape="round"
              type="default"
              icon={<LinkedinFilled style={{ color: "#003EB3" }} />}
              onClick={linkedInLogin}
            >
              Continue with LinkedIn
            </Button>
          </>
        )}
      </Space>
    </Form>
  );
};

export default AuthenticationForm;
