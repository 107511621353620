import { Space } from "antd";
import QueueAnim from "rc-queue-anim";
import React from "react";

class SpaceComponent extends React.Component {
  render() {
    return <Space direction="vertical">{this.props.children}</Space>;
  }
}

type AnimateProps = {
  disabled?: boolean;
  elements: React.ReactElement[];
};

export const Animate = ({ disabled, elements }: AnimateProps) => (
  <QueueAnim
    appear={!disabled}
    duration={1000}
    interval={300}
    type="bottom"
    component={SpaceComponent}
  >
    {elements.map((element, index) => (
      <div key={index}>{element}</div>
    ))}
  </QueueAnim>
);
