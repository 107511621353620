import { LeftOutlined, RocketOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd";
import { useNavigate } from "react-router-dom";

import { Layout, Typography } from "components/antd";
import { useNavigateToPath, useSelector } from "hooks";
import { UnprotectedPaths } from "routes";
import { selectAuthUserType } from "store/selectors";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const navigateToEntry = useNavigateToPath(UnprotectedPaths.Entry);

  const { isAuthenticatedUser } = useSelector(selectAuthUserType);

  return (
    <Layout
      isFullHeight
      style={{
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Row justify="center">
        <Col span={20}>
          <Space direction="vertical" size="middle">
            <Typography.Title>
              404. Looks like you’re lost in space.
            </Typography.Title>
            {isAuthenticatedUser ? (
              <>
                <Typography.Title level={3}>
                  Let’s get you back on track.
                </Typography.Title>
                <Button
                  icon={<LeftOutlined />}
                  onClick={() => navigate(-1)}
                  shape="round"
                  size="large"
                  type="primary"
                >
                  Go back
                </Button>
                <Button
                  ghost
                  icon={<RocketOutlined />}
                  onClick={navigateToEntry}
                  shape="round"
                  size="large"
                  type="primary"
                >
                  Navigate to home
                </Button>
              </>
            ) : (
              <>
                <Typography.Title level={3}>
                  To continue, please log back in.
                </Typography.Title>
                <Button
                  icon={<RocketOutlined />}
                  onClick={navigateToEntry}
                  shape="round"
                  size="large"
                  type="primary"
                >
                  Log in
                </Button>
              </>
            )}
          </Space>
        </Col>
      </Row>
    </Layout>
  );
};

export default NotFoundPage;
