import {
  CommentOutlined,
  HomeOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu as AntdMenu, ConfigProvider, Skeleton, Space, theme } from "antd";
import hexToRgba from "hex-to-rgba";
import { useLocation, useNavigate } from "react-router-dom";

import { isValue } from "helpers";
import { usePath, useSelector } from "hooks";
import { ProtectedPaths } from "routes";
import {
  selectEventProfileIsOnboardingFinished,
  selectEventProfileIsSingleEventAccessAndOnboardingNotFinished,
} from "store/selectors";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { opacityMenuItemActive } from "styles/variables";

import OnboardingMenu from "./OnboardingMenu";
import SupportMenu from "./SupportMenu";

export const StyledMenu = styled(AntdMenu)(
  ({ styledProps: { borderRadius } }: StyledProps) => ({
    borderRadius,
    borderInlineEnd: "0 !important",
  })
);

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const eventChatPath = usePath(ProtectedPaths.EventChat);
  const eventConnectPath = usePath(ProtectedPaths.EventConnect);
  const eventHomePath = usePath(ProtectedPaths.EventHome);
  const eventMeetingsPath = usePath(ProtectedPaths.EventMeetings);
  const eventProfilePath = usePath(ProtectedPaths.EventProfile);
  const isOnboardingFinished = useSelector(
    selectEventProfileIsOnboardingFinished
  );
  const isSingleEventAccessAndOnboardingNotFinished = useSelector(
    selectEventProfileIsSingleEventAccessAndOnboardingNotFinished
  );
  const { token } = theme.useToken();

  const handleClick: MenuProps["onClick"] = ({ key }) => {
    navigate(key);
  };

  const mainMenuItems: MenuProps["items"] = [
    {
      icon: <HomeOutlined />,
      key: eventHomePath,
      label: "Home",
    },
    {
      icon: <CommentOutlined />,
      key: eventChatPath,
      label: "Chat",
    },
    {
      icon: <IdcardOutlined />,
      key: eventProfilePath,
      label: "Profile",
    },
  ];

  const onboardingMainMenuItems = isSingleEventAccessAndOnboardingNotFinished
    ? [mainMenuItems[0]]
    : [];

  const items = isOnboardingFinished ? mainMenuItems : onboardingMainMenuItems;

  const isActivePathEventHomeAlias = [
    eventConnectPath,
    eventMeetingsPath,
  ].includes(location.pathname);

  const selectedKeys = isActivePathEventHomeAlias
    ? [eventHomePath]
    : items
        .map((item) => item.key)
        .filter((key: any) => location.pathname.includes(key)); // TODO: Replace with a universal method of removing params from pathname

  return isValue(isOnboardingFinished) ? (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: token.colorText,
          colorPrimaryBg: hexToRgba(token.colorTextBase, opacityMenuItemActive),
        },
      }}
    >
      <StyledMenu
        items={items}
        mode="inline"
        onClick={handleClick}
        selectedKeys={selectedKeys}
        styledProps={{ borderRadius: token.borderRadius }}
      />
      {!isOnboardingFinished && <OnboardingMenu />}
      <SupportMenu />
    </ConfigProvider>
  ) : (
    <Space direction="vertical" size={4} style={{ margin: 4 }}>
      <Skeleton.Input block size="large" />
      <Skeleton.Input block size="large" />
      <Skeleton.Input block size="large" />
      <Skeleton.Input block size="large" />
    </Space>
  );
};

export default Menu;
