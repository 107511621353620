import classNames from "classnames";

import { colorIconFill } from "styles/variables";

import type { FilledIcon } from ".";

export const MailFilled = ({
  className,
  fill = colorIconFill,
  height = 18,
  style,
  width = 22,
}: FilledIcon) => (
  <svg
    className={classNames("anticon", className)}
    fill="none"
    height={height}
    style={style}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.59124 8.68573L1.61745 3.17026C2.49601 1.7941 4.0752 0.873047 5.85457 0.873047H16.9756C18.755 0.873047 20.3231 1.78326 21.2127 3.15942L13.2612 8.66406C12.138 9.39006 10.6922 9.39006 9.59124 8.68573ZM21.9579 5.31576L14.529 10.452C13.5726 11.0696 12.4827 11.373 11.404 11.373C10.3252 11.373 9.26873 11.0696 8.33457 10.4628L0.87234 5.31576C0.861219 5.45663 0.850098 5.60833 0.850098 5.7492V12.2507C0.850098 14.938 3.09655 17.1269 5.85457 17.1269H16.9756C19.7336 17.1269 21.9801 14.938 21.9801 12.2507V5.7492C21.9801 5.60833 21.969 5.45663 21.9579 5.31576Z"
      fill={fill}
    />
  </svg>
);
