import {
  borderRadius,
  colorDark,
  colorError,
  colorGray,
  colorGrayLight,
  colorInfo,
  colorPrimary,
  colorSuccess,
  colorWarning,
  colorWhite,
  fontFamily,
  fontSizeHeading1,
  fontSizeHeading2,
  fontSizeHeading3,
  fontSizeHeading4,
  fontSizeHeading5,
  fontSizeXS,
  paddingContentHorizontalLG,
  tagBorderRadiusSM,
} from "styles/variables";

export const defaultThemeToken = {
  borderRadius,
  colorBgLayout: colorGrayLight,
  colorError,
  colorInfo,
  colorLink: colorDark,
  colorLinkActive: colorDark,
  colorLinkHover: colorPrimary,
  colorPrimary,
  colorSuccess,
  colorWarning,
  controlOutlineWidth: 0,
  fontFamily,
  fontSizeHeading1,
  fontSizeHeading2,
  fontSizeHeading3,
  fontSizeHeading4,
  fontSizeHeading5,
  lineWidthFocus: 5,
  paddingContentHorizontalLG,
};

export const lightThemeToken = {
  ...defaultThemeToken,
};

export const darkThemeToken = {
  ...defaultThemeToken,
  colorBgContainer: colorWhite,
  colorBgContainerDisabled: colorDark,
  colorBgElevated: colorDark,
  colorBgLayout: colorDark,
  colorBgSpotlight: colorWhite,
  colorLink: colorPrimary,
  colorLinkActive: colorPrimary,
  colorLinkHover: colorWhite,
  colorTextBase: colorWhite,
};

export const defaultThemeComponents = {
  Tag: {
    borderRadiusSM: tagBorderRadiusSM,
    colorText: colorDark,
  },
  Tooltip: {
    fontSize: fontSizeXS,
  },
};

export const lightThemeComponents = {
  Layout: {
    colorBgHeader: lightThemeToken.colorBgLayout,
  },
  Progress: {
    colorInfo: lightThemeToken.colorPrimary,
  },
  Tag: defaultThemeComponents.Tag,
  Tooltip: defaultThemeComponents.Tooltip,
};

export const darkThemeComponents = {
  Button: {
    colorText: darkThemeToken.colorBgLayout,
  },
  Card: {
    colorBgContainer: darkThemeToken.colorBgLayout,
  },
  Divider: {
    colorSplit: darkThemeToken.colorTextBase,
  },
  Input: {
    colorText: darkThemeToken.colorBgLayout,
    colorTextPlaceholder: colorGray,
    colorIcon: "inherit",
    colorIconHover: "inherit",
  },
  Tag: defaultThemeComponents.Tag,
  Tooltip: {
    ...defaultThemeComponents.Tooltip,
    colorTextLightSolid: darkThemeToken.colorBgLayout,
  },
};
