import { MenuOutlined } from "@ant-design/icons";
import { theme } from "antd";
import { useState } from "react";

import Window from "components/Window";
import { Button } from "components/antd";
import { styled } from "styles";
import General from "views/General";

const StyledButton = styled(Button)({
  height: "100%",
});

const GeneralButton = () => {
  const [isGeneralOpen, setIsGeneralOpen] = useState(false);
  const { token } = theme.useToken();

  return (
    <>
      <StyledButton onClick={() => setIsGeneralOpen(true)} type="link">
        <MenuOutlined style={{ fontSize: token.sizeMD }} />
      </StyledButton>

      <Window isOpen={isGeneralOpen} onClose={() => setIsGeneralOpen(false)}>
        <General onLinkClick={() => setIsGeneralOpen(false)} />
      </Window>
    </>
  );
};

export default GeneralButton;
