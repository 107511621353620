import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import type { RelativeRoutingType } from "react-router-dom";

import { RouteTransitionContext } from "components/RouteTransition";
import { UnprotectedPaths, getIsEventPath } from "routes";
import type { ArrayWithPathAndFilters, Path } from "routes";

const getComposedPathname = (
  path: Path | ArrayWithPathAndFilters,
  params: {
    eventId?: string;
  }
) => {
  const isArrayWithPathAndFilters = Array.isArray(path);
  const isEventPath = getIsEventPath(
    isArrayWithPathAndFilters ? (path[0] as Path) : path
  );

  const ArrayWithPathAndFilters = isArrayWithPathAndFilters
    ? path.join("/")
    : path;

  return isEventPath && params?.eventId
    ? `/${params.eventId}/${ArrayWithPathAndFilters}`
    : `/${ArrayWithPathAndFilters}`;
};

export const useIsPathActive = (path: Path) => {
  const location = useLocation();
  const { eventId: eventIdFromActiveRoute } = useParams();

  const composedPathname = getComposedPathname(path, {
    eventId: eventIdFromActiveRoute,
  });

  const comparedPath =
    path === UnprotectedPaths.Entry
      ? location.pathname
      : location.pathname.slice(0, composedPathname.length);

  return comparedPath === composedPathname;
};

export const usePath = (
  path: Path | ArrayWithPathAndFilters,
  params?: {
    eventId?: string;
  }
) => {
  const { eventId: eventIdFromActiveRoute } = useParams();

  const isArrayWithPathAndFilters = Array.isArray(path);
  const isEventPath = getIsEventPath(
    isArrayWithPathAndFilters ? (path[0] as Path) : path
  );

  const composedPathname = getComposedPathname(
    path,
    isEventPath && {
      eventId: params?.eventId || eventIdFromActiveRoute,
    }
  );

  return composedPathname;
};

export const useNavigateToPath = (
  path: Path | ArrayWithPathAndFilters,
  options?: {
    replace?: boolean;
    state?: any;
    relative?: RelativeRoutingType;
  }
) => {
  const navigate = useNavigate();
  const pathname = usePath(path);

  return () => navigate(pathname, options);
};

export const useNavigateToReturnPath = () => {
  const { isTransitionExiting } = useContext(RouteTransitionContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [isWaitingForTransition, setIsWaitingForTransition] =
    useState<boolean>(false);
  const [isVisibleForTransition, setIsVisibleForTransition] =
    useState<boolean>(false);

  const returnPath = location.state?.returnPath || [];
  const nextPath = returnPath[0] || location.pathname;
  const isNextPathNotCurrentPath = nextPath !== location.pathname;

  useEffect(() => {
    isNextPathNotCurrentPath && setIsWaitingForTransition(true);
  }, [isNextPathNotCurrentPath]);

  useEffect(() => {
    isWaitingForTransition &&
      isTransitionExiting &&
      setIsVisibleForTransition(true);
  }, [isTransitionExiting, isWaitingForTransition]);

  return isNextPathNotCurrentPath || isVisibleForTransition
    ? () =>
        navigate(nextPath, {
          state: { returnPath: returnPath.slice(1) },
        })
    : null;
};

export const useLocationState = () => {
  const location = useLocation();

  return location.state || {};
};

export const useReturnPath = () => {
  const location = useLocation();
  const returnPath = location.state?.returnPath || [];

  return [location.pathname, ...returnPath];
};
