import { useContext } from "react";

import { WindowContext } from "components/Window";

const FeedbackPage = () => {
  const { height } = useContext(WindowContext);

  return (
    <div style={{ height, overflow: "hidden" }}>
      <iframe
        height="100%"
        width="100%"
        src="https://form.asana.com/?k=xxfHEzUWg0Bz-s6CKfQwhg&d=1203885620969316&embed=true"
      />
    </div>
  );
};

export default FeedbackPage;
