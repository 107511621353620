import { InfoCircleFilled } from "@ant-design/icons";
import type { TabsProps } from "antd";
import { Alert, Badge, theme } from "antd";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { fetchUserDeviceToken } from "api/resources/users";
import TabSwitcher from "components/TabSwitcher";
import { Button, Typography } from "components/antd";
import { MeetingsProvider } from "database/providers";
import {
  useBreakpoint,
  useInterestTags,
  useIsPathActive,
  useNavigateToPath,
  usePath,
  useSelector,
} from "hooks";
import { ProtectedPaths } from "routes";
import {
  getFirebaseToken,
  selectAccountId,
  selectEventProfileIsOnboardingFinished,
  selectEventProfileIsSingleEventAccess,
  selectEventProfileIsSingleEventAccessAndOnboardingNotFinished,
  selectMeetingRequestsReceivedCount,
} from "store/selectors";
import Connect from "views/Connect";
import Meetings from "views/Meetings";

type HomeProps = {
  children?: React.ReactNode;
};

const Home = ({ children }: HomeProps) => {
  const { isDesktop } = useBreakpoint();
  useInterestTags();
  const isEventOnboardingPathActive = useIsPathActive(
    ProtectedPaths.EventOnboarding
  );
  const location = useLocation();
  const navigate = useNavigate();
  const navigateToEventOnboarding = useNavigateToPath(
    ProtectedPaths.EventOnboarding
  );
  const connectPath = usePath(ProtectedPaths.EventConnect);
  const meetingsPath = usePath(ProtectedPaths.EventMeetings);
  const firebaseToken = useSelector(getFirebaseToken);
  const accountId = useSelector(selectAccountId);
  const isOnboardingFinished = useSelector(
    selectEventProfileIsOnboardingFinished
  );
  const isSingleEventAccess = useSelector(
    selectEventProfileIsSingleEventAccess
  );
  const isSingleEventAccessAndOnboardingNotFinished = useSelector(
    selectEventProfileIsSingleEventAccessAndOnboardingNotFinished
  );
  const meetingRequestsReceivedCount = useSelector(
    selectMeetingRequestsReceivedCount
  );
  const { token } = theme.useToken();

  useEffect(() => {
    if (firebaseToken) {
      fetchUserDeviceToken(accountId, firebaseToken);
    }
    if (
      !isEventOnboardingPathActive &&
      !isOnboardingFinished &&
      !isSingleEventAccess
    ) {
      navigateToEventOnboarding();
    }
  }, [
    accountId,
    firebaseToken,
    isEventOnboardingPathActive,
    isOnboardingFinished,
    isSingleEventAccess,
    navigateToEventOnboarding,
  ]);

  const activeKey = location.pathname.includes(connectPath)
    ? connectPath
    : meetingsPath; // TODO: Replace with a universal method of removing params from pathname

  const defaultView = meetingRequestsReceivedCount
    ? {
        component: <Meetings />,
        key: meetingsPath,
      }
    : {
        component: <Connect />,
        key: connectPath,
      };

  const tabItems: TabsProps["items"] = [
    {
      key: meetingsPath,
      label: (
        <>
          My meetings{" "}
          <Badge
            count={meetingRequestsReceivedCount}
            style={{ bottom: 5, position: "absolute", right: -15 }}
          />
        </>
      ),
    },
    {
      key: connectPath,
      label: <>Connect with others</>,
    },
  ];

  if (isOnboardingFinished || isSingleEventAccessAndOnboardingNotFinished) {
    return (
      <>
        {isDesktop && !isSingleEventAccessAndOnboardingNotFinished && (
          <TabSwitcher
            activeKey={children ? activeKey : defaultView.key}
            items={tabItems}
            onChange={(key: string) => navigate(key)}
          />
        )}

        {isSingleEventAccessAndOnboardingNotFinished && (
          <Alert
            action={
              <Button
                danger
                shape="round"
                size="middle"
                onClick={navigateToEventOnboarding}
              >
                Finish your profile
              </Button>
            }
            description={
              <>
                To <Typography.Text strong>propose meetings</Typography.Text>{" "}
                and show up as a networking contact, you must first finish your
                profile
              </>
            }
            icon={<InfoCircleFilled style={{ color: token.colorError }} />}
            message={
              <Typography.Title level={5}>
                You are not visible for others
              </Typography.Title>
            }
            showIcon
            style={{ marginBottom: token.marginXL, marginTop: token.marginXXL }}
            type="error"
          />
        )}

        <MeetingsProvider>{children || defaultView.component}</MeetingsProvider>
      </>
    );
  }
  return null;
};

export default Home;
