import { Button, Form, Space, Typography } from "antd";
import dayjs from "dayjs";
import { serverTimestamp } from "firebase/firestore";
import { useCallback, useMemo, useState } from "react";

import Window from "components/Window";
import { Input, InputAppearances } from "components/antd";
import sendEmail from "database/handles/sendEmail";
import { useDatabase } from "hooks";
import { ProfileProps } from "store";
import { styled } from "styles";
import { FirestoreStatuses } from "views/Meetings";

import styles from "./Meetings.module.scss";

const StyledSpace = styled(Space)({
  margin: "auto",
  width: 300,
});

enum FieldValues {
  Message = "message",
}

type CancelMeetingWindowProps = {
  viewer: ProfileProps;
  user: ProfileProps;
  cancelMeeting: any;
  onClearCancelMeeting: () => void;
};

const CancelMeetingWindow = ({
  viewer,
  user,
  cancelMeeting,
  onClearCancelMeeting,
}: CancelMeetingWindowProps) => {
  const database = useDatabase();
  const [cancelMessage, setCancelMessage] = useState(null);

  const cancelMessages = useMemo(() => {
    return [
      "This time does not suit me, please propose a new time",
      "I can’t meet at this point, but I am happy to network later with you",
    ];
  }, []);

  const handleCancelMessageClick = useCallback(
    (i) => {
      if (cancelMessage === i) {
        setCancelMessage(null);
      } else {
        setCancelMessage(i);
      }
    },
    [cancelMessage]
  );

  const onClose = useCallback(() => {
    onClearCancelMeeting();
  }, [onClearCancelMeeting]);

  const onSubmit = useCallback(async () => {
    let message = null;

    if (typeof cancelMessage === "number") {
      message = cancelMessages[cancelMessage];
    } else {
      message = cancelMessage;
    }

    const data = {
      status: FirestoreStatuses.Declined,
      cancelled_message: message || "",
      cancelled_at: serverTimestamp(),
    };

    await database
      .collection("meetings")
      .doc(cancelMeeting.id)
      .update(data)
      .then(() => {
        if (cancelMeeting.inviter === viewer.email) {
          // Viewer is inviter
          const emailData = {
            inviteeName: user.firstName,
            inviterFullName: viewer.firstName + " " + viewer.lastName,
            date: dayjs(cancelMeeting.proposed_time.toDate()).format(
              "DD/MM/YYYY"
            ),
            time: dayjs(cancelMeeting.proposed_time.toDate()).format("HH:mm"),
            cancellationReason: data.cancelled_message || "No reason added.",
          };
          sendEmail(
            user.email,
            emailData,
            "d-544cd18e176d49239852b25a5f7b3c50"
          );
        } else {
          // Viewer is invitee
          const emailData = {
            inviteeFullName: viewer.firstName + " " + viewer.lastName,
            inviterName: user.firstName,
            date: dayjs(cancelMeeting.proposed_time.toDate()).format(
              "DD/MM/YYYY"
            ),
            time: dayjs(cancelMeeting.proposed_time.toDate()).format("HH:mm"),
            cancellationReason: data.cancelled_message || "No reason added.",
          };
          sendEmail(
            user.email,
            emailData,
            "d-7d1bc44c51bb430dadc9ea0879a7639a"
          );
        }

        onClose();
      });
  }, [
    onClose,
    database,
    cancelMeeting,
    user,
    viewer,
    cancelMessage,
    cancelMessages,
  ]);

  return (
    <Window isOpen={!!cancelMeeting} onClose={onClose}>
      <Space
        direction="vertical"
        style={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          margin: "4rem 0",
        }}
      >
        <>
          <Typography.Title level={1}>Cancel meeting</Typography.Title>
          <Form onFinish={onSubmit}>
            <StyledSpace
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
              direction="vertical"
            >
              {cancelMessages.map((m, i) => (
                <Button
                  shape="round"
                  type={cancelMessage === i ? "primary" : "dashed"}
                  onClick={() => handleCancelMessageClick(i)}
                  key={"CancelMessage-" + i}
                  className={styles.cancelMessage}
                >
                  {m}
                </Button>
              ))}
              <Form.Item
                style={{
                  margin: "2rem 0",
                  width: "330px",
                }}
                name={FieldValues.Message}
              >
                <Input
                  appearance={InputAppearances.Underline}
                  onChange={(e: any) => setCancelMessage(e.target.value)}
                  placeholder="Custom reply, click to type…"
                />
              </Form.Item>
              <StyledSpace style={{ justifyContent: "center" }}>
                <Button shape="round" htmlType="submit" type="primary">
                  Cancel meeting
                </Button>
              </StyledSpace>
            </StyledSpace>
          </Form>
        </>
      </Space>
    </Window>
  );
};

export default CancelMeetingWindow;
