import { FileImageOutlined } from "@ant-design/icons";
import { Avatar as AntdAvatar, Skeleton, theme } from "antd";

import { isValue } from "helpers";
import { useImagePreloader } from "hooks";
import { boxShadow, styled } from "styles";
import type { StyledProps } from "styles";

const StyledAvatar = styled(AntdAvatar)({
  "&.ant-avatar": {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
});

type AvatarProps = {
  children?: React.ReactNode;
  className?: string;
  size: number;
  src?: string;
  initials?: string; // TODO: Unify with children property
};

const Avatar = ({ children, className, size, src, initials }: AvatarProps) => {
  const showInitials = !src;
  const { isImageError, isImageLoaded } = useImagePreloader(src);
  const { token } = theme.useToken();

  const placeholderElement = isImageError ? (
    <AntdAvatar
      icon={<FileImageOutlined />}
      size={size}
      style={{
        backgroundColor: token.colorFillSecondary,
        color: token.colorTextBase,
      }}
    />
  ) : (
    <Skeleton.Avatar size={size} />
  );

  return isImageLoaded || showInitials ? (
    <StyledAvatar className={className} size={size} src={src}>
      {children || initials}
    </StyledAvatar>
  ) : (
    placeholderElement
  );
};

export const StyledRadiantAvatar = styled(Avatar)(
  ({
    styledProps: {
      backgroundColor,
      borderColor,
      borderOffset = 0,
      borderWidth,
      margin,
    },
  }: StyledProps) => ({
    ...boxShadow,
    backgroundColor,
    borderRadius: "50%",
    margin: isValue(margin) ? margin : borderOffset + borderWidth * 2,
    overflow: "visible",

    "&:before": {
      border: `${borderWidth}px solid ${borderColor}`,
      borderRadius: "50%",
      bottom: "-100%",
      boxSizing: "content-box",
      content: "''",
      height: `calc(100% + ${borderOffset * 2}px)`,
      left: "-100%",
      margin: "auto",
      opacity: "0.24",
      position: "absolute",
      right: "-100%",
      top: "-100%",
      width: `calc(100% + ${borderOffset * 2}px)`,
    },

    "&:after": {
      border: `${borderWidth}px solid ${borderColor}`,
      borderRadius: "50%",
      bottom: "-100%",
      boxSizing: "content-box",
      content: "''",
      height: `calc(100% + ${borderOffset * 2}px + ${borderWidth * 2}px)`,
      left: "-100%",
      margin: "auto",
      opacity: "0.12",
      position: "absolute",
      right: "-100%",
      top: "-100%",
      width: `calc(100% + ${borderOffset * 2}px + ${borderWidth * 2}px)`,
    },

    img: {
      borderRadius: "50%",
      overflow: "hidden",
    },
  })
);

export default Avatar;
