import { LockOutlined } from "@ant-design/icons";
import { ConfigProvider, theme } from "antd";

import Loader from "components/Loader";
import {
  StyledCard,
  StyledCardCoverPlaceholder,
  StyledCardElement,
  Typography,
  TypographySizes,
} from "components/antd";
import { useImagePreloader } from "hooks";
import { colorWarning } from "styles/variables";

import Countdown from "./Countdown";

type ItemProps = {
  countdownInDays: number;
  coverImageSrc: string;
  dates: string;
  isOnboardingUnfinished: boolean;
  locked: boolean;
  onClick: () => void;
  title: string;
};

const Item = ({
  countdownInDays,
  coverImageSrc,
  dates,
  isOnboardingUnfinished,
  locked,
  onClick,
  title,
}: ItemProps) => {
  const { isImageError, isImageLoaded } = useImagePreloader(coverImageSrc);
  const { token } = theme.useToken();

  const paddingX = token.paddingLG;
  const paddingY = token.paddingSM;

  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            colorBgContainer: token.colorPrimary,
          },
        },
      }}
    >
      <StyledCard
        bodyStyle={{
          ...(isImageLoaded && {
            backgroundImage:
              "linear-gradient(180.07deg, rgba(0, 0, 0, 0) -31.13%, rgba(0, 0, 0, 0.84) 80.69%)",
          }),
        }}
        cover={
          isImageLoaded ? (
            <img src={coverImageSrc} />
          ) : (
            <StyledCardCoverPlaceholder backgroundColor={token.colorBgBase}>
              <Loader isIndicatorVisible={!isImageError} />
            </StyledCardCoverPlaceholder>
          )
        }
        hoverable
        onClick={onClick}
        styledProps={{ height: 200 }}
      >
        <StyledCardElement styledProps={{ left: paddingX, top: paddingY }}>
          {locked && <LockOutlined style={{ fontSize: token.fontSizeXL }} />}
        </StyledCardElement>

        <StyledCardElement styledProps={{ right: paddingX, top: paddingY }}>
          <Countdown days={countdownInDays} />
        </StyledCardElement>

        <StyledCardElement
          styledProps={{ bottom: paddingY, left: paddingX, width: "60%" }}
        >
          <Typography.Title level={5}>{title}</Typography.Title>
          <Typography size={TypographySizes.XS}>{dates}</Typography>
        </StyledCardElement>

        <StyledCardElement styledProps={{ bottom: paddingY, right: paddingX }}>
          {isOnboardingUnfinished && (
            <Typography color={colorWarning} size={TypographySizes.XS}>
              Finish onboarding
            </Typography>
          )}
        </StyledCardElement>
      </StyledCard>
    </ConfigProvider>
  );
};

export default Item;
