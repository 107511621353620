import { RocketOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd";

import { Layout, Typography } from "components/antd";
import { useNavigateToPath } from "hooks";
import { UnprotectedPaths } from "routes";

const Maintenance = () => {
  const navigateToEntry = useNavigateToPath(UnprotectedPaths.Entry);

  return (
    <Layout
      isFullHeight
      style={{
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Row justify="center">
        <Col span={20}>
          <Space direction="vertical" size="middle">
            <Typography.Title>
              We are currently undergoing maintenance, we will be back by
              tomorrow :)
            </Typography.Title>
            <>
              <Typography.Title level={3}></Typography.Title>
              <Button
                ghost
                icon={<RocketOutlined />}
                onClick={navigateToEntry}
                shape="round"
                size="large"
                type="primary"
              >
                Navigate to home
              </Button>
            </>
          </Space>
        </Col>
      </Row>
    </Layout>
  );
};

export default Maintenance;
