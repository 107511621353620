import { LeftOutlined } from "@ant-design/icons";
import {
  cloneElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { Button } from "components/antd";
import { isArray } from "helpers";

type JourneyContextProps = {
  activeStepIndex: number;
  setActiveStepIndex: (state: number) => void;
};

const initialState: JourneyContextProps = {
  activeStepIndex: undefined,
  setActiveStepIndex: undefined,
};

export const JourneyContext = createContext<JourneyContextProps>(initialState);

type JourneyContextProviderProps = {
  children: React.ReactNode;
};

export const JourneyContextProvider = ({
  children,
}: JourneyContextProviderProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState(
    initialState.activeStepIndex
  );

  return (
    <JourneyContext.Provider
      children={children}
      value={{
        activeStepIndex,
        setActiveStepIndex,
      }}
    />
  );
};

type FooterProps = {
  element: React.ReactElement;
  isBackButtonVisible: boolean;
  isNextButtonDisabled: boolean;
  isNextButtonVisible: boolean;
  onBackButtonClick: () => void;
  onNextButtonClick: () => void;
};

const Footer = ({
  element,
  isBackButtonVisible,
  isNextButtonDisabled,
  isNextButtonVisible,
  onBackButtonClick,
  onNextButtonClick,
}: FooterProps) => {
  const backButtonPlaceholderElement = <div />;

  return cloneElement(element, {
    children: (
      <>
        {isBackButtonVisible ? (
          <Button
            icon={<LeftOutlined />}
            onClick={onBackButtonClick}
            type="link"
          >
            Back
          </Button>
        ) : (
          backButtonPlaceholderElement
        )}
        {isNextButtonVisible && (
          <Button
            disabled={isNextButtonDisabled}
            onClick={onNextButtonClick}
            shape="round"
            type="primary"
          >
            Next
          </Button>
        )}
      </>
    ),
  });
};

const isArrayOfElements = (
  elements: React.ReactElement | React.ReactElement[]
) => isArray(elements as keyof typeof elements);

export type JourneyProps = {
  contentElements: React.ReactElement | React.ReactElement[];
  footerElements: React.ReactElement | React.ReactElement[];
  headerElements: React.ReactElement | React.ReactElement[];
  isConfirmationStepAdded?: boolean;
  isNextStepBlocked: boolean;
};

export const useJourney = ({
  contentElements,
  footerElements,
  headerElements,
  isConfirmationStepAdded,
  isNextStepBlocked,
}: JourneyProps) => {
  const { activeStepIndex, setActiveStepIndex } = useContext(JourneyContext);

  const handleBackButtonClick = useCallback(
    () => setActiveStepIndex(activeStepIndex - 1),
    [activeStepIndex, setActiveStepIndex]
  );

  const handleNextButtonClick = useCallback(
    () => setActiveStepIndex(activeStepIndex + 1),
    [activeStepIndex, setActiveStepIndex]
  );

  const contentElement = isArrayOfElements(contentElements)
    ? contentElements[activeStepIndex as keyof typeof contentElements]
    : (contentElements as React.ReactElement);

  const footerElement = useMemo(
    () =>
      isArrayOfElements(footerElements)
        ? footerElements[activeStepIndex as keyof typeof footerElements]
        : (footerElements as React.ReactElement),
    [activeStepIndex, footerElements]
  );

  const headerElement = useMemo(
    () =>
      isArrayOfElements(headerElements)
        ? headerElements[activeStepIndex as keyof typeof headerElements]
        : (headerElements as React.ReactElement),
    [activeStepIndex, headerElements]
  );

  const getEndIndex = (elements: React.ReactElement[]) => {
    const indexOffset = isConfirmationStepAdded ? -2 : -1;

    return elements.length + indexOffset;
  };

  const isFirstStep = activeStepIndex == 0;

  const isFooterVisible = isArrayOfElements(contentElements)
    ? activeStepIndex <= getEndIndex(contentElements as React.ReactElement[])
    : true;

  const isLastStep = isArrayOfElements(contentElements)
    ? activeStepIndex == getEndIndex(contentElements as React.ReactElement[])
    : true;

  const realFooterElement = useMemo(
    () =>
      isFooterVisible && (
        <Footer
          element={footerElement}
          isBackButtonVisible={!isFirstStep}
          isNextButtonDisabled={isNextStepBlocked}
          isNextButtonVisible={!isLastStep}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
        />
      ),
    [
      footerElement,
      handleBackButtonClick,
      handleNextButtonClick,
      isFirstStep,
      isFooterVisible,
      isLastStep,
      isNextStepBlocked,
    ]
  );

  return {
    // activeStepIndex,
    // back: handleBackButtonClick,
    contentElement,
    footerElement: realFooterElement,
    headerElement,
    // next: handleNextButtonClick,
  };
};
