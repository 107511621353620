import {
  Input as AntdInput,
  Select as AntdSelect,
  ConfigProvider,
  theme,
} from "antd";
import type {
  InputProps as AntdInputProps,
  SelectProps as AntdSelectProps,
} from "antd";
import classNames from "classnames";
import { cloneElement, forwardRef } from "react";

import { css } from "styles";
import {
  animationDuration,
  inputBorderRadiusLG,
  inputBorderRadiusMD,
  inputBorderRadiusSM,
  inputControlPaddingHorizontal,
} from "styles/variables";

export enum InputAppearances {
  Default,
  Underline,
}

type CustomInputProps = {
  appearance?: InputAppearances;
  isSelect?: boolean;
};

type StyledInputProps = CustomInputProps & {
  element: React.ReactElement;
};

const StyledInput = ({
  appearance = InputAppearances.Default,
  element,
  isSelect,
}: StyledInputProps) => {
  const { token } = theme.useToken();

  const overrideSafariAutofillCSS = css(`
    :not([disabled]):-webkit-autofill,
    :not([disabled]):-webkit-autofill:focus,
    input.ant-input:not([disabled]):-webkit-autofill,
    input.ant-input:not([disabled]):-webkit-autofill:focus {
      box-shadow: 0 0 0 100px ${token.colorBgContainer} inset !important;
      transition: none !important;
    }

    :is([disabled]):-webkit-autofill,
    :is([disabled]):-webkit-autofill:focus,
    input.ant-input:is([disabled]):-webkit-autofill,
    input.ant-input:is([disabled]):-webkit-autofill:focus {
      box-shadow: 0 0 0 100px ${token.colorBgContainerDisabled} inset !important;
      transition: none !important;
    }
  `);

  const sizeSmallBorderRadius =
    element.props?.size === "small" && inputBorderRadiusSM;
  const sizeLargeBorderRadius =
    element.props?.size === "large" && inputBorderRadiusLG;
  const inputBorderRadiusBasedOnSizeProperty =
    sizeSmallBorderRadius || sizeLargeBorderRadius;

  const appearanceUnderlineBorderStyle = {
    borderInlineEndWidth: "0 !important",
    borderLeftWidth: 0,
    borderRadius: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
  };

  const appearanceUnderlineBorderRadiusToken = {
    borderRadius: 0,
    borderRadiusLG: 0,
    borderRadiusSM: 0,
  };

  const appearanceUnderlineColorToken = {
    colorBgContainer: "transparent",
    colorBorder: token.colorTextBase,
    colorText: token.colorTextBase,
  };

  const clearButtonStyle = {
    alignItems: "center",
    bottom: 0,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    margin: "auto",
    marginRight: token.marginSM,
    opacity: 1,
    top: 0,
    width: token.sizeXL,
  };

  const preventDarkThemeFlickerStyle = {
    transition: `border-color ${animationDuration}s`,
  };

  return appearance === InputAppearances.Underline ? (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            ...appearanceUnderlineBorderRadiusToken,
            ...appearanceUnderlineColorToken,
          },
          ...(isSelect && {
            Select: appearanceUnderlineColorToken,
          }),
        },
      }}
    >
      {cloneElement(element, {
        ...element.props,
        className: classNames(
          css({
            minHeight: token.controlHeight,
            ...(isSelect
              ? {
                  "&.ant-select:not(.ant-select-customize-input)": {
                    ".ant-select-clear": clearButtonStyle,
                    ".ant-select-selector": {
                      "&, &:active, &:focus, &:hover":
                        appearanceUnderlineBorderStyle,
                    },
                  },
                }
              : {
                  "&[class*=ant-input]": {
                    "&, &:active, &:focus, &:hover, .ant-input": {
                      ...appearanceUnderlineBorderStyle,
                      ...preventDarkThemeFlickerStyle,
                    },
                  },
                }),
          }),
          element.props.className
        ),
      })}
    </ConfigProvider>
  ) : (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            borderRadius:
              inputBorderRadiusBasedOnSizeProperty || inputBorderRadiusMD,
            borderRadiusLG: inputBorderRadiusLG,
            borderRadiusSM: inputBorderRadiusSM,
            controlPaddingHorizontal: inputControlPaddingHorizontal,
          },
        },
      }}
    >
      {cloneElement(element, {
        ...element.props,
        className: classNames(
          css({
            "&[class*=ant-input]": {
              "&, &:hover, .ant-input": preventDarkThemeFlickerStyle,
            },
          }),
          overrideSafariAutofillCSS,
          element.props.className
        ),
      })}
    </ConfigProvider>
  );
};

type InputProps = (AntdInputProps | AntdSelectProps | any) & CustomInputProps;

type CompoundedComponent = React.ForwardRefExoticComponent<
  InputProps & React.RefAttributes<JSX.Element>
> & {
  Password: (props: InputProps) => JSX.Element;
  Search: (props: InputProps) => JSX.Element;
  Select: (props: InputProps) => JSX.Element;
  TextArea: (props: InputProps) => JSX.Element;
};

const Input = forwardRef((props: InputProps, ref) => (
  <StyledInput
    appearance={props.appearance}
    element={<AntdInput {...props} ref={ref} />}
  />
)) as CompoundedComponent;

Input.Password = forwardRef((props: InputProps, ref) => (
  <StyledInput
    appearance={props.appearance}
    element={<AntdInput.Password {...props} ref={ref} />}
  />
));

Input.Search = forwardRef((props: InputProps, ref) => (
  <StyledInput
    appearance={props.appearance}
    element={<AntdInput.Search {...props} ref={ref} />}
  />
));

Input.TextArea = forwardRef((props: InputProps, ref) => (
  <StyledInput
    appearance={props.appearance}
    element={<AntdInput.TextArea {...props} ref={ref} />}
  />
));

Input.Select = forwardRef((props: InputProps, ref) => (
  <StyledInput
    appearance={props.appearance}
    element={<AntdSelect {...props} ref={ref} />}
    isSelect
  />
)); // TODO: Move to components/antd/Select.tsx

export default Input;
