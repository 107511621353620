import { Layout as AntdLayout, LayoutProps as AntdLayoutProps } from "antd";
import { isMobile } from "react-device-detect";

import { styled } from "styles";
import type { StyledProps } from "styles";
import { isBodyScrollingInertiaDisabled } from "styles/global";

const mobileViewportBugFix = "-webkit-fill-available";

const StyledLayout = styled(AntdLayout)(
  ({ styledProps: { isFullHeight } }: StyledProps) =>
    isFullHeight && {
      height: !isBodyScrollingInertiaDisabled && "100%",
      minHeight: isMobile ? mobileViewportBugFix : "100vh",
    }
);

type LayoutProps = (AntdLayoutProps | any) & {
  children: React.ReactNode;
  className: string;
  isFullHeight: boolean;
  style: React.CSSProperties;
};

const Layout = ({ children, className, isFullHeight, style }: LayoutProps) => {
  return (
    <StyledLayout
      children={children}
      className={className}
      style={style}
      styledProps={{ isFullHeight }}
    />
  );
};

Layout.Content = AntdLayout.Content;
Layout.Footer = AntdLayout.Footer;
Layout.Header = AntdLayout.Header;
Layout.Sider = AntdLayout.Sider;

export default Layout;
