import { useContext, useEffect } from "react";

import { RouteTransitionContext } from "components/RouteTransition";
import {
  useDispatch,
  useFirebase,
  useNavigateToPath,
  useSelector,
} from "hooks";
import { UnprotectedPaths } from "routes";
import { fetchAccountTerminatingReducer } from "store/appSlice";
import { selectAccount } from "store/selectors";
import { ThemeContext } from "styles/antdThemeProvider";

export const useLogOut = () => {
  const { isTransitionExited: isTransitionFinished } = useContext(
    RouteTransitionContext
  );
  const { isDarkTheme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const navigateToEntry = useNavigateToPath(UnprotectedPaths.Entry);
  const account = useSelector(selectAccount);

  const isTransitionFinishedOrNotRequired = !isDarkTheme
    ? isTransitionFinished
    : true;

  useEffect(() => {
    account.isTerminating &&
      isTransitionFinishedOrNotRequired &&
      firebase.logout();
  }, [account, dispatch, firebase, isTransitionFinishedOrNotRequired]);

  const logOut = () => {
    dispatch(fetchAccountTerminatingReducer());

    navigateToEntry();
  };

  return logOut;
};
