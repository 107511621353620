import { useNavigate, useParams } from "react-router-dom";

import { ProtectedPaths } from "routes";
import { StepIndexes } from "routes/Event/Onboarding";
import type { ParamsProps } from "routes/Event/Onboarding";

export const useOnboarding = () => {
  const navigate = useNavigate();
  const { eventId, step } = useParams<ParamsProps>();

  return {
    activeStepIndex: step ? StepIndexes[step] : null,
    onStepChange: (value: number) => {
      navigate(
        `/${eventId}/${ProtectedPaths.EventOnboarding}/${StepIndexes[value]}`
      );
    },
  };
};
