import { StarFilled } from "@ant-design/icons";
import { Button, Space } from "antd";

import iconSuccessCheckmarkSrc from "assets/images/icon-success-checkmark.gif";
import { Typography, TypographySizes } from "components/antd";
import { styled } from "styles";

const StyledImage = styled.img({
  maxHeight: 190,
  maxWidth: 190,
});

type ExportLandingProps = {
  isFavouritedUsers: boolean;
  onDone: () => void;
};

const ExportLanding = ({ isFavouritedUsers, onDone }: ExportLandingProps) => (
  <Space direction="vertical" size="large" style={{ textAlign: "center" }}>
    <Space direction="vertical">
      {isFavouritedUsers && <StyledImage src={iconSuccessCheckmarkSrc} />}
      <Typography.Text size={TypographySizes.LG}>
        {isFavouritedUsers
          ? "Export has been sent to your email"
          : "You have no contacts to export"}
      </Typography.Text>
      <Typography.Text size={TypographySizes.SM}>
        {isFavouritedUsers
          ? "In case you do not find it in your inbox make sure to check junk"
          : "Make sure you have at least one favourited contact or one contact under meetings"}
      </Typography.Text>
    </Space>
    {isFavouritedUsers ? (
      <Button
        icon={<StarFilled />}
        onClick={onDone}
        shape="round"
        type="primary"
      >
        View favourites
      </Button>
    ) : (
      <div />
    )}
  </Space>
);

export default ExportLanding;
