import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { verifySingleEventAccessToken } from "api/resources/users";
import { Typography, TypographySizes } from "components/antd";
import { useLocationState, useNavigateToPath } from "hooks";
import { useFirebase } from "hooks";
import SinglePageLayout from "layouts/SinglePage";
import LoadingPage from "pages/Loading";
import NotFoundPage from "pages/NotFound";
import OneTimeLinkExpiredPage from "pages/OneTimeLinkExpired";
import { ProtectedPaths } from "routes";

export default () => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const { email, eventName } = useLocationState();
  const navigate = useNavigate();
  const navigateToEvents = useNavigateToPath(ProtectedPaths.Events);
  const { token } = useParams();
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    async function verifyToken() {
      try {
        const { event, firebaseToken } = await verifySingleEventAccessToken(
          token
        );

        const { alias: path } = event;
        await firebase
          .auth()
          .signInWithCustomToken(firebaseToken)
          .then(() => {
            path ? navigate(`/${path}`) : navigateToEvents();
          });
      } catch (e) {
        setIsError(true);
      }
    }

    if (token) {
      verifyToken();
    }
  }, [dispatch, navigate, navigateToEvents, token, firebase]);

  if (email) {
    return (
      <SinglePageLayout
        isCentered
        title={eventName ? `Welcome to “${eventName}”` : "Welcome back"}
      >
        <Typography.Text size={TypographySizes.XXL}>
          A personalized one time login link has been sent to your email.
          <br />
          Remember, it's only valid for 15 minutes.
        </Typography.Text>
      </SinglePageLayout>
    );
  } else if (token && !isError) {
    return <LoadingPage text="Loading..." />;
  } else if (token && isError) {
    return <OneTimeLinkExpiredPage />;
  } else {
    return <NotFoundPage />;
  }
};
