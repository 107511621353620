import Window from "components/Window";
import { useUnmountOnAfterClose } from "hooks";
import type { UnmountOnAfterCloseProps } from "hooks";
import { ProfileProps } from "store";

import Content from "./Content";
import Header from "./Header";

type ProjectWindowProps = {
  user: ProfileProps;
  isMaskVisible?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const ProjectWindow = ({
  user,
  isMaskVisible,
  isOpen,
  onAfterClose,
  onClose,
}: ProjectWindowProps & UnmountOnAfterCloseProps) => {
  return (
    <Window
      childrenKey={user?.id}
      headerElement={<Header user={user} />}
      isFullHeight
      isMaskVisible={isMaskVisible}
      isOpen={isOpen}
      onAfterClose={onAfterClose}
      onClose={onClose}
    >
      <Content user={user} />
    </Window>
  );
};

export default (props: ProjectWindowProps) =>
  useUnmountOnAfterClose(ProjectWindow, props);
