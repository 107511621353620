import { useEffect } from "react";

import { isFunction } from "helpers";

export const useOnMount = (callback: any) => {
  useEffect(
    function mountOnFirstPaint() {
      setTimeout(() => isFunction(callback) && callback(), 0);
    },
    [callback]
  );
};
