import { Button, Col, Form, Row, theme } from "antd";

import {
  Input,
  InputAppearances,
  Space,
  SpaceTypes,
  Typography,
  TypographySizes,
} from "components/antd";

export enum FieldValues {
  Note = "note",
}

type NoteProps = {
  onSubmit: (values: FieldValues) => void;
};

const Note = ({ onSubmit }: NoteProps) => {
  const { token } = theme.useToken();

  return (
    <Row justify="center">
      <Col span={24} md={12}>
        <Form onFinish={onSubmit}>
          <Space
            direction="vertical"
            size={token.sizeXXL}
            type={SpaceTypes.Centered}
          >
            <Typography.Text size={TypographySizes.LG} strong>
              Please write an optional message for your meeting
            </Typography.Text>
            <Form.Item name={FieldValues.Note}>
              <Input.TextArea
                appearance={InputAppearances.Underline}
                placeholder="Hi! I would like to meet you."
              />
            </Form.Item>
            <Button htmlType="submit" shape="round" size="large" type="primary">
              Propose a meeting
            </Button>
            <Typography.Text>
              PS! You can find the virtual meeting room under “Meetings” up to
              one hour before the agreed time. If you would like to meet in
              person please specify the meeting spot in the note.
            </Typography.Text>
          </Space>
        </Form>
      </Col>
    </Row>
  );
};

export default Note;
