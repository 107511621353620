import { Card as AntdCard, theme } from "antd";

import type { StyledProps } from "styles";
import { boxShadowCard, styled } from "styles";

export const StyledCard = styled(AntdCard)(
  ({ styledProps: { height } }: StyledProps) => ({
    boxShadow: boxShadowCard,
    height,
    overflow: "hidden",
    ".ant-card-cover": {
      img: {
        borderRadius: "initial",
        height: "100%",
        objectFit: "cover",
      },
      height: "100%",
      margin: 0,
      position: "absolute",
      width: "100%",
    },
    ".ant-card-body": {
      borderRadius: "initial",
      height: "100%",
      position: "relative",
      textAlign: "left",
      width: "100%",
    },
  })
);

export const StyledCardElement = styled.div(
  ({ styledProps: { bottom, left, right, top, width } }: StyledProps) => ({
    bottom,
    position: "absolute",
    left,
    right,
    top,
    width,
  })
);

const StyledCardCoverPlaceholderElement = styled.div(
  ({ styledProps: { backgroundColor } }: StyledProps) => ({
    alignItems: "center",
    backgroundColor,
    display: "flex !important",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  })
);

type StyledCardCoverPlaceholderProps = {
  backgroundColor?: string;
  children?: React.ReactNode;
};

export const StyledCardCoverPlaceholder = ({
  backgroundColor,
  children,
}: StyledCardCoverPlaceholderProps) => {
  const { token } = theme.useToken();

  return (
    <StyledCardCoverPlaceholderElement
      children={children}
      styledProps={{
        backgroundColor: backgroundColor || token.colorFillSecondary,
      }}
    />
  );
};

const StyledCardHead = styled.div(
  ({ styledProps: { justifyContent, zIndex } }: StyledProps) => ({
    display: "flex",
    justifyContent,
    left: 0,
    position: "absolute",
    right: 0,
    top: -10,
    width: "100%",
    zIndex,
  })
);

type CardHeadProps = {
  children?: React.ReactNode;
  justifyContent?: string;
  zIndex?: number;
};

export const CardHead = ({
  children,
  justifyContent = "center",
  zIndex,
}: CardHeadProps) => {
  const { token } = theme.useToken();

  return (
    <StyledCardHead
      children={children}
      styledProps={{
        justifyContent,
        zIndex: zIndex || token.zIndexBase + 1,
      }}
    />
  );
};

export { Card } from "antd";
