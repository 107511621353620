import { Col, Row, theme } from "antd";

import meetingTypeInPersonImageSrc from "assets/images/meeting-type-in-person.png";
import meetingTypeVirtualImageSrc from "assets/images/meeting-type-virtual.png";
import Selectable from "components/Selectable";
import {
  Space,
  SpaceTypes,
  StyledCard,
  Typography,
  TypographySizes,
} from "components/antd";
import { MeetingTypes } from "helpers/enums";
import { useSelector } from "hooks";
import { selectEvent } from "store/selectors";
import { boxShadowCard } from "styles";

const height = 200;

type TypeSelectorProps = {
  onSelect: (selectedType: MeetingTypes) => void;
  value: MeetingTypes;
};

const TypeSelector = ({ onSelect, value }: TypeSelectorProps) => {
  const event = useSelector(selectEvent);
  const { token } = theme.useToken();

  const handleClick = (selectedType: MeetingTypes) => {
    value !== selectedType && onSelect(selectedType);
  };

  const bodyStyle = {
    paddingBottom: token.paddingXS,
    paddingLeft: token.paddingSM,
    paddingRight: token.paddingSM,
    paddingTop: token.paddingXS,
  };

  const inPersonMeetingTextElement =
    event.isInPersonMeetingWithDedicatedTables ? (
      <Typography.Text>
        This event has designated meeting tables.
        <br />A table number will be given to you once the other party confirms.
      </Typography.Text>
    ) : (
      <Typography.Text>
        This event does not have designated meeting area.
        <br />
        Please specify in the last step where you would like to meet.
      </Typography.Text>
    );

  const virtualMeetingTextElement = (
    <Typography.Text>
      A virtual meeting room will open one hour before confirmed meeting time.
    </Typography.Text>
  );

  return (
    <Space
      size="large"
      type={SpaceTypes.SpaceEvenly}
      style={{ textAlign: "center" }}
    >
      <Typography.Title level={2}>
        Choose the way you would like to meet
      </Typography.Title>

      <Row gutter={[25, 25]} justify="center">
        {event.isInPersonMeetingAvailable && (
          <Col span={12} md={7}>
            <Selectable
              boxShadow={boxShadowCard}
              isSelected={value === MeetingTypes.InPerson}
              onClick={() => handleClick(MeetingTypes.InPerson)}
            >
              <StyledCard
                bodyStyle={bodyStyle}
                bordered={false}
                cover={<img src={meetingTypeInPersonImageSrc} />}
                styledProps={{ height }}
              >
                <Typography.Text
                  color={token.colorBgContainer}
                  size={TypographySizes.LG}
                  strong
                >
                  {MeetingTypes.InPerson} meeting
                </Typography.Text>
              </StyledCard>
            </Selectable>
          </Col>
        )}
        <Col span={12} md={7}>
          <Selectable
            boxShadow={boxShadowCard}
            isSelected={
              !event.isInPersonMeetingAvailable ||
              value === MeetingTypes.Virtual
            }
            onClick={() => handleClick(MeetingTypes.Virtual)}
          >
            <StyledCard
              bodyStyle={{ ...bodyStyle, textAlign: "right" }}
              bordered={false}
              cover={<img src={meetingTypeVirtualImageSrc} />}
              styledProps={{ height }}
            >
              <Typography.Text
                color={token.colorBgContainer}
                size={TypographySizes.LG}
                strong
              >
                {MeetingTypes.Virtual} meeting
              </Typography.Text>
            </StyledCard>
          </Selectable>
        </Col>
      </Row>

      {event.isInPersonMeetingAvailable
        ? inPersonMeetingTextElement
        : virtualMeetingTextElement}
    </Space>
  );
};

export default TypeSelector;
