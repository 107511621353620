import { SwapOutlined } from "@ant-design/icons";
import { Layout, Skeleton, Space, theme } from "antd";
import { useContext } from "react";

import { RouteTransitionContext } from "components/RouteTransition";
import { Button, Link, Typography, TypographySizes } from "components/antd";
import { formatDatesRange, isLoaded } from "helpers";
import { useNavigateToPath, useSelector } from "hooks";
import { ProtectedPaths } from "routes";
import { selectEvent } from "store/selectors";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { mobileHeaderHeight } from "styles/variables";

const StyledHeader = styled(Layout.Header)(
  ({
    styledProps: { backgroundColor, padding, position, zIndex },
  }: StyledProps) => ({
    alignItems: "center",
    backgroundColor,
    display: "flex",
    height: mobileHeaderHeight,
    padding,
    position,
    top: 0,
    width: "100%",
    zIndex,
  })
);

const StyledSpace = styled(Space)({
  ".ant-skeleton": {
    display: "flex",
  },
});

const EventSwitcherMobile = () => {
  const { isTransitionEntered } = useContext(RouteTransitionContext);
  const navigateToEvents = useNavigateToPath(ProtectedPaths.Events);
  const event = useSelector(selectEvent);
  const { token } = theme.useToken();

  return (
    <>
      <StyledHeader
        styledProps={{
          backgroundColor: token.colorBgLayout,
          padding: token.paddingContentHorizontalLG,
          position: isTransitionEntered ? "fixed" : "relative",
          zIndex: token.zIndexPopupBase,
        }}
      >
        {isLoaded(event) ? (
          <Space>
            <Button
              icon={<SwapOutlined />}
              onClick={navigateToEvents}
              type="link"
            />
            <Link onClick={navigateToEvents}>
              <Typography.Title level={4} style={{ marginBottom: 0 }}>
                {event.title}
              </Typography.Title>
              <Typography.Text
                size={TypographySizes.SM}
                style={{ display: "block" }}
              >
                {formatDatesRange(event.startDate, event.endDate)}
              </Typography.Text>
            </Link>
          </Space>
        ) : (
          <StyledSpace>
            <Skeleton.Avatar
              shape="square"
              style={{ borderRadius: token.borderRadiusSM }}
            />
            <Skeleton.Input />
          </StyledSpace>
        )}
      </StyledHeader>
    </>
  );
};

export default EventSwitcherMobile;
