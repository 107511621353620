export const animationDuration = 0.2;
export const animationDurationMedium = 0.5;
export const animationDurationLong = 0.8;
export const borderRadius = 6;
export const mobileHeaderHeight = 60;
export const opacityMenuItemActive = 0.06;
export const opacityMenuItemHover = 0.03;
export const opacitySkeletonLight = opacityMenuItemHover;
export const paddingContentHorizontalLG = 30;
export const paddingContentVerticalLG = 20;
export const paddingLayoutVertical = 40;
export const sidebarWidth = 264;
export const sizeXXL = 64;
export const tabBarHeight = 65;

// Color
export const colorBlue = "#1677FF";
export const colorDark = "#0C020E";
export const colorError = "#FF4D4F";
export const colorGray = "#A9A9A9";
export const colorGray900 = "#111827";
export const colorGrayButtonBackground = "#F3F4F6";
export const colorGrayButtonBorder = "#D9D9D9";
export const colorGrayLight = "#F8FAFC";
export const colorGrayMenu = "#9CA3AF";
export const colorGrayModalBorder = "#7A7A7A";
export const colorGraySemi = "#71798E";
export const colorGreenDark = "#00A68D";
export const colorIconFill = "#39404F";
export const colorInfo = "#1677FF";
export const colorPrimary = "#01CFB0";
export const colorSuccess = "#52C41A";
export const colorWarning = "#FAAD14";
export const colorWhite = "#FFFFFF";

// Font
export const fontFamily = "Orkney";
export const fontSizeHeading1 = 48;
export const fontSizeHeading1Responsive = 32;
export const fontSizeHeading2 = 32;
export const fontSizeHeading2Responsive = 24;
export const fontSizeHeading3 = 24;
export const fontSizeHeading3Responsive = 20;
export const fontSizeHeading4 = 20;
export const fontSizeHeading4Responsive = 18;
export const fontSizeHeading5 = 18;
export const fontSizeHeading5Responsive = 16;
export const fontSizeXXS = 8;
export const fontSizeXS = 10;
export const fontSizeSM = 12;
export const fontSizeMD = 14;
export const fontSizeLG = 16;
export const fontSizeXL = 18;
export const fontSizeXXL = 20;
export const fontWeightLight = 300;
export const fontWeightRegular = 400;
export const fontWeightMedium = 500;
export const fontWeightBold = 600;

// Input
export const inputHeightSM = 24;
export const inputHeightMD = 32;
export const inputHeightLG = 40;
export const inputBorderRadiusSM = inputHeightSM / 2;
export const inputBorderRadiusMD = inputHeightMD / 2;
export const inputBorderRadiusLG = inputHeightLG / 2;
export const inputControlPaddingHorizontal = 25;

// Menu
export const menuSubmenuBackgroundColor = "rgba(0, 0, 0, 0.02)";

// Modal
export const modalPaddingBottom = 30;
export const modalPaddingLeft = 20;
export const modalPaddingRight = 20;
export const modalPaddingTop = 30;

// Popup
export const popupBorderRadius = 20;
export const popupPaddingBottom = 30;
export const popupPaddingLeft = 20;
export const popupPaddingRight = 20;
export const popupPaddingTop = 30;

// Segmented
export const segmentedBorderRadiusSM = 16;
export const segmentedColorText = colorWhite;
export const segmentedColorTextLabelMidTone = "rgba(125, 125, 125, 1)";
export const segmentedTransitionDurationIn = 0.5;
export const segmentedTransitionDurationOut = 3;

// Tag
export const tagBorderRadiusSM = 16;
