import { Layout, Steps, theme } from "antd";

import AccountDropdown from "components/AccountDropdown";
import { useBreakpoint, useOnboarding } from "hooks";
import { StyledLayoutHeader } from "layouts";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { paddingContentVerticalLG } from "styles/variables";

const StyledLayoutContent = styled(Layout.Content)({
  marginTop: 30,
});

const StyledSteps = styled(Steps)(
  ({ styledProps: { backgroundColor, zIndex } }: StyledProps) => ({
    backgroundColor,
    position: "sticky",
    top: 0,
    zIndex,
    "&.ant-steps": {
      paddingTop: paddingContentVerticalLG * 1.2,
    },
  })
);

type OnboardingLayoutType = {
  children: React.ReactNode;
};

const OnboardingLayout = ({ children }: OnboardingLayoutType) => {
  const { isDesktop, xs } = useBreakpoint();
  const { activeStepIndex, onStepChange } = useOnboarding();
  const { token } = theme.useToken();

  return (
    <Layout>
      {isDesktop ? (
        <StyledLayoutHeader
          styledProps={{
            backgroundColor: token.colorBgLayout,
            marginTop: -token.paddingContentHorizontalLG,
            zIndex: token.zIndexPopupBase,
          }}
        >
          <AccountDropdown />
        </StyledLayoutHeader>
      ) : (
        <StyledSteps
          current={activeStepIndex}
          items={[
            { title: "Expertise" },
            { title: "Info" },
            { title: !xs ? "Profile preview" : "Preview" },
          ]}
          onChange={onStepChange}
          responsive={false}
          size={"small"}
          styledProps={{
            backgroundColor: token.colorBgLayout,
            zIndex: token.zIndexBase + 1,
          }}
          type={"navigation"}
        />
      )}
      <StyledLayoutContent>{children}</StyledLayoutContent>
    </Layout>
  );
};

export default OnboardingLayout;
