import { Button, Form } from "antd";
import { useCallback, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";

import ErrorField from "components/ErrorField";
import { Input, InputAppearances, Space, SpaceTypes } from "components/antd";
import { validateEmail } from "helpers/validators";
import { useFirebase, useFocusOnTransitionEnd } from "hooks";

enum FieldValues {
  Email = "email",
}

type ResetPasswordProps = {
  setIsEmailSent: Dispatch<SetStateAction<boolean>>;
};

const ResetPasswordForm: React.FC<ResetPasswordProps> = ({
  setIsEmailSent,
}) => {
  const firebase = useFirebase();
  const focusRef = useFocusOnTransitionEnd();
  const location = useLocation();
  const emailFromPreviousStep = location.state?.email;
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = useCallback(
    async (values) => {
      const errors = {
        "auth/user-not-found":
          "If the account with the email you entered exists, an email has been sent.",
        "auth/too-many-requests": "Too many login attempts, please wait.",
      };

      firebase
        .resetPassword(values[FieldValues.Email])
        .then(() => {
          setIsEmailSent(true);
          setTimeout(() => {}, 5000);
        })
        .catch((error) => {
          setIsEmailSent(true);
          if (errors[error.code as keyof typeof errors] !== undefined) {
            setErrorMessage(errors[error.code as keyof typeof errors]);
          } else {
            setErrorMessage(error.code);
          }
        });
    },
    [firebase, setIsEmailSent]
  );

  return (
    <Form onFinish={onSubmit} size="large">
      <Space type={SpaceTypes.FormContentAndButton}>
        <Space direction="vertical">
          <Form.Item
            hasFeedback
            initialValue={emailFromPreviousStep}
            name={FieldValues.Email}
            rules={[
              {
                message: "Enter your login email to reset password",
                required: true,
              },
              { validator: validateEmail },
            ]}
          >
            <Input
              appearance={InputAppearances.Underline}
              autocapitalize="off"
              autocomplete="off"
              autocorrect="off"
              data-qa="email"
              placeholder="Email"
              ref={focusRef}
            />
          </Form.Item>

          {errorMessage && <ErrorField>{errorMessage}</ErrorField>}
        </Space>

        <Button
          block
          data-qa="submit"
          htmlType="submit"
          shape="round"
          size="middle"
          type="primary"
        >
          Next
        </Button>
      </Space>
    </Form>
  );
};

export default ResetPasswordForm;
