import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ConfigProvider, Skeleton, theme } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

import { TypographySizes } from "components/antd";
import { cssMultiplier, getChildrenDimensions } from "helpers";
import { useOnMount, useOnResize } from "hooks";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { animationDurationMedium } from "styles/variables";

const defaultDistance = 200;

const StyledScrollContainer = styled(ScrollContainer)(
  ({
    styledProps: { justifyContent, padding, shadowColor, zIndex },
  }: StyledProps) => ({
    cursor: "grab",
    display: "flex",
    justifyContent,
    overflow: "scroll",
    paddingLeft: padding,
    paddingRight: padding,
    textAlign: "left",
    ".ant-tag": {
      marginBottom: 0,
    },
    "&:after": {
      backgroundImage: `linear-gradient(-90deg, ${shadowColor}, transparent)`,
      right: 0,
    },
    "&:before": {
      backgroundImage: `linear-gradient(90deg, ${shadowColor}, transparent)`,
      left: 0,
    },
    "&:after, &:before": {
      content: "''",
      display: "block",
      position: "absolute",
      height: "100%",
      width: padding,
      zIndex,
    },
  })
);

export const StyledScrollIndicator = styled.div(
  ({
    styledProps: {
      back,
      forth,
      isSmoothGradient,
      isVisible,
      padding,
      shadowColor,
      zIndex,
    },
  }: StyledProps) => ({
    alignItems: "center",
    display: "flex",
    height: "100%",
    opacity: isVisible ? 1 : 0,
    pointerEvents: isVisible ? "auto" : "none",
    position: "absolute",
    transition: `opacity ${animationDurationMedium}s`,
    width: padding * 3,
    zIndex,
    ...(back && {
      backgroundImage: isSmoothGradient
        ? cssMultiplier(
            `linear-gradient(90deg, ${shadowColor}, transparent)`,
            3
          )
        : `linear-gradient(90deg, ${shadowColor} 50%, transparent)`,
      justifyContent: "flex-start",
      left: 0,
      paddingLeft: padding,
    }),
    ...(forth && {
      backgroundImage: isSmoothGradient
        ? cssMultiplier(
            `linear-gradient(-90deg, ${shadowColor}, transparent)`,
            3
          )
        : `linear-gradient(-90deg, ${shadowColor} 50%, transparent)`,
      justifyContent: "flex-end",
      paddingRight: padding,
      right: 0,
    }),
  })
);

const StyledSkeleton = styled(Skeleton)(({ padding }: any) => ({
  paddingLeft: padding,
  paddingRight: padding,
}));

const StyledWrapper = styled.div({
  position: "relative",
});

type SwipeProps = {
  children: React.ReactNode;
  disableStartPositionActivationDistance?: boolean;
  fontSize?: TypographySizes;
  isLoading?: boolean;
  isSmoothGradient?: boolean;
  justifyContent?: string;
  padding: number;
  style?: object;
};

const Swipe = ({
  children,
  disableStartPositionActivationDistance,
  fontSize,
  isLoading,
  isSmoothGradient,
  justifyContent,
  padding,
  style,
}: SwipeProps) => {
  const scrollEndPositionRef = useRef<HTMLDivElement>(null);
  const scrollStartPositionRef = useRef<HTMLDivElement>(null);
  const [isEndVisible, setIsEndVisible] = useState(true);
  const [isStartVisible, setIsStartVisible] = useState(false);
  const [isSwipeable, setIsSwipeable] = useState<boolean>(true);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { token } = theme.useToken();

  const handleJump = useCallback((distance: number) => {
    const scrollStartPosition =
      scrollStartPositionRef.current?.getBoundingClientRect().left -
      scrollContainerRef.current?.getBoundingClientRect().left;

    scrollContainerRef.current?.scroll({
      behavior: "smooth",
      left: -scrollStartPosition + distance,
    });
  }, []);

  const handleScroll = useCallback(() => {
    if (scrollContainerRef.current) {
      const activationDistanceWithSwipePositionMiscalculationMargin =
        padding / 2;
      const scrollContainer =
        scrollContainerRef.current?.getBoundingClientRect();

      const scrollStartPosition =
        scrollStartPositionRef.current?.getBoundingClientRect().left -
        scrollContainer.left;
      setIsStartVisible(
        scrollStartPosition <
          (disableStartPositionActivationDistance
            ? padding
            : activationDistanceWithSwipePositionMiscalculationMargin)
      );

      const scrollEndPosition =
        scrollEndPositionRef.current?.getBoundingClientRect().left -
        scrollContainer.left;
      setIsEndVisible(
        scrollEndPosition >
          scrollContainer.width -
            activationDistanceWithSwipePositionMiscalculationMargin
      );
    }
  }, [disableStartPositionActivationDistance, padding]);

  useEffect(handleScroll, [isLoading, handleScroll]);
  useOnResize(handleScroll);

  const setIsSwipeableBasedOnWidths = useCallback(() => {
    if (scrollContainerRef.current) {
      const scrollContainerChildrenWidth =
        scrollContainerRef.current &&
        getChildrenDimensions(scrollContainerRef.current).width;
      const scrollContainerWidth = scrollContainerRef.current?.clientWidth;

      setIsSwipeable(scrollContainerChildrenWidth >= scrollContainerWidth);
    }
  }, []);

  useOnMount(setIsSwipeableBasedOnWidths);
  useOnResize(setIsSwipeableBasedOnWidths);

  return (
    <StyledWrapper style={style}>
      {!isLoading ? (
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                colorPrimary: "transparent",
              },
            },
          }}
        >
          <StyledScrollContainer
            innerRef={scrollContainerRef}
            onScroll={handleScroll}
            styledProps={{
              justifyContent: !isSwipeable && justifyContent,
              padding,
              shadowColor: token.colorBgContainer,
              zIndex: token.zIndexBase + 1,
            }}
          >
            <div ref={scrollStartPositionRef} />
            {children}
            <div ref={scrollEndPositionRef} />

            <StyledScrollIndicator
              onClick={() => handleJump(-defaultDistance)}
              styledProps={{
                back: true,
                isSmoothGradient,
                isVisible: isStartVisible,
                padding,
                shadowColor: token.colorBgContainer,
                zIndex: token.zIndexBase + 2,
              }}
            >
              <LeftOutlined style={{ color: token.colorLink, fontSize }} />
            </StyledScrollIndicator>

            <StyledScrollIndicator
              onClick={() => handleJump(+defaultDistance)}
              styledProps={{
                forth: true,
                isSmoothGradient,
                isVisible: isEndVisible,
                padding,
                shadowColor: token.colorBgContainer,
                zIndex: token.zIndexBase + 2,
              }}
            >
              <RightOutlined style={{ color: token.colorLink, fontSize }} />
            </StyledScrollIndicator>
          </StyledScrollContainer>
        </ConfigProvider>
      ) : (
        <StyledSkeleton padding={padding} paragraph={false} />
      )}
    </StyledWrapper>
  );
};

export default Swipe;
