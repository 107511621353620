import {
  CalendarOutlined,
  CalendarTwoTone,
  CommentOutlined,
  PushpinOutlined,
  SettingOutlined,
  SettingTwoTone,
  UserOutlined,
} from "@ant-design/icons";
import { theme } from "antd";
import { TabBar as AntdMobileTabBar, Badge } from "antd-mobile";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import iconGeneralActiveSrc from "assets/images/icon-general-active.png";
import iconGeneralSrc from "assets/images/icon-general.png";
import Window from "components/Window";
import { HandWaveOutlined } from "components/icons";
import { useIsPathActive, usePath, useReturnPath, useSelector } from "hooks";
import { ProtectedPaths } from "routes";
import {
  selectEventProfileIsOnboardingFinished,
  selectEventProfileIsSingleEventAccess,
  selectMeetingRequestsReceivedCount,
} from "store/selectors";
import { boxShadow, styled } from "styles";
import type { StyledProps } from "styles";
import { colorWhite, tabBarHeight } from "styles/variables";
import General, { key as generalKey } from "views/General";

const iconOverflow = 10;
const iconPadding = 8;
const iconSize = 35;

const StyledIcon = styled.img({
  height: iconSize,
  width: iconSize,
});

const StyledIconWrapper = styled.div(
  ({ styledProps: { backgroundColor } }: StyledProps) => ({
    backgroundColor,
    borderRadius: "50%",
    height: 2 * iconPadding + iconSize,
    padding: iconPadding,
    position: "relative",
    top: -22,
    width: 2 * iconPadding + iconSize,
    ":before": {
      ...boxShadow,
      borderRadius: "50%",
      content: "''",
      height: "100%",
      left: 0,
      position: "absolute",
      top: 0,
      width: "100%",
      zIndex: -1,
    },
    ":after": {
      backgroundColor,
      content: "''",
      height: "100%",
      left: -iconOverflow,
      position: "absolute",
      top: iconOverflow,
      width: `calc(100% + ${2 * iconOverflow}px)`,
      zIndex: -1,
    },
  })
);

const StyledWrapper = styled.div(
  ({ styledProps: { zIndex } }: StyledProps) => ({
    ...boxShadow,
    backgroundColor: colorWhite,
    bottom: 0,
    height: tabBarHeight,
    paddingBottom: 8,
    paddingTop: 8,
    position: "fixed",
    width: "100%",
    zIndex,
  })
);

const StyledTabBar = styled(AntdMobileTabBar)({
  ".adm-tab-bar-icon-badge": {
    top: "6px !important",
  },
  ".adm-tab-bar-wrap": {
    overflow: "visible",
  },
});

const TabBar = () => {
  const isEventHomePathActive = useIsPathActive(ProtectedPaths.EventHome);
  const isEventSupportFeedbackPathActive = useIsPathActive(
    ProtectedPaths.EventSupportFeedback
  );
  const location = useLocation();
  const navigate = useNavigate();
  const eventChatPath = usePath(ProtectedPaths.EventChat);
  const eventConnectPath = usePath(ProtectedPaths.EventConnect);
  const eventMeetingsPath = usePath(ProtectedPaths.EventMeetings);
  const eventOnboardingPath = usePath(ProtectedPaths.EventOnboarding);
  const eventProfilePath = usePath(ProtectedPaths.EventProfile);
  const returnPath = useReturnPath();
  const isOnboardingFinished = useSelector(
    selectEventProfileIsOnboardingFinished
  );
  const isSingleEventAccess = useSelector(
    selectEventProfileIsSingleEventAccess
  );
  const meetingRequestsReceivedCount = useSelector(
    selectMeetingRequestsReceivedCount
  );
  const defaultKey = isEventHomePathActive && eventConnectPath;
  const [isGeneralOpen, setIsGeneralOpen] = useState(false);
  const { token } = theme.useToken();

  const handleTabChange = useCallback(
    (key: string) => {
      if (key === generalKey) {
        setIsGeneralOpen(true);
      } else {
        setIsGeneralOpen(false);

        navigate(key, {
          state: { returnPath },
        });
      }
    },
    [navigate, returnPath]
  );

  const handleWindowClose = useCallback(() => {
    setIsGeneralOpen(false);
  }, []);

  const onboardingTabs = [
    ...(isSingleEventAccess
      ? [
          {
            icon: (active: boolean) =>
              active ? (
                <HandWaveOutlined fill={token.colorPrimary} />
              ) : (
                <HandWaveOutlined fill={token.colorText} />
              ),
            key: eventConnectPath,
            title: "Connect",
          },
        ]
      : []),
    {
      badge: Badge.dot,
      icon: (active: boolean) =>
        active ? (
          <PushpinOutlined style={{ color: token.colorPrimary }} />
        ) : (
          <PushpinOutlined />
        ),
      key: eventOnboardingPath,
      title: "Onboarding",
    },
    {
      icon: (active: boolean) =>
        active ? (
          <SettingTwoTone
            style={{ fontSize: 20 }}
            twoToneColor={token.colorPrimary}
          />
        ) : (
          <SettingOutlined style={{ fontSize: 20 }} />
        ),
      key: generalKey,
      title: "General",
    },
  ];

  const mainTabs = [
    {
      icon: (active: boolean) =>
        active ? (
          <HandWaveOutlined fill={token.colorPrimary} />
        ) : (
          <HandWaveOutlined fill={token.colorText} />
        ),
      key: eventConnectPath,
      title: "Connect",
    },
    {
      badge: meetingRequestsReceivedCount > 0 && meetingRequestsReceivedCount,
      icon: (active: boolean) =>
        active ? (
          <CalendarTwoTone
            style={{ fontSize: 20 }}
            twoToneColor={token.colorPrimary}
          />
        ) : (
          <CalendarOutlined style={{ fontSize: 20 }} />
        ),
      key: eventMeetingsPath,
      title: "Meetings",
    },
    {
      icon: (active: boolean) =>
        active ? (
          <StyledIconWrapper
            styledProps={{ backgroundColor: token.colorBgElevated }}
          >
            <StyledIcon src={iconGeneralActiveSrc} />
          </StyledIconWrapper>
        ) : (
          <StyledIconWrapper
            styledProps={{ backgroundColor: token.colorBgElevated }}
          >
            <StyledIcon src={iconGeneralSrc} />
          </StyledIconWrapper>
        ),
      key: generalKey,
      title: "General",
    },
    {
      icon: (active: boolean) =>
        active ? (
          <UserOutlined style={{ color: token.colorPrimary }} />
        ) : (
          <UserOutlined />
        ),
      key: eventProfilePath,
      title: "Profile",
    },
    {
      icon: (active: boolean) =>
        active ? (
          <CommentOutlined style={{ color: token.colorPrimary }} />
        ) : (
          <CommentOutlined />
        ),
      key: eventChatPath,
      title: "Chat",
    },
  ];

  const tabs = isOnboardingFinished ? mainTabs : onboardingTabs;
  const tabKeys = tabs.map((tab) => tab.key);
  const pathKey =
    tabKeys.filter((key) => location.pathname.includes(key))[0] || defaultKey; // TODO: Replace with a universal method of removing params from pathname
  const isGeneralLinkOpen = isEventSupportFeedbackPathActive;
  const defaultActiveKey =
    isGeneralOpen || isGeneralLinkOpen ? generalKey : pathKey;

  return (
    <>
      <StyledWrapper styledProps={{ zIndex: token.zIndexPopupBase + 1 }}>
        <StyledTabBar
          activeKey={
            !isOnboardingFinished && !isSingleEventAccess
              ? eventOnboardingPath
              : defaultActiveKey
          }
          onChange={handleTabChange}
        >
          {tabs.map((item) => (
            <AntdMobileTabBar.Item
              badge={item.badge}
              icon={item.icon}
              key={item.key}
              style={{ color: token.colorText }}
              title={item.title}
            />
          ))}
        </StyledTabBar>
      </StyledWrapper>

      <Window isOpen={isGeneralOpen} onClose={handleWindowClose}>
        <General onLinkClick={handleWindowClose} />
      </Window>
    </>
  );
};

export default TabBar;
