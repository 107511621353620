import { Checkbox, ConfigProvider, theme } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { addUserToFavorites } from "api/resources/users";
import {
  Button,
  Space,
  SpaceTypes,
  Typography,
  TypographySizes,
} from "components/antd";
import { formatUserFullName } from "helpers";
import { useNavigateToPath, useSelector } from "hooks";
import { ProtectedPaths, UnprotectedPaths } from "routes";
import { EventsProvider } from "routes/Events";
import { FilterValues as EventsFilterValues } from "routes/Events/Filters";
import type { EventProps, ProfileProps } from "store";
import {
  selectAccountId,
  selectAllEvents,
  selectAuthUserType,
} from "store/selectors";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { FilterValues as EventConnectFilterValues } from "views/Connect/Filters";

import type { ParamsProps } from ".";

const StyledSpace = styled(Space)(
  ({ styledProps: { backgroundColor, padding } }: StyledProps) => ({
    backgroundColor,
    padding,
  })
);

type FooterProps = {
  isViewerJoinedEvent: boolean;
  user: ProfileProps;
};

const Footer = ({ isViewerJoinedEvent, user }: FooterProps) => {
  const navigateToEntry = useNavigateToPath(UnprotectedPaths.Entry);
  const navigateToEventConnectFavourites = useNavigateToPath([
    ProtectedPaths.EventConnect,
    EventConnectFilterValues.Favourites,
  ]);
  const navigateToEventsDiscover = useNavigateToPath([
    ProtectedPaths.Events,
    EventsFilterValues.Discover,
  ]);
  const { eventId } = useParams<ParamsProps>();
  const accountId = useSelector(selectAccountId);
  const allEvents = useSelector(selectAllEvents);
  const { isAuthenticatedUser } = useSelector(selectAuthUserType);
  const [isReminderChecked, setIsReminderChecked] = useState(false);
  const { token } = theme.useToken();

  const eventTitle = allEvents?.find(
    ({ path }: EventProps) => path === eventId
  )?.title;

  const navigateToEventConnectFavouritesIfAlreadyFavourited =
    navigateToEventConnectFavourites;

  const handleAddFavoriteClick = async () => {
    if (isReminderChecked) {
      // TODO: Set up reminder for user in one week to contact the favorited user
    }

    await addUserToFavorites(accountId, eventId, {
      userId: user.id,
    })
      .then(navigateToEventConnectFavourites)
      .catch(navigateToEventConnectFavouritesIfAlreadyFavourited);
  };

  const getTitleText = () => {
    switch (true) {
      case isAuthenticatedUser && !isViewerJoinedEvent:
        return <>You have not joined {eventTitle || "this event"}</>;

      default:
        return (
          <>Would you like to keep {formatUserFullName(user)} as a contact?</>
        );
    }
  };

  const getDescriptionElement = () => {
    switch (true) {
      case isAuthenticatedUser && isViewerJoinedEvent:
        return (
          <>
            <Checkbox
              checked={isReminderChecked}
              onChange={(e) => setIsReminderChecked(e.target.checked)}
            >
              <Typography.Text size={TypographySizes.XS}>
                Remind me to contact {user.firstName} in one week
              </Typography.Text>
            </Checkbox>

            <Button
              onClick={handleAddFavoriteClick}
              shape="round"
              style={{ marginTop: token.marginXS }}
              type={"primary"}
            >
              Favourite now
            </Button>
          </>
        );

      case isAuthenticatedUser && !isViewerJoinedEvent:
        return (
          <>
            <Typography.Text size={TypographySizes.SM}>
              To keep contacts and view them later you must first join event
            </Typography.Text>

            <Button
              onClick={navigateToEventsDiscover}
              shape="round"
              style={{ marginTop: token.marginXS }}
              type="primary"
            >
              Join now
            </Button>
          </>
        );

      default:
        return (
          <>
            <Typography.Text size={TypographySizes.SM}>
              Hundreds of people are using Matchmaking
              <br />
              to favourite and keep their event contacts
            </Typography.Text>

            <Button
              onClick={navigateToEntry}
              shape="round"
              style={{ marginTop: token.marginXS }}
              type="primary"
            >
              Use now
            </Button>
          </>
        );
    }
  };

  return (
    <EventsProvider>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorLink: token.colorLink,
              colorText: token.colorText,
            },
          },
          token: {
            colorLink: token.colorPrimary,
            colorText: token.colorBgContainer,
          },
        }}
      >
        <StyledSpace
          direction="vertical"
          styledProps={{
            backgroundColor: token.colorTextBase,
            padding: token.paddingMD,
          }}
          type={SpaceTypes.Centered}
        >
          <Typography.Title level={4} strong style={{ margin: 0 }}>
            {getTitleText()}
          </Typography.Title>

          {getDescriptionElement()}
        </StyledSpace>
      </ConfigProvider>
    </EventsProvider>
  );
};

export default Footer;
