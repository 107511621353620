import type { Interpolation, Theme } from "@emotion/react";
import { theme } from "antd";
import update from "immutability-helper";
import { isAndroid } from "react-device-detect";

import { useBreakpoint } from "hooks";
import { appElementId, appWrapperElementId } from "index";
import {
  fontSizeSM,
  fontWeightMedium,
  mobileHeaderHeight,
  sidebarWidth,
  tabBarHeight,
} from "styles/variables";

export { Global } from "@emotion/react";

export const isBodyScrollingInertiaDisabled = !isAndroid;

const intercomCssSelector = ".intercom-namespace .intercom-messenger-frame";
const intercomLauncherCssSelector = ".intercom-messenger-frame + div";
const intercomLoadTransitionCssSelector = ".intercom-lightweight-app";

const iOSExpandedAddressBarStyle = {
  height: "100%",
};

type GlobalLayoutStylesProps = {
  offsetBottom?: number;
  offsetTop?: number;
};

export const useGlobalLayoutStyles = (params?: GlobalLayoutStylesProps) => {
  const { isDesktop, md } = useBreakpoint();
  const { token } = theme.useToken();

  const antdOverrides = {
    ".ant-form-item-explain": {
      marginLeft: token.marginSM,
      marginTop: token.marginXS,
      textAlign: "left",
      ".ant-form-item-explain-error": {
        fontSize: fontSizeSM,
        "&:first-letter": {
          textTransform: "uppercase",
        },
      },
    },
    ".ant-typography": {
      strong: {
        fontWeight: fontWeightMedium,
      },
    },
  };

  const appStyle = {
    height: `calc(100% - ${params?.offsetBottom || 0}px - ${
      params?.offsetTop || 0
    }px)`,
    overflow: "auto",
    position: "relative",
    top: params?.offsetTop,
  };

  const bodyStyle = {
    backgroundColor: token.colorBgLayout,
    color: token.colorTextBase,
    fontFamily: token.fontFamily,
  };

  const intercomStyle = {
    [`${intercomLauncherCssSelector}, ${intercomLoadTransitionCssSelector}`]: {
      display: "none !important",
    },
    [intercomCssSelector]: {
      bottom: `${
        (isDesktop ? 0 : tabBarHeight) + token.paddingContentVertical
      }px !important`,
      boxShadow: "none !important",
      height: `calc(100% - ${
        (isDesktop ? 0 : mobileHeaderHeight + tabBarHeight) +
        token.paddingContentVertical * 2
      }px) !important`,
      left: `${
        (isDesktop ? sidebarWidth : 0) + token.paddingContentHorizontal
      }px !important`,
      margin: "auto !important",
      maxHeight: "none !important",
      minHeight: "none !important",
      right: `${token.paddingContentHorizontal}px !important`,
      top: `${
        (isDesktop ? 0 : mobileHeaderHeight) + token.paddingContentVertical
      }px !important`,
      transformOrigin: "center",
      transition: "none !important",
      width: `calc(${md ? 75 : 100}% - ${
        (isDesktop ? sidebarWidth : 0) + token.paddingContentHorizontal * 2
      }px) !important`,
      zIndex: `${token.zIndexPopupBase} !important`,
    },
  };

  const mainStyles: any = {
    ...antdOverrides,
    ...intercomStyle,
    [`#${appElementId}`]: appStyle,
    body: bodyStyle,
  };

  const disabledScrollingInertiaStyles = update(mainStyles, {
    body: {
      $merge: {
        height: iOSExpandedAddressBarStyle.height || "100%",
        overflow: "hidden",
      },
    },
    [`#${appWrapperElementId}`]: {
      $set: {
        height: "100%",
        overflow: "hidden",
      },
    },
  });

  const globalLayoutStyles = isBodyScrollingInertiaDisabled
    ? disabledScrollingInertiaStyles
    : mainStyles;

  return globalLayoutStyles as Interpolation<Theme>;
};
