import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Row,
  Space,
  TimePicker,
  Upload,
  message,
  theme,
} from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile } from "antd/es/upload/interface";
import {
  UploadResult,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Avatar,
  Input,
  InputAppearances,
  Link,
  Typography,
  TypographySizes,
} from "components/antd";
import { formatUserInitials } from "helpers";
import { validateLinkedInUrl } from "helpers/validators";
import { useDispatch, useIsPathActive, useSelector } from "hooks";
import { ProtectedPaths } from "routes";
import type { CompanyProps } from "store";
import { fetchCompanies, fetchUserEventUpdateProfile } from "store/appSlice";
import {
  selectAccountId,
  selectCompanies,
  selectEventProfile,
} from "store/selectors";
import type { StyledProps } from "styles";
import { styled } from "styles";
import EditLayoutFooter from "views/Edit/layout/Footer";

import { AvatarSkeleton } from ".";
import CompanySearchInput from "./CompanySearchInput";
import CountryCodeSearchInput from "./CountryCodeSearchInput";

enum FieldValues {
  Description = "description",
  JobTitle = "job_title",
  PhoneNumber = "phone_number",
  Linkedin = "linkedin",
  UnavailableTimes = "unavailable_times",
}

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file.");
  }
  return isJpgOrPng;
};

const dummyRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const StyledCompanyInfo = styled(Typography.Text)(
  ({ styledProps: { padding } }: StyledProps) => ({
    padding,
  })
);

type ProfileProps = {
  company: CompanyProps;
  onCompanyLinkClick: () => void;
  onCompanySearchInputChange: (id: string) => void;
  onSaveFinished: () => void;
};

const ProfileForm = ({
  company,
  onCompanyLinkClick,
  onCompanySearchInputChange,
  onSaveFinished,
}: ProfileProps) => {
  const dispatch = useDispatch();
  const isEventOnboardingPathActive = useIsPathActive(
    ProtectedPaths.EventOnboarding
  );
  const { eventId } = useParams();
  const accountId = useSelector(selectAccountId);
  const eventProfile = useSelector(selectEventProfile);
  const [saving, setSaving] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    eventProfile.countryCode
  );
  const [imageUrl, setImageUrl] = useState<string>();
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const description = Form.useWatch(FieldValues.Description, form);
  const companies = useSelector(selectCompanies);

  const isCompanyListed = !!company?.id;

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [accountId, dispatch, eventId]);

  useEffect(() => {
    setImageUrl(eventProfile?.avatarUrl);
  }, [eventProfile?.avatarUrl]);

  useEffect(() => {
    if (eventProfile?.unavailableTimes) {
      const transformedUnavailableTimes = eventProfile.unavailableTimes.map(
        (item) => ({
          date: moment(item.date),
          time: moment(item.time),
        })
      );

      form.setFieldsValue({
        [FieldValues.UnavailableTimes]: transformedUnavailableTimes,
      });
    }
  }, [eventProfile, form]);

  let uploadResultResizeImage: UploadResult;
  const checkResizeImageTries = 0;
  const checkResizeImageMaxTries = 10;
  const resizeImageCheckDelay = 1000;

  const checkForResizedImage = () => {
    const storage = getStorage();
    const { metadata } = uploadResultResizeImage;
    const resizedImageRef = ref(storage, `${metadata.fullPath}_200x200`);

    getDownloadURL(resizedImageRef)
      .then((downloadURL) => {
        setIsImageUploading(false);
        setImageUrl(downloadURL);
      })
      .catch(() => {
        if (checkResizeImageTries < checkResizeImageMaxTries) {
          setTimeout(checkForResizedImage, resizeImageCheckDelay);
        } else {
          setIsImageUploading(false);
        }
      });
  };

  const uploadImageToStorage = (file: Blob | ArrayBuffer | Uint8Array) => {
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `users/avatars/${accountId}-${eventId}-${Date.now()}`
    );

    const metadata = {
      contentType: "image/jpeg",
    };

    uploadBytes(storageRef, file, metadata).then((uploadResult) => {
      uploadResultResizeImage = uploadResult;
      checkForResizedImage();
    });
  };

  const handleUploadImage = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      setIsImageUploading(true);
      uploadImageToStorage(info.file.originFileObj);
    }
  };

  const handleRemoveImage = () => {
    setImageUrl(null);
  };

  const handleProfileSave = useCallback(
    async (values) => {
      if (accountId && eventProfile) {
        const companyData = {
          company: company?.id || company?.name || "",
          isCompanyListed,
        };

        const updateData = {
          ...companyData,
          avatarUrl: imageUrl || "",
          countryCode: selectedCountryCode,
          description: values[FieldValues.Description],
          jobTitle: values[FieldValues.JobTitle],
          linkedIn: values[FieldValues.Linkedin],
          phoneNumber: values[FieldValues.PhoneNumber],
          unavailableTimes: values[FieldValues.UnavailableTimes],
        };

        dispatch(fetchUserEventUpdateProfile(accountId, eventId, updateData));
      }
    },
    [
      accountId,
      company?.id,
      company?.name,
      dispatch,
      eventId,
      eventProfile,
      imageUrl,
      isCompanyListed,
      selectedCountryCode,
    ]
  );

  const handleFormSubmit = (values: FieldValues) => {
    setSaving(true);

    handleProfileSave(values)
      .then(() => {
        setSaving(false);
      })
      .then(onSaveFinished);
  };

  return (
    <Form form={form} onFinish={handleFormSubmit}>
      {eventProfile && (
        <Space direction="vertical" size="large">
          <Row justify="center">
            <Col span={24}>
              <div style={{ alignItems: "flex-end", display: "flex" }}>
                <div
                  style={{
                    marginRight: token.marginXL,
                    position: "relative",
                  }}
                >
                  {isImageUploading ? (
                    <AvatarSkeleton />
                  ) : (
                    <Avatar
                      initials={formatUserInitials(eventProfile)}
                      size={112}
                      src={imageUrl}
                    />
                  )}
                  {imageUrl && !isImageUploading && (
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: token.colorError,
                        },
                      }}
                    >
                      <Button
                        shape="circle"
                        size="large"
                        onClick={handleRemoveImage}
                        style={{ position: "absolute", right: 0, top: 0 }}
                        type="default"
                      >
                        <DeleteOutlined />
                      </Button>
                    </ConfigProvider>
                  )}
                  {!isImageUploading && (
                    <Upload
                      beforeUpload={beforeUpload}
                      customRequest={dummyRequest}
                      onChange={handleUploadImage}
                      defaultFileList={[]}
                    >
                      <Button
                        shape="circle"
                        size="large"
                        style={{
                          bottom: 0,
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        <PlusOutlined />
                      </Button>
                    </Upload>
                  )}
                </div>
                <Space
                  direction="vertical"
                  size="large"
                  style={{ flex: "auto" }}
                >
                  <Form.Item
                    initialValue={eventProfile?.jobTitle}
                    name={FieldValues.JobTitle}
                  >
                    <Input
                      appearance={InputAppearances.Underline}
                      placeholder="Your Job Title"
                      data-qa="job"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Space direction="vertical">
                      <CompanySearchInput
                        onChange={onCompanySearchInputChange}
                        options={companies}
                        value={eventProfile?.companyName}
                      />
                      <StyledCompanyInfo
                        size={TypographySizes.XS}
                        styledProps={{ padding: token.paddingSM }}
                      >
                        {isCompanyListed && (
                          <>
                            Exhibitor booth linked with{" "}
                            {company.products?.length} item
                            {company.products?.length === 1 ? "" : "s"}.{" "}
                            {company.products?.length > 0 && (
                              <ConfigProvider
                                theme={{
                                  token: {
                                    colorLink: token.colorPrimary,
                                  },
                                }}
                              >
                                <Link onClick={onCompanyLinkClick}>
                                  View catalogue
                                </Link>
                              </ConfigProvider>
                            )}
                          </>
                        )}
                      </StyledCompanyInfo>
                    </Space>
                  </Form.Item>
                </Space>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Space
                style={{
                  justifyContent: "flex-end",
                  marginBottom: "4px",
                }}
              >
                <Typography.Text>
                  {description && description.length + " / 200"}
                </Typography.Text>
              </Space>
              <Form.Item
                initialValue={eventProfile?.description}
                name={FieldValues.Description}
                rules={[
                  {
                    message: "Please fill out your short description",
                    required: true,
                  },
                  () => ({
                    validator(_, value) {
                      if (value.length <= 200) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "You can use a maximum of 200 characters in your short description"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.TextArea
                  appearance={InputAppearances.Underline}
                  placeholder="Short description about yourself. Eg. Business Sales and Development Professional with 20+ years experience in B2B Sales"
                  data-qa="description"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item>
                <CountryCodeSearchInput
                  onChange={(value: string) => setSelectedCountryCode(value)}
                  selectedValue={selectedCountryCode}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                initialValue={eventProfile?.phoneNumber}
                name={FieldValues.PhoneNumber}
              >
                <Input
                  appearance={InputAppearances.Underline}
                  placeholder="Public phone number"
                  data-qa="phone number"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                initialValue={eventProfile?.linkedIn}
                name={FieldValues.Linkedin}
                rules={[{ validator: validateLinkedInUrl }]}
              >
                <Input
                  appearance={InputAppearances.Underline}
                  placeholder="LinkedIn URL"
                  data-qa="linkedin"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Typography.Title level={4}>Unavailable Times</Typography.Title>
              <Typography.Text>
                Select the times you are not available for meetings.
                {eventProfile.inPersonMeetingStartDate && (
                  <Typography.Text>
                    <br />
                    Admin manager has set in person meeting dates for you, which
                    you can see below. You can add unavailable times within this
                    range.
                    <br />
                    <br />
                    Start date: {eventProfile.inPersonMeetingStartDate}
                    <br />
                    End date: {eventProfile.inPersonMeetingEndDate}
                    <br />
                  </Typography.Text>
                )}
              </Typography.Text>
              <Form.List name={FieldValues.UnavailableTimes}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Form.Item key={field.key} required={false}>
                        <Space
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, "date"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select a date",
                              },
                            ]}
                            noStyle
                          >
                            <DatePicker placeholder="Select Date" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "time"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select a time",
                              },
                            ]}
                            noStyle
                          >
                            <TimePicker
                              placeholder="Select Time"
                              format="HH:mm"
                            />
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                            style={{ color: "red", cursor: "pointer" }}
                          />
                        </Space>
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        Add Unavailable Time
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </Space>
      )}
      <EditLayoutFooter>
        <Button
          disabled={saving}
          htmlType="submit"
          shape="round"
          type="primary"
        >
          {isEventOnboardingPathActive ? "Next" : "Save"}
        </Button>
      </EditLayoutFooter>
    </Form>
  );
};

export default ProfileForm;
