import { Skeleton, Space } from "antd";

import { Typography, TypographySizes } from "components/antd";
import { isLoaded } from "helpers";
import { useSelector } from "hooks";
import { selectEventInterests } from "store/selectors";

import GroupTags from "./GroupTags";

type GroupsProps = {
  onAddTag: (tag: string) => void;
  onRemoveTag: (tagId: string) => void;
  userInterestTags: string[];
};

const Groups = ({ onAddTag, onRemoveTag, userInterestTags }: GroupsProps) => {
  const eventInterests = useSelector(selectEventInterests);

  return isLoaded(eventInterests) ? (
    <Space direction="vertical" size="large">
      {eventInterests.items.map(
        ({ id, name, tags: eventInterestTags }: any) => (
          <Space direction="vertical" key={id} size="middle">
            <Typography.Text size={TypographySizes.XS} uppercase>
              {name}
            </Typography.Text>

            <GroupTags
              onAddTag={onAddTag}
              onRemoveTag={onRemoveTag}
              tags={eventInterestTags}
              selectedTags={userInterestTags}
            />
          </Space>
        )
      )}
    </Space>
  ) : (
    <Skeleton />
  );
};

export default Groups;
