import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { Timestamp } from "firebase/firestore";
import _ from "lodash";
import moment from "moment";
import { isEmpty, isLoaded } from "react-redux-firebase";

import type {
  MeetingTimeProps,
  ProfileProps,
  TimeProps,
  TimeWithAvailabilityProps,
} from "store";

import { firestoreTimestampFormat } from "./constants";

export const cssMultiplier = (css: string, multiplier: number) => {
  const multipliedCss = [...Array(multiplier)].map(() => css);

  return multipliedCss.join(",");
};

export const formatCountdownInDays = (startDate: Date, endDate: Date) => {
  const now = dayjs().startOf("day");
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const isFutureEvent = now.isBefore(start);
  const isPastEvent = now.isAfter(end);
  const dueValue = isPastEvent ? -1 : 0;

  return isFutureEvent ? start.diff(now, "day") : dueValue;
};

export const getDateAtEndOfDay = (date: Dayjs) =>
  date.set("hour", 23).set("minute", 59).set("second", 59);

export const getDateAtStartOfDay = (date: Dayjs) =>
  date.set("hour", 0).set("minute", 0).set("second", 0);

export const getDateAtEndOfPreviousMonth = (date: Dayjs) => date.set("date", 0);

export const getDateAtStartOfNextMonth = (date: Dayjs) =>
  date.add(1, "month").set("date", 1);

export const getFirestoreTimestamp = (date: Dayjs) =>
  new Timestamp(
    Date.parse(new Date(date.format(firestoreTimestampFormat)).toISOString()) /
      1000,
    0
  );

export const formatDatesRange = (startDate: Date, endDate: Date) => {
  const start = dayjs(startDate).format("DD MMM");
  const end = dayjs(endDate).format("DD MMM, YYYY");

  return `${start} - ${end}`;
};

export const formatNotEmptyString = (string: string) =>
  string !== "" ? string : undefined;

export const formatTime = ({ hour, minute }: TimeProps, separator = ":") => {
  const paddedNumber = (timeUnitValue: number) =>
    String(timeUnitValue).padStart(2, "0");

  return `${paddedNumber(hour)}${separator}${paddedNumber(minute)}`;
};

export const formatUserCompanyNameWithJobTitle = (user: ProfileProps) => {
  const separator = user?.jobTitle && user?.companyName ? " • " : "";

  return (
    isLoaded(user) &&
    `${user?.jobTitle || ""}${separator}${user?.companyName || ""}`
  );
};

export const formatUserTypeProjectName = (user: ProfileProps) => {
  return isLoaded(user) && `Project • ${user?.lastName || ""}`;
};

export const formatUserFullName = (user: ProfileProps) =>
  isLoaded(user) && `${user?.firstName} ${user?.lastName}`;

export const formatUserInitials = (user: ProfileProps) =>
  isLoaded(user) &&
  `${user?.firstName.charAt(0).toUpperCase()}${user?.lastName
    ?.charAt(0)
    .toUpperCase()}`;

type AccumulatorDimensionsProps = { height: number; width: number };

export const getChildrenDimensions = ({ children }: any) => {
  const initialDimensions = { height: 0, width: 0 };

  return children
    ? [...children].reduce(
        (
          accumulator: AccumulatorDimensionsProps,
          { clientHeight, clientWidth }: HTMLDivElement
        ) => ({
          height: accumulator.height + clientHeight,
          width: accumulator.width + clientWidth,
        }),
        initialDimensions
      )
    : initialDimensions;
};

export const getIsDateInPast = (date: Dayjs) => {
  const today = dayjs();

  return date < getDateAtEndOfDay(today).add(-1, "day");
};

export const getIsTimeInPast = (date: Dayjs) => date < dayjs();

export const getSubChildrenDimensions = (
  { children }: any,
  targetId: string
) => {
  const initialDimensions = { height: 0, width: 0 };

  return children
    ? [...children].reduce(
        (
          accumulator: AccumulatorDimensionsProps,
          { children: subChildren, id }: HTMLDivElement
        ) =>
          id === targetId
            ? {
                height: accumulator + getChildrenDimensions(subChildren).height,
                width: accumulator + getChildrenDimensions(subChildren).width,
              }
            : initialDimensions,
        initialDimensions
      )
    : initialDimensions;
};

export const getValidDates = (
  dateAndTime: Dayjs,
  [startDate, endDate]: Dayjs[],
  userEventProfile: ProfileProps
) => {
  const date = getDateAtStartOfDay(dateAndTime);

  let validEndDate = getDateAtEndOfDay(endDate);
  let validStartDate = getDateAtStartOfDay(startDate);

  if (userEventProfile?.inPersonMeetingStartDate) {
    validStartDate = dayjs(userEventProfile.inPersonMeetingStartDate);
    validEndDate = dayjs(userEventProfile.inPersonMeetingEndDate);
  }

  const isDateInPast = getIsDateInPast(date);
  const isDateInValidRange = dateAndTime.isBetween(
    validStartDate,
    validEndDate,
    "day",
    "[]"
  );

  return {
    date,
    isDateInPast,
    isDateInValidRange,
    validEndDate,
    validStartDate,
  };
};

export const isArray = (variable: any) => Array.isArray(variable);

export const isEqual = (
  variableOne: object | string[],
  variableTwo: object | string[]
) =>
  isArray(variableOne) || isArray(variableTwo)
    ? _.isEqual(_.sortBy(variableOne), _.sortBy(variableTwo))
    : _.isEqual(variableOne, variableTwo);

export const getValidTimes = (
  dateAndTime: Dayjs,
  meetingTimes: TimeProps[],
  unavailableTimes: MeetingTimeProps[] = []
) => {
  const currentDay = dateAndTime.get("day");
  const times = meetingTimes.map((time: TimeProps) => {
    const isAvailable = !unavailableTimes.some(
      ({ date, time: meetingTime }: MeetingTimeProps) =>
        moment(meetingTime).get("hour") === time.hour &&
        moment(date).get("day") === currentDay
    );

    return { ...time, isAvailable, isUnavailable: !isAvailable };
  });

  const isNoAvailableTimes = !times.some(
    ({ isAvailable }: TimeWithAvailabilityProps) => isAvailable
  );

  const isTimeInPast = getIsTimeInPast(dateAndTime);
  const isTimeInValidRange =
    !isTimeInPast &&
    meetingTimes.some((meetingTime: TimeProps) =>
      isEqual(meetingTime, {
        hour: dateAndTime.get("hour"),
        minute: dateAndTime.get("minute"),
      })
    );

  return {
    isNoAvailableTimes,
    isTimeInValidRange,
    times,
  };
};

export const isFunction = (variable: any) => typeof variable === "function";

export const isNumber = (variable: any) =>
  !isNaN(variable) && variable !== null;

export const isObject = (variable: any) => typeof variable === "object";

export const isValue = (variable: any) =>
  variable !== undefined && variable !== null;

export { isEmpty, isLoaded };
