import { Col, Row, theme } from "antd";
import { useContext, useRef } from "react";

import { WindowContext } from "components/Window";
import { Layout } from "components/antd";
import { cssMultiplier } from "helpers";
import { useRefElementProperties } from "hooks";
import { styled } from "styles";
import type { StyledProps } from "styles";

export const minHeight = 70;

const StyledLayout = styled(Layout)(
  ({
    styledProps: {
      backgroundImage,
      marginBottom,
      marginTop,
      paddingY,
      position,
      width,
    },
  }: StyledProps) => ({
    backgroundColor: "transparent",
    backgroundImage,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    marginBottom,
    marginTop,
    minHeight,
    paddingLeft: paddingY,
    paddingRight: paddingY,
    position,
    width,
  })
);

const FixedPlaceholder = styled(Layout)(
  ({ styledProps: { marginTop } }: StyledProps) => ({
    marginTop,
    minHeight,
  })
);

type EditLayoutFooterProps = {
  children: React.ReactNode;
  isFixed?: boolean;
};

const EditLayoutFooter = ({ children, isFixed }: EditLayoutFooterProps) => {
  const { spacingBottom: spacing } = useContext(WindowContext);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    relativeToViewport: { width },
  } = useRefElementProperties(wrapperRef);
  const { token } = theme.useToken();

  return (
    <>
      <div ref={wrapperRef} />
      <StyledLayout
        styledProps={{
          backgroundImage:
            !isFixed &&
            cssMultiplier(
              `linear-gradient(transparent, ${token.colorBgLayout})`,
              5
            ),
          marginBottom: !isFixed && -spacing,
          marginTop: !isFixed && spacing,
          paddingY: token.paddingContentHorizontalLG,
          position: isFixed ? "fixed" : "sticky",
          width: isFixed ? width : "100%",
        }}
      >
        <Row justify="center">
          <Col span={24} lg={16} style={{ textAlign: "center" }}>
            {children}
          </Col>
        </Row>
      </StyledLayout>
      {isFixed && (
        <FixedPlaceholder styledProps={{ marginTop: token.marginMD }} />
      )}
    </>
  );
};

export default EditLayoutFooter;
