import { ConfigProvider, Tabs, theme } from "antd";
import type { TabsProps } from "antd";
import { useRef } from "react";

import AccountDropdown, {
  height as accountDropdownHeight,
} from "components/AccountDropdown";
import { useRefElementProperties } from "hooks";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { colorGrayMenu, fontWeightMedium } from "styles/variables";

export const height = 108;
const offset = 50;
export const paddingTop = 20;
export const zIndexOffset = -1;

const StyledAccountDropdown = styled(AccountDropdown)(
  ({ styledProps: { right } }: StyledProps) => ({
    position: "fixed",
    right,
    top: (paddingTop + height - accountDropdownHeight) / 2,
  })
);

const StyledAccountDropdownPlaceholder = styled(AccountDropdown)({
  visibility: "hidden",
});

const StyledTabs = styled(Tabs)(
  ({ styledProps: { backgroundColor, zIndex } }: StyledProps) => ({
    fontWeight: fontWeightMedium,
    marginBottom: 50,
    marginTop: 75,
    paddingTop,
    position: "sticky",
    top: 0,
    zIndex,
    "&.ant-tabs": {
      "> .ant-tabs-nav": {
        marginBottom: 0,
        "&:not(.ant-tabs-tab-active)": {
          color: colorGrayMenu,
        },
      },
    },
    "&:before": {
      backgroundColor,
      content: "''",
      height: "100%",
      left: -offset,
      position: "absolute",
      right: -offset,
      top: 0,
      width: `calc(100% + ${offset * 2}px)`,
    },
  })
);

type TabSwitcherProps = {
  activeKey: string;
  defaultActiveKey?: string;
  items?: TabsProps["items"];
  onChange: (activeKey: string) => void;
};

const TabSwitcher = ({
  activeKey,
  defaultActiveKey,
  items,
  onChange,
}: TabSwitcherProps) => {
  const tabBarEndRef = useRef<HTMLDivElement>(null);
  const { token } = theme.useToken();

  const {
    relativeToViewport: { right },
  } = useRefElementProperties(tabBarEndRef);

  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            colorPrimary: token.colorTextBase,
            fontSizeLG: 36,
          },
        },
      }}
    >
      <StyledTabs
        activeKey={activeKey}
        defaultActiveKey={defaultActiveKey}
        items={items}
        onChange={onChange}
        size="large"
        styledProps={{
          backgroundColor: token.colorBgLayout,
          zIndex: token.zIndexPopupBase + zIndexOffset,
        }}
        tabBarExtraContent={
          <>
            <StyledAccountDropdownPlaceholder />
            <StyledAccountDropdown styledProps={{ right }} />
            <div ref={tabBarEndRef} />
          </>
        }
      />
    </ConfigProvider>
  );
};

export default TabSwitcher;
