import { addDoc, collection, getFirestore } from "firebase/firestore";

// const database = getFirestore();

export const sendEmail = async (to, emailData, template_id) => {
  const time = new Date().toLocaleString();

  // await addDoc(collection(database, "emails"), {
  //   to: to,
  //   email_data: emailData,
  //   template_id: template_id,
  //   created_at: time,
  // });
};

export default sendEmail;
