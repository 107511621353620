import type {
  CompanyProps,
  MeetingTimeProps,
  ProfileInterestsProps,
  ProfileProps,
} from "store";

type EventProfileProps = {
  avatarUrl: string;
  category: string;
  company: CompanyProps;
  companyId: string;
  countryCode: string;
  description: string;
  id: string;
  interest_tags: ProfileInterestsProps;
  isOnboardingFinished: boolean;
  jobTitle: string;
  linkedIn: string;
  phoneNumber: string;
  role: string;
  type: string;
  genres: string[];
  subjects: string[];
  countries: string[];
  languages: string[];
  companies: string[];
  logline: string[];
  externalUrl: string;
  director: string;
  producer: string;
  screenwriter: string;
  unavailableTimes: MeetingTimeProps[];
  inPersonMeetingStartDate: string;
  inPersonMeetingEndDate: string;
};

type UserProps = {
  id: string;
  created_at: string;
  email: string;
  first_name: string;
  last_name: string;
};

export const formatDataToProfileProps = (
  user: UserProps,
  eventProfile: EventProfileProps
) => {
  const isCompanyListed = !!eventProfile?.company?.id;

  return {
    avatarUrl: eventProfile?.avatarUrl,
    category: eventProfile?.category,
    companyId: isCompanyListed ? eventProfile.company.id : null,
    companyName: isCompanyListed
      ? eventProfile?.company.name
      : eventProfile?.company,
    countryCode: eventProfile?.countryCode,
    description: eventProfile?.description,
    email: user.email,
    exhibitorUrl: eventProfile?.company?.exhibitorUrl,
    firstName: user.first_name,
    id: user.id,
    interests: eventProfile?.interest_tags,
    isOnboardingFinished: eventProfile?.isOnboardingFinished,
    jobTitle: eventProfile?.jobTitle,
    lastName: user.last_name,
    linkedIn: eventProfile?.linkedIn,
    phoneNumber: eventProfile?.phoneNumber,
    role: eventProfile?.role,
    type: eventProfile?.type,
    genres: eventProfile?.genres,
    subjects: eventProfile?.subjects || [],
    countries: eventProfile?.countries || [],
    languages: eventProfile?.languages || [],
    companies: eventProfile?.companies || [],
    logline: eventProfile?.logline || [],
    externalUrl: eventProfile?.externalUrl,
    director: eventProfile?.director,
    producer: eventProfile?.producer,
    screenwriter: eventProfile?.screenwriter,
    unavailableTimes: eventProfile?.unavailableTimes,
    inPersonMeetingStartDate: eventProfile?.inPersonMeetingStartDate,
    inPersonMeetingEndDate: eventProfile?.inPersonMeetingEndDate,
  } as ProfileProps;
};
