import emotionStyled, { CreateStyled } from "@emotion/styled";

export { css } from "@emotion/css";

const styledPropName = "styledProps";

export type StyledProps = { [styledPropName]: any };

const options: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => propName !== styledPropName,
}; // https://github.com/emotion-js/emotion/issues/2193

const styled = (component: any) => emotionStyled(component, options);

styled.div = emotionStyled("div", options);

styled.img = emotionStyled("img", options);

export default styled;
