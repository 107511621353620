import { useCallback, useEffect, useRef, useState } from "react";

import { getChildrenDimensions } from "helpers";

export const useRefElementProperties = (ref?: any) => {
  const providedRef = useRef<any>();
  const [, setResizeKey] = useState(0);

  const handleResize = useCallback(() => {
    setResizeKey((previousValue) => previousValue + 1);
  }, []);

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  ref = ref || providedRef; // TODO: Replace parameter ref with provided ref

  return {
    children: ref.current && getChildrenDimensions(ref.current),
    isLoaded: !!ref.current,
    ref,
    relativeToParent: {
      height: ref.current?.offsetHeight,
      left: ref.current?.offsetLeft,
      top: ref.current?.offsetTop,
      width: ref.current?.offsetWidth,
    },
    relativeToViewport: {
      bottom:
        Math.round(
          window.innerHeight -
            ref.current?.getBoundingClientRect().top -
            ref.current?.getBoundingClientRect().height
        ) || 0,
      height: Math.round(ref.current?.getBoundingClientRect().height) || 0,
      left: Math.round(ref.current?.getBoundingClientRect().left) || 0,
      right:
        Math.round(
          window.innerWidth -
            ref.current?.getBoundingClientRect().left -
            ref.current?.getBoundingClientRect().width
        ) || 0,
      top: Math.round(ref.current?.getBoundingClientRect().top) || 0,
      width: Math.round(ref.current?.getBoundingClientRect().width) || 0,
    }, // TODO: Review if viewport calculations are needed if position relative to parent is available
  };
};
