import { Col, Row } from "antd";

import SinglePageLayout from "layouts/SinglePage";

import LoginForm from "./Form";

export default () => (
  <SinglePageLayout isCentered title="Unlock the Power of Networking">
    <Row justify="center">
      <Col span={24} sm={16} md={12} lg={10} xl={8}>
        <LoginForm />
      </Col>
    </Row>
  </SinglePageLayout>
);
