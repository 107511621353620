import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";

import "styles/index.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

export const appElementId = "app";
export const appWrapperElementId = "root";

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  ReactGA.initialize("G-BZPJ7VKMKQ");
}

if (process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

declare global {
  interface Window {
    Intercom: any;
  }
}

// This boots up Intercom
window.Intercom("boot", {
  app_id: process.env.REACT_APP_IntercomAppId,
});

// This is a hack to get Intercom into the DOM without showing.
// It prevents flickering later on when Intercom is actually opened.
window.Intercom("show");
window.Intercom("hide");

ReactDOM.render(<App />, document.getElementById(appElementId));

reportWebVitals();
