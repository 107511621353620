import { useContext } from "react";

import { ThemeContext } from "styles/antdThemeProvider";

export const useBreakpoint = () => {
  const { breakpoint } = useContext(ThemeContext);

  return {
    ...breakpoint,
    isDesktop: breakpoint.xl,
    isLoaded: breakpoint.xl !== undefined,
  };
};
