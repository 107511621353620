import axiosInstance from "../axiosInstance";
import { Event } from "../models/Event";
import { Events } from "../models/Events";

export const getAllEvents = async (): Promise<Events> => {
  const response = await axiosInstance.get(`/events`);
  return response.data;
};

export const getJoinedEvents = async (userId: string): Promise<Events> => {
  const response = await axiosInstance.get(`/users/${userId}/events`);
  return response.data;
};

export const getEvent = async (
  userId: string,
  eventId: string
): Promise<Event> => {
  const response = await axiosInstance.get(
    `/users/${userId}/events/${eventId}`
  );
  return response.data;
};

export const postUserToEvent = async (
  userId: string,
  eventId: string,
  data?: any
) => {
  const response = await axiosInstance.post(
    `/users/${userId}/events/${eventId}`,
    data
  );
  return response.data;
};

export const getEventByCode = async (eventCode: string) => {
  const response = await axiosInstance.get(`/events/private/${eventCode}`);
  return response.data;
};

export const getEventInterests = async (eventId: string) => {
  const response = await axiosInstance.get(`/events/${eventId}/interests`);

  return response.data;
};

export const updateUserEventProfile = async (
  userId: string,
  eventId: string,
  data: any
) => {
  const response = await axiosInstance.put(
    `/users/${userId}/events/${eventId}`,
    data
  );

  return response.data;
};

export const createEventMeeting = async (eventId: string, data: any) => {
  const response = await axiosInstance.post(
    `/events/${eventId}/meetings`,
    data
  );

  return response.data;
};

export const acceptEventMeeting = async (meetingId: string) => {
  const response = await axiosInstance.put(
    `/events/meetings/${meetingId}/accept`
  );

  return response.data;
};

export const declineEventMeeting = async (meetingId: string) => {
  const response = await axiosInstance.put(
    `/events/meetings/${meetingId}/decline`
  );

  return response.data;
};
