import React, { useEffect } from "react";
import { Provider } from "react-redux";

import { FirebaseProvider } from "./database/firebase";
import Routes from "./routes";
import { store } from "./store";

const App = () => {
  // This disables the error for ResizeObserver, which can safely be ignored by our side, see more: https://stackoverflow.com/a/50387233.
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        e.preventDefault();
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <FirebaseProvider store={store}>
          <Routes />
        </FirebaseProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default App;
