import { CaretDownFilled } from "@ant-design/icons";
import { Dropdown, Skeleton, theme } from "antd";
import type { MenuProps } from "antd";
import { useContext } from "react";

import { RouteTransitionContext } from "components/RouteTransition";
import { Typography, TypographySizes } from "components/antd";
import { formatUserFullName, isLoaded } from "helpers";
import {
  useLogOut,
  useNavigateToPath,
  useReturnPath,
  useSelector,
} from "hooks";
import { ProtectedPaths } from "routes";
import { selectAccount } from "store/selectors";
import { styled } from "styles";
import { colorGrayMenu, fontSizeMD } from "styles/variables";

import MenuItem from "./EventSwitcher/MenuItem";

export const height = 40;

const StyledContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  height,
  position: "relative",
});

const StyledDropdownButton = styled(Dropdown.Button)({
  ".ant-btn": {
    "&:first-child": {
      paddingBottom: 0,
      paddingTop: 0,
      textAlign: "right",
      span: {
        lineHeight: 1.2,
      },
    },
    "&:last-child": {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    },
  },
});

const StyledSkeletonInput = styled(Skeleton.Input)({
  "&.ant-skeleton": {
    display: "flex",
  },
});

const StyledTypographyText = styled(Typography.Text)({
  display: "block",
});

type AccountDropdownProps = {
  className?: string;
};

const AccountDropdown = ({ className }: AccountDropdownProps) => {
  const { isTransitionExiting: isTransitionFinishing } = useContext(
    RouteTransitionContext
  );
  const logOut = useLogOut();
  const returnPath = useReturnPath();
  const navigateToAccount = useNavigateToPath(ProtectedPaths.Account, {
    state: { returnPath },
  });
  const account = useSelector(selectAccount);
  const { token } = theme.useToken();

  const dropdownMenuItems: MenuProps["items"] = [
    {
      label: <MenuItem title="Edit account" />,
      key: "edit-account",
      onClick: navigateToAccount,
    },
    {
      type: "divider",
    },
    {
      label: <MenuItem title="Log out" />,
      key: "log-out",
      onClick: logOut,
    },
  ];

  const isVisible =
    !account.isTerminating || (account.isTerminating && isTransitionFinishing);

  return (
    isVisible && (
      <div className={className}>
        {isLoaded(account) ? (
          <StyledContainer>
            <StyledDropdownButton
              icon={
                <CaretDownFilled
                  style={{ color: token.colorPrimary, fontSize: fontSizeMD }}
                />
              }
              menu={{ items: dropdownMenuItems }}
              placement="bottomRight"
              size="large"
              type="link"
            >
              <StyledTypographyText strong style={{ display: "block" }}>
                {formatUserFullName(account)}
              </StyledTypographyText>
              <StyledTypographyText
                style={{ display: "block" }}
                color={colorGrayMenu}
                size={TypographySizes.SM}
                strong
              >
                {account.email}
              </StyledTypographyText>
            </StyledDropdownButton>
          </StyledContainer>
        ) : (
          <StyledSkeletonInput />
        )}
      </div>
    )
  );
};

export default AccountDropdown;
