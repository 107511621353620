import { useContext, useEffect } from "react";

import { RouteTransitionContext } from "components/RouteTransition";

const SupportPage = () => {
  const { isTransitionExiting } = useContext(RouteTransitionContext);

  useEffect(() => {
    window.Intercom("show");

    return () => {
      window.Intercom("hide");
    };
  }, []);

  useEffect(() => {
    isTransitionExiting && window.Intercom("hide");
  }, [isTransitionExiting]);

  return <></>;
};

export default SupportPage;
