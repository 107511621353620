import classNames from "classnames";

import type { FilledIcon } from ".";

export const BackgroundSphereFilled = ({
  className,
  fill = "currentColor",
  height = 66,
  style,
  width = 584,
}: FilledIcon) => (
  <svg
    className={classNames("anticon", className)}
    fill="none"
    height={height}
    style={style}
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M583.920645,0.00252200406 C495.531513,42.3086691 396.53246,66 292,66 C187.467557,66 88.4685183,42.3086767 0.0793973407,0.00254223406 Z"
      fill={fill}
      fillOpacity="0.2"
    />
  </svg>
);
