import Lottie from "lottie-react";

import lottieEmail from "assets/images/lottie-email.json";
import {
  Button,
  Space,
  SpaceTypes,
  Typography,
  TypographySizes,
} from "components/antd";
import { useNavigateToPath } from "hooks";
import { UnprotectedPaths } from "routes";
import { styled } from "styles";
import { colorGraySemi } from "styles/variables";

const StyledLottie = styled(Lottie)({
  maxHeight: 200,
  maxWidth: 200,
});

type ConfirmationProps = {
  email: string;
};

const Confirmation = ({ email }: ConfirmationProps) => {
  const navigateToEntryPage = useNavigateToPath(UnprotectedPaths.Entry);

  return (
    <Space direction="vertical" size="middle" type={SpaceTypes.Centered}>
      <Typography.Title level={4}>
        {`Your instructions were sent to ${email}`}
      </Typography.Title>
      <Typography.Text color={colorGraySemi} size={TypographySizes.MD}>
        Please find the Exponaut Matchmaking guide in your inbox. If it's not
        there, check your spam folder.
      </Typography.Text>
      <StyledLottie animationData={lottieEmail} loop={false} />
      <Button onClick={navigateToEntryPage} shape="round" type="primary">
        Done
      </Button>
    </Space>
  );
};

export default Confirmation;
