import { Skeleton, theme } from "antd";

import {
  Space,
  SpaceTypes,
  Typography,
  TypographySizes,
} from "components/antd";
import { isEmpty } from "helpers";
import { useSelector } from "hooks";
import { selectEventCompany } from "store/selectors";

type HeaderProps = {
  isLoading: boolean;
};

export const Header = ({ isLoading }: HeaderProps) => {
  const eventCompany = useSelector(selectEventCompany);
  const { token } = theme.useToken();

  const titleElement = isLoading ? (
    <Skeleton.Input size="large" style={{ marginTop: token.marginMD * 0.9 }} />
  ) : (
    <Typography.Title style={{ marginBottom: 0 }}>
      {eventCompany?.name}
    </Typography.Title>
  );

  return (
    !isEmpty(eventCompany) && (
      <Space direction="vertical" type={SpaceTypes.Centered}>
        <Typography.Text size={TypographySizes.LG}>
          Catalogue by
        </Typography.Text>
        {titleElement}
      </Space>
    )
  );
};

export default Header;
