import { theme } from "antd";
import _ from "lodash";
import { useMemo } from "react";

import { Global } from "styles/global";

const isComponentToken = (value: any) => typeof value === "object";

const AntdMobileThemeProvider = () => {
  const { token }: any = theme.useToken();

  const cssVariables = useMemo(() => {
    const prefixedKebabCaseTokens = Object.fromEntries(
      Object.entries(token).map(([key, value]) =>
        isComponentToken(value) ? [] : [`--adm-${_.kebabCase(key)}`, value]
      )
    );

    const overrides = {
      "--adm-color-background": token.colorBgElevated,
    };

    return { ...prefixedKebabCaseTokens, ...overrides };
  }, [token]);

  return (
    <Global
      styles={{
        ":root": cssVariables,
      }}
    />
  );
};

export default AntdMobileThemeProvider;
