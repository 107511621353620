import axios from "axios";

import axiosInstance from "../axiosInstance";
import { UserExist } from "../models/User";

export const checkUserEmailExists = async (
  email: string
): Promise<UserExist> => {
  const response = await axiosInstance.get(`/users/exists?email=${email}`);
  return response.data;
};

export const verifyLinkedinUser = async (code: string) => {
  const response = await axiosInstance.get(
    `/auth/linkedin/verification?code=${code}`
  );
  return response.data;
};

export const requestPasswordResetLink = async (
  data: any,
  sendInstructionsEmail = false
) => {
  const response = await axiosInstance.post("/auth/send-otp-email", data, {
    params: {
      sendInstructionsEmail,
    },
  });
  return response.data;
};

export const verifySingleEventAccessToken = async (token: string) => {
  const response = await axiosInstance.get(
    `/auth/validate-otp-token?token=${token}`
  );
  return response.data;
};

export const getAllEventUsers = async (userId: string, eventId: string) => {
  const response = await axiosInstance.get(
    `/users/${userId}/events/${eventId}/users`
  );
  return response.data;
};

export const getEventUser = async (userId: string, eventId: string) => {
  const response = await axiosInstance.get(
    `/users/${userId}/events/${eventId}`
  );
  return response.data;
};

export const addUserToFavorites = async (
  userId: string,
  eventId: string,
  data: any
) => {
  const response = await axiosInstance.put(
    `/users/${userId}/events/${eventId}/favorites`,
    data
  );

  return response.data;
};

export const removeUserFromFavorites = async (
  userId: string,
  eventId: string,
  favoriteUserId: string
) => {
  const response = await axiosInstance.delete(
    `/users/${userId}/events/${eventId}/favorites/${favoriteUserId}`
  );

  return response.data;
};

export const exportContacts = async (
  userId: string,
  eventId: string,
  data: any
) => {
  const response = await axiosInstance.post(
    `/users/${userId}/events/${eventId}/export-contacts`,
    data
  );

  return response.data;
};

export const deleteUser = async (userId: string) => {
  const response = await axiosInstance.delete(`/users/${userId}`);

  return response.data;
};

export const fetchUserDeviceToken = async (userId: string, token: string) => {
  return axiosInstance.post("/users/device-token", { userId, token });
};
