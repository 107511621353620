import { Space, Tag, theme } from "antd";
import type { Dayjs } from "dayjs";

import { Typography, TypographySizes } from "components/antd";
import {
  humanReadableDateAndTimeFormat,
  humanReadableDateFormat,
} from "helpers/constants";
import { MeetingTypes } from "helpers/enums";
import { styled } from "styles";
import type { StyledProps } from "styles";

const StyledSpace = styled(Space)({ textAlign: "center" });

const StyledTag = styled(Tag)(
  ({ styledProps: { isPlaceholder } }: StyledProps) => ({
    "&.ant-tag": {
      ...(isPlaceholder && {
        transition: "none",
        visibility: "hidden",
      }),
    },
  })
);

type HeaderProps = {
  contentElement?: React.ReactElement;
  date: Dayjs;
  dateFormat:
    | typeof humanReadableDateFormat
    | typeof humanReadableDateAndTimeFormat;
  inviteeFullName: string;
  type: MeetingTypes;
};

const Header = ({
  contentElement,
  date,
  dateFormat,
  inviteeFullName,
  type,
}: HeaderProps) => {
  const { token } = theme.useToken();

  return (
    <StyledSpace direction="vertical" size="large">
      <StyledTag
        color={token.colorTextBase}
        styledProps={{ isPlaceholder: !type }}
      >
        {type || "placeholder"}
      </StyledTag>
      <Typography.Text size={TypographySizes.LG} strong uppercase>
        Propose a meeting with {inviteeFullName}
      </Typography.Text>
      {date && (
        <Typography.Title level={3}>{date.format(dateFormat)}</Typography.Title>
      )}
      {contentElement}
    </StyledSpace>
  );
};

export default Header;
