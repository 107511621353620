import { height, paddingTop } from "components/TabSwitcher";
import { Layout } from "components/antd";
import { styled } from "styles";
import type { StyledProps } from "styles";

export const StyledLayoutHeader = styled(Layout.Header)(
  ({
    styledProps: { backgroundColor, marginTop, marginBottom, zIndex },
  }: StyledProps) => ({
    alignItems: "center",
    backgroundColor: backgroundColor,
    display: "flex",
    height,
    justifyContent: "flex-end",
    marginBottom,
    marginTop,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop,
    position: "sticky",
    top: 0,
    zIndex,
  })
);
