import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";

import { Space, SpaceTypes, Typography } from "components/antd";
import { useFirebase, useNavigateToPath } from "hooks";
import SinglePageLayout from "layouts/SinglePage";
import { UnprotectedPaths } from "routes";

import SetNewPasswordForm from "./Form";

export default () => {
  const firebase = useFirebase();
  const navigateToEntry = useNavigateToPath(UnprotectedPaths.Entry);
  const navigateToLogin = useNavigateToPath(UnprotectedPaths.Login);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const actionCode = urlParams.get("oobCode");

  useEffect(() => {
    firebase
      .verifyPasswordResetCode(actionCode)
      .then(() => {})
      .catch(navigateToEntry);
  }, [firebase, navigateToEntry, actionCode]);

  return (
    <SinglePageLayout
      isCentered
      title={isPasswordChanged ? "You're all set" : "Set your new password"}
    >
      <Row justify="center">
        <Col span={24} sm={16} md={12} lg={10} xl={8}>
          {isPasswordChanged ? (
            <Space type={SpaceTypes.FormContentAndButton}>
              <Typography.Text>
                Your password has been changed successfully
              </Typography.Text>

              <Button
                shape="round"
                size="middle"
                type="primary"
                onClick={navigateToLogin}
                data-qa="login-button"
              >
                Log in
              </Button>
            </Space>
          ) : (
            <SetNewPasswordForm
              actionCode={actionCode}
              setIsPasswordChanged={setIsPasswordChanged}
            />
          )}
        </Col>
      </Row>
    </SinglePageLayout>
  );
};
