import { Divider, theme } from "antd";
import { Image } from "antd";

import { Space, SpaceTypes, Typography } from "components/antd";
import { ProfileProps } from "store";

export const logoSize = 64;

type ContentProps = {
  user: ProfileProps;
};

const Content = ({ user }: ContentProps) => {
  const { token } = theme.useToken();

  return (
    <Space direction="vertical" size={token.sizeXXL} style={{ rowGap: "0" }}>
      <Image src={user.avatarUrl} alt="Project Image" preview={false} />
      <Divider />
      <Space direction="vertical" size="middle" type={SpaceTypes.Centered}>
        <Typography.Text>{user.logline}</Typography.Text>
      </Space>
      <Space direction="vertical" style={{ marginTop: "3rem" }}>
        <Typography.Text>Subjects: {user.subjects.join(", ")}</Typography.Text>
        <Typography.Text>Genres: {user.genres.join(", ")}</Typography.Text>
        <Typography.Text>
          Countries: {user.countries.join(", ")}
        </Typography.Text>
        <Typography.Text>
          Languages: {user.languages.join(", ")}
        </Typography.Text>
        <Typography.Text>Director: {user.director}</Typography.Text>
        <Typography.Text>Producer: {user.producer}</Typography.Text>
        <Typography.Text>Screenwriter: {user.screenwriter}</Typography.Text>
      </Space>
    </Space>
  );
};

export default Content;
