import { ConfigProvider, Modal, theme } from "antd";
import { Popup } from "antd-mobile";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { RouteTransitionContext } from "components/RouteTransition";
import {
  getChildrenDimensions,
  getSubChildrenDimensions,
  isFunction,
} from "helpers";
import { flexibleSpaceId } from "helpers/constants";
import {
  useBreakpoint,
  useEffectOnValueChange,
  useMutationObserver,
  useNavigateToReturnPath,
  useRefElementProperties,
} from "hooks";
import { LayoutContext } from "layouts/Main";
import { styled } from "styles";
import type { StyledProps } from "styles";
import { ThemeContext } from "styles";
import {
  colorGrayModalBorder,
  modalPaddingBottom,
  modalPaddingLeft,
  modalPaddingRight,
  modalPaddingTop,
  popupBorderRadius,
  popupPaddingBottom,
  popupPaddingLeft,
  popupPaddingRight,
  popupPaddingTop,
  tabBarHeight,
} from "styles/variables";

export const scrollContainerClassName = "scroll-container";
export const shadowHeight = 30;
export const zIndexOffset = 1;

export const WindowContext = createContext({
  height: undefined,
  isWindowOpen: false,
  spacingBottom: 0,
});

const StyledModal = styled(Modal)(
  ({
    styledProps: { borderRadius, isChildrenEmpty, maxHeight },
  }: StyledProps) => ({
    "&.ant-modal": {
      borderRadius,
      maxHeight,
      overflow: "hidden",
      ".ant-modal-content": {
        overflow: "hidden",
        padding: 0,
        ...(isChildrenEmpty && {
          display: "none",
        }),
        ".ant-modal-footer": {
          marginTop: 0,
        },
      },
    },
  })
);

const StyledBodyScrollContainer = styled.div(
  ({ styledProps: { height, maxHeight } }: StyledProps) => ({
    height,
    maxHeight,
    overflowY: "auto",
  })
);

const StyledBodyItem = styled.div(
  ({
    styledProps: {
      height,
      isFooterElementVisible,
      isFullHeight,
      isFullHeightChildrenVerticallyCentered,
      isHeaderElementVisible,
      minHeight,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      shadowColor,
      zIndex,
    },
  }: StyledProps) => ({
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    position: "relative",
    ...(isFooterElementVisible && {
      ":after": {
        bottom: 0,
        boxShadow: `inset 0px -${shadowHeight}px ${shadowHeight / 2}px -${
          shadowHeight / 2
        }px ${shadowColor}`,
        content: "''",
        display: "block",
        height: shadowHeight,
        left: 0,
        position: "sticky",
        width: "100%",
        zIndex,
      },
    }),
    ...(isFullHeight && {
      height,
      minHeight,
      ...(isFullHeightChildrenVerticallyCentered && {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }),
    }),
    ...(isHeaderElementVisible && {
      ":before": {
        boxShadow: `inset 0px ${shadowHeight}px ${shadowHeight / 2}px -${
          shadowHeight / 2
        }px ${shadowColor}`,
        content: "''",
        display: "block",
        height: shadowHeight,
        left: 0,
        position: "sticky",
        top: 0,
        width: "100%",
        zIndex,
      },
    }),
  })
);

type UncontrolledWindowProps = {
  children?: React.ReactNode;
  childrenKey?: number | string;
  footerElement?: React.ReactNode;
  headerElement?: React.ReactElement;
  isCloseButtonVisible?: boolean;
  isFullHeight?: boolean;
  isFullHeightChildrenVerticallyCentered?: boolean; // TODO: Change to verticalAlignment or use functionality without prop
  isMaskVisible?: boolean;
  isFullPage?: boolean; // TODO: Cover modal in addition to popup
  noPadding?: boolean;
  onAfterClose?: () => void;
  onAfterOpen?: () => void;
  onClose?: () => void;
  onOpen?: () => void;
  width?: number;
};

type WindowProps = UncontrolledWindowProps & {
  isOpen: boolean;
};

const Window = ({
  children,
  childrenKey,
  footerElement,
  headerElement,
  isCloseButtonVisible = true,
  isFullHeight,
  isFullHeightChildrenVerticallyCentered,
  isFullPage,
  isMaskVisible,
  isOpen,
  noPadding,
  onAfterClose,
  onAfterOpen,
  onClose,
  onOpen,
  width = 1000,
}: WindowProps) => {
  const { isDesktop } = useBreakpoint();
  const { isTabBarVisible } = useContext(LayoutContext);
  const { isTransitionExiting } = useContext(RouteTransitionContext);
  const { isDarkTheme } = useContext(ThemeContext);
  const childrenWrapperRef = useRef<HTMLDivElement>(null);
  const footerElementRef = useRef<HTMLDivElement>(null);
  const headerElementRef = useRef<HTMLDivElement>(null);
  const {
    relativeToViewport: { height: footerElementHeight },
  } = useRefElementProperties(footerElementRef);
  const {
    relativeToViewport: { height: headerElementHeight },
  } = useRefElementProperties(headerElementRef);
  const [isVisible, setIsVisible] = useState(false);
  const [, setRerenderKey] = useState(0);
  const [screenHeight, setScreenHeight] = useState(null);
  const { token } = theme.useToken();

  const resizeListener = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    resizeListener();

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const rerender = () => {
    setRerenderKey((previousValue) => previousValue + 1);
  };

  useEffectOnValueChange(function updateHeightsOnChildrenKeyChange() {
    rerender();
  }, childrenKey);

  useEffectOnValueChange(() => {
    if (isOpen) {
      isFunction(onOpen) && onOpen();
      setIsVisible(true);
    }
  }, isOpen);

  useEffect(
    function calculateScrollContainerHeightsAfterComponentIsVisible() {
      rerender();
    },
    [isVisible]
  );

  useMutationObserver(
    footerElementRef,
    function updateHeightsOnFooterElementDomChange() {
      rerender();
    }
  );

  useMutationObserver(
    headerElementRef,
    function updateHeightsOnHeaderElementDomChange() {
      rerender();
    }
  );

  useEffect(
    function calculateIsScrollableOnHeightsChange() {
      rerender();
    },
    [footerElementHeight, headerElementHeight]
  );

  const isChildrenEmpty = !children;
  const isChildrenFlexibleSpace =
    childrenWrapperRef.current?.children[0]?.id === flexibleSpaceId;
  const isFooterElementVisible = !!footerElement;
  const isHeaderElementVisible = !!headerElement;

  const childrenHeight =
    childrenWrapperRef.current &&
    (isChildrenFlexibleSpace
      ? getSubChildrenDimensions(childrenWrapperRef.current, flexibleSpaceId)
          .height
      : getChildrenDimensions(childrenWrapperRef.current).height);
  const childrenWrapperHeight =
    childrenHeight +
    (isFooterElementVisible ? shadowHeight : 0) +
    (isHeaderElementVisible ? shadowHeight : 0);
  const modalHeight = screenHeight - token.marginXL * 2;
  const modalScrollContainerHeight =
    modalHeight - footerElementHeight - headerElementHeight;
  const popupHeight = screenHeight - (isTabBarVisible ? tabBarHeight : 0);
  const popupScrollContainerHeight =
    popupHeight - footerElementHeight - headerElementHeight;

  const isScrollable = childrenWrapperHeight > modalScrollContainerHeight;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgLayout: token.colorBgElevated,
        },
      }}
    >
      <WindowContext.Provider
        value={{
          height: isDesktop ? modalHeight : popupHeight,
          isWindowOpen: true,
          ...(!isFooterElementVisible && {
            spacingBottom: isDesktop ? modalPaddingBottom : popupPaddingBottom,
          }),
        }}
      >
        {isDesktop ? (
          <StyledModal
            afterClose={onAfterClose}
            afterOpenChange={(open: boolean) => {
              open && isFunction(onAfterOpen) && onAfterOpen();
            }}
            bodyStyle={{
              border: isDarkTheme && `1px solid ${colorGrayModalBorder}`,
              borderRadius: !footerElement && token.borderRadiusLG,
              lineHeight: 1,
              overflow: "hidden",
            }}
            centered
            closeIcon={isCloseButtonVisible}
            destroyOnClose
            footer={
              isFooterElementVisible ? (
                <div ref={footerElementRef}>{footerElement}</div>
              ) : null
            }
            mask={isMaskVisible}
            open={isOpen}
            onCancel={onClose}
            styledProps={{
              borderRadius: token.borderRadiusLG,
              isChildrenEmpty,
              maxHeight: modalHeight,
            }}
            width={width}
          >
            {isHeaderElementVisible && (
              <div ref={headerElementRef}>
                <StyledBodyItem
                  styledProps={{
                    paddingBottom: noPadding ? 0 : token.paddingContentVertical,
                    paddingLeft: noPadding ? 0 : modalPaddingLeft,
                    paddingRight: noPadding ? 0 : modalPaddingRight,
                    paddingTop: noPadding ? 0 : modalPaddingTop,
                  }}
                >
                  {headerElement}
                </StyledBodyItem>
              </div>
            )}
            <StyledBodyScrollContainer
              className={scrollContainerClassName}
              styledProps={{
                height: isFullHeight && modalScrollContainerHeight,
                maxHeight: !isFullHeight && modalScrollContainerHeight,
              }}
            >
              <StyledBodyItem
                ref={childrenWrapperRef}
                styledProps={{
                  height: isScrollable ? "auto" : modalScrollContainerHeight,
                  isFooterElementVisible,
                  isFullHeight,
                  isFullHeightChildrenVerticallyCentered,
                  isHeaderElementVisible,
                  minHeight: isScrollable && modalScrollContainerHeight,
                  paddingBottom:
                    isFooterElementVisible || noPadding
                      ? 0
                      : modalPaddingBottom,
                  paddingLeft: noPadding ? 0 : modalPaddingLeft,
                  paddingRight: noPadding ? 0 : modalPaddingRight,
                  paddingTop:
                    isHeaderElementVisible || noPadding ? 0 : modalPaddingTop,
                  shadowColor: token.colorBgContainer,
                  zIndex: token.zIndexBase + zIndexOffset,
                }}
              >
                {children}
              </StyledBodyItem>
            </StyledBodyScrollContainer>
          </StyledModal>
        ) : (
          <Popup
            afterClose={onAfterClose}
            bodyStyle={{
              borderTop: isDarkTheme && `1px solid ${colorGrayModalBorder}`,
              borderTopLeftRadius: isFullPage ? 0 : popupBorderRadius,
              borderTopRightRadius: isFullPage ? 0 : popupBorderRadius,
              bottom: isTabBarVisible && !isTransitionExiting && tabBarHeight,
              overflow: "hidden",
              display: isChildrenEmpty ? "none" : "block",
            }}
            destroyOnClose
            mask={isMaskVisible}
            onClose={onClose}
            onMaskClick={onClose}
            position="bottom"
            showCloseButton={isCloseButtonVisible}
            visible={isOpen}
          >
            {isHeaderElementVisible && (
              <div ref={headerElementRef}>
                <StyledBodyItem
                  styledProps={{
                    paddingBottom: noPadding ? 0 : token.paddingContentVertical,
                    paddingLeft: noPadding ? 0 : popupPaddingLeft,
                    paddingRight: noPadding ? 0 : popupPaddingRight,
                    paddingTop: noPadding ? 0 : popupPaddingTop,
                  }}
                >
                  {headerElement}
                </StyledBodyItem>
              </div>
            )}
            <StyledBodyScrollContainer
              className={scrollContainerClassName}
              styledProps={{ maxHeight: popupScrollContainerHeight }}
            >
              <StyledBodyItem
                styledProps={{
                  isFooterElementVisible,
                  isHeaderElementVisible,
                  paddingBottom:
                    isFooterElementVisible || noPadding
                      ? 0
                      : popupPaddingBottom,
                  paddingLeft: noPadding ? 0 : popupPaddingLeft,
                  paddingRight: noPadding ? 0 : popupPaddingRight,
                  paddingTop:
                    isHeaderElementVisible || noPadding ? 0 : popupPaddingTop,
                  shadowColor: token.colorBgContainer,
                  zIndex: token.zIndexBase + zIndexOffset,
                }}
              >
                {children}
              </StyledBodyItem>
            </StyledBodyScrollContainer>
            {isFooterElementVisible && (
              <div ref={footerElementRef}>{footerElement}</div>
            )}
          </Popup>
        )}
      </WindowContext.Provider>
    </ConfigProvider>
  );
};

export const UncontrolledWindow = ({
  isCloseButtonVisible = true,
  onAfterClose,
  onClose,
  ...restProps
}: UncontrolledWindowProps) => {
  const navigate = useNavigate();
  const navigateToReturnPath = useNavigateToReturnPath();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(function openOnMount() {
    setIsOpen(true);
  }, []);

  const handleNavigation = () => {
    isFunction(navigateToReturnPath) ? navigateToReturnPath() : navigate(-1);
  };

  return (
    <Window
      isCloseButtonVisible={isCloseButtonVisible}
      isOpen={isOpen}
      onAfterClose={() => {
        isCloseButtonVisible && handleNavigation();

        isFunction(onAfterClose) && onAfterClose();
      }}
      onClose={() => {
        isCloseButtonVisible && setIsOpen(false);

        isFunction(onClose) && onClose();
      }}
      {...restProps}
    />
  );
};

export default Window;
