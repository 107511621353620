import { Typography, theme } from "antd";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { getEnumValues } from "helpers/enums";
import { ProtectedPaths, UnprotectedPaths } from "routes";

const { Link: AntdLink } = Typography;

export enum LinkTypes {
  Default,
  Primary,
}

type LinkProps = {
  back?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  state?: object;
  style?: React.CSSProperties;
  to?: string;
  type?: LinkTypes;
};

export const Link = ({
  back = false,
  children,
  onClick,
  state,
  style,
  to = ".",
  type = LinkTypes.Default,
  ...restProps
}: LinkProps) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const handleClick = () => {
    back ? navigate(-1) : onClick();
  };

  const enumValues = [ProtectedPaths, UnprotectedPaths].flatMap(getEnumValues);
  to = enumValues.includes(to) ? `/${to}` : to;

  const isExternal = to.includes("http");
  const isOverwritten = back || typeof onClick === "function";

  return isOverwritten ? (
    <AntdLink
      onClick={handleClick}
      {...restProps}
      style={
        type === LinkTypes.Primary
          ? { ...style, color: token.colorPrimary }
          : style
      }
    >
      {children}
    </AntdLink>
  ) : (
    <AntdLink>
      <RouterLink
        to={to}
        state={state}
        target={isExternal && "_blank"}
        {...restProps}
        style={style}
      >
        {children}
      </RouterLink>
    </AntdLink>
  );
};
